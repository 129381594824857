import React, { useMemo, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Html } from "@react-three/drei";
import * as THREE from "three";
import { createNoise3D } from 'simplex-noise';
import { useDataset } from "../components/DataFetcher";
import * as d3 from "d3-force";

const colorPalette = {
  dkpurple: "#2c294b",
  mdpurple: "#3b3484",
  ltpurple: "#7174b0",
  dkorange: "#de6736",
  mdorange: "#e08a3c",
  ltorange: "#ebb844",
  cranberry: "#762861",
  magenta: "#c5316a",
  mdgreen: "#80ba55",
};

function getColorFromElevation(elevation) {
  if (elevation < -0.5) return colorPalette.dkpurple;
  else if (elevation < 0) return colorPalette.mdpurple;
  else if (elevation < 0.5) return colorPalette.ltpurple;
  else if (elevation < 1) return colorPalette.dkorange;
  else if (elevation < 1.5) return colorPalette.mdorange;
  else if (elevation < 2) return colorPalette.ltorange;
  else if (elevation < 2.5) return colorPalette.cranberry;
  else return colorPalette.magenta;
}

function parseItemList(itemList) {
  if (!itemList) return [];
  return Array.isArray(itemList) ? itemList.flat(Infinity) : [];
}

function arrangeItems(uniqueItems, parsedData, size = 50) {
  const nodes = uniqueItems.map((item) => ({ id: item }));
  const links = parsedData.flatMap((rule) =>
    rule.antecedents.map((antecedent) => ({
      source: antecedent,
      target: rule.consequents[0], // Assuming one consequent for simplicity
      value: rule.support,
    }))
  );

  const simulation = d3.forceSimulation(nodes)
    .force("link", d3.forceLink(links).id((d) => d.id).strength((d) => d.value))
    .force("charge", d3.forceManyBody().strength(-50))
    .force("center", d3.forceCenter(0, 0))
    .force("collision", d3.forceCollide(10));

  for (let i = 0; i < 300; i++) simulation.tick();

  return nodes.map((node) => ({
    item: node.id,
    position: [node.x * size, node.y * size],
  }));
}

function distributeMetrics(parsedData, itemMetrics) {
  parsedData.forEach((rule) => {
    const totalItems = rule.antecedents.length + rule.consequents.length;
    const weight = 1 / totalItems;

    rule.antecedents.forEach((item) => {
      itemMetrics[item].support += rule.support * weight;
      itemMetrics[item].lift += rule.lift * weight;
      itemMetrics[item].conviction += rule.conviction * weight;
      itemMetrics[item].zhangs_metric += rule.zhangs_metric * weight;
      itemMetrics[item].count += weight;
    });

    rule.consequents.forEach((item) => {
      itemMetrics[item].support += rule.support * weight;
      itemMetrics[item].lift += rule.lift * weight;
      itemMetrics[item].conviction += rule.conviction * weight;
      itemMetrics[item].zhangs_metric += rule.zhangs_metric * weight;
      itemMetrics[item].count += weight;
    });
  });
}

function generateHeightmapWithRidges(width, depth, itemPositions, parsedData, scale = 15) {
  console.log("Generating heightmap with width:", width, "depth:", depth);

  const simplex = createNoise3D();
  const heightmap = new Float32Array(width * depth);

  for (let i = 0; i < width * depth; i++) {
    const x = (i % width) / width;
    const z = Math.floor(i / width) / depth;
    heightmap[i] = simplex(x * scale, z * scale, 0) * 2;
  }

  const sigma = 0.1; // Adjusted sigma for broader ridges
  parsedData.forEach((rule) => {
    const antecedentPositions = rule.antecedents.map((item) => {
      const pos = itemPositions.find((p) => p.item === item);
      return pos ? pos.position : null;
    }).filter(Boolean);

    const consequentPosition = itemPositions.find((p) => p.item === rule.consequents[0])?.position;
    if (!consequentPosition) return;

    antecedentPositions.forEach(([ax, az]) => {
      const [cx, cz] = consequentPosition;

      for (let i = 0; i < width * depth; i++) {
        const x = (i % width) / width;
        const z = Math.floor(i / width) / depth;

        const dxA = x - (ax + 25) / 50;
        const dzA = z - (az + 25) / 50;
        const distSqA = dxA * dxA + dzA * dzA;
        const influenceA = Math.exp(-distSqA / (2 * sigma * sigma)) * rule.support;

        const dxC = x - (cx + 25) / 50;
        const dzC = z - (cz + 25) / 50;
        const distSqC = dxC * dxC + dzC * dzC;
        const influenceC = Math.exp(-distSqC / (2 * sigma * sigma)) * rule.support;

        heightmap[i] += influenceA + influenceC;
      }
    });
  });

  console.log("Heightmap generated.", heightmap);
  return heightmap;
}

const FPGraphPage = () => {
  const { data } = useDataset("fpgrowth");

  const fpgrowthData = useMemo(() => {
    if (!data?.FPGrowth) return null;
    const cleanedString = data.FPGrowth.replace(/Infinity/g, "2");
    try {
      return JSON.parse(cleanedString);
    } catch {
      console.error("Error parsing FPGrowth data.", cleanedString);
      return null;
    }
  }, [data]);

  const parsedData = useMemo(() => {
    if (!fpgrowthData || !fpgrowthData.data) return [];
    const { columns, data: rows } = fpgrowthData;
    return rows.map((row) => {
      const rowData = {};
      columns.forEach((col, index) => {
        rowData[col] = row[index];
      });
      const antecedents = parseItemList(rowData["antecedents"]);
      const consequents = parseItemList(rowData["consequents"]);
      return {
        antecedents,
        consequents,
        antecedent_support: rowData["antecedent support"] || 0,
        consequent_support: rowData["consequent support"] || 0,
        support: rowData["support"] || 0,
        confidence: rowData["confidence"] || 0,
        lift: rowData["lift"] || 0,
        conviction: rowData["conviction"] || 0,
        zhangs_metric: rowData["zhangs_metric"] || 0,
      };
    });
  }, [fpgrowthData]);

  const uniqueItems = useMemo(() => {
    const items = new Set();
    parsedData.forEach((rule) => {
      rule.antecedents.forEach((item) => items.add(item));
      rule.consequents.forEach((item) => items.add(item));
    });
    console.log("Unique items parsed:", Array.from(items));
    return Array.from(items);
  }, [parsedData]);

  const itemMetrics = useMemo(() => {
    const metrics = {};
    uniqueItems.forEach((item) => {
      metrics[item] = {
        support: 0,
        conviction: 0,
        zhangs_metric: 0,
        lift: 0,
        count: 0,
      };
    });
    distributeMetrics(parsedData, metrics);
    console.log("Item metrics calculated:", metrics);
    return metrics;
  }, [parsedData, uniqueItems]);

  const itemPositions = useMemo(() => {
    return arrangeItems(uniqueItems, parsedData, 50).map(({ item, position }) => ({
      item,
      position,
      metrics: itemMetrics[item] || { support: 0, lift: 0 },
    }));
  }, [uniqueItems, itemMetrics, parsedData]);

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <Canvas camera={{ position: [0, 50, 50], fov: 50 }}>
        <OrbitControls enableZoom={true} />
        <ambientLight intensity={0.5} />
        <directionalLight position={[10, 30, 10]} intensity={1} />
        {parsedData && <Terrain itemPositions={itemPositions} parsedData={parsedData} />}
      </Canvas>
    </div>
  );
};

function Terrain({ itemPositions, parsedData }) {
  const meshRef = useRef();
  const size = 50;
  const divisions = 50;

  const [geometry, colorAttribute] = useMemo(() => {
    const geometry = new THREE.PlaneGeometry(size, size, divisions, divisions);
    geometry.rotateX(-Math.PI / 2);
    const width = divisions + 1;
    const depth = divisions + 1;

    const heightmap = generateHeightmapWithRidges(width, depth, itemPositions, parsedData);

    const positionAttribute = geometry.attributes.position;
    const colorAttribute = new THREE.BufferAttribute(
      new Float32Array(positionAttribute.count * 3),
      3
    );
    const color = new THREE.Color();

    for (let i = 0; i < positionAttribute.count; i++) {
      const vertex = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
      const elevation = heightmap[i];
      vertex.y = elevation;
      positionAttribute.setXYZ(i, vertex.x, vertex.y, vertex.z);

      // Apply gradient color for ridges
      const elevationNormalized = (elevation + 1) / 3; // Normalize elevation to [0, 1]
      const ridgeColor = new THREE.Color().setHSL(0.6 - elevationNormalized * 0.6, 1, 0.5);
      colorAttribute.setXYZ(i, ridgeColor.r, ridgeColor.g, ridgeColor.b);
    }

    geometry.setAttribute("color", colorAttribute);
    geometry.computeVertexNormals();
    console.log("Geometry and colors set up successfully.");

    return [geometry, colorAttribute];
  }, [itemPositions, parsedData]);

  return (
    <>
      <mesh ref={meshRef} geometry={geometry}>
        <meshStandardMaterial vertexColors={true} side={THREE.DoubleSide} flatShading={false} />
      </mesh>
      {itemPositions.map(({ item, position, metrics }) => (
        <Html
          key={item}
          position={[position[0], metrics.lift * 2, position[1]]} // Adjusted height scaling for label positioning
          style={{ pointerEvents: "none" }}
        >
          <div
            style={{
              color: "black",
              fontSize: "8px",
              textAlign: "center",
              background: "rgba(255,255,255,0.7)",
              padding: "2px",
              borderRadius: "2px",
            }}
          >
            <strong>{item}</strong><br />
            <strong>Support:</strong> {metrics.support.toFixed(2)}<br />
            <strong>Lift:</strong> {metrics.lift.toFixed(2)}
          </div>
        </Html>
      ))}
    </>
  );
}

export default FPGraphPage;




// import React, { useMemo, useRef } from "react";
// import { Canvas } from "@react-three/fiber";
// import { OrbitControls, Html } from "@react-three/drei";
// import * as THREE from "three";
// import { createNoise3D } from 'simplex-noise';
// import { useDataset } from "../components/DataFetcher";
// import * as d3 from "d3-force";

// const colorPalette = {
//   dkpurple: "#2c294b",
//   mdpurple: "#3b3484",
//   ltpurple: "#7174b0",
//   dkorange: "#de6736",
//   mdorange: "#e08a3c",
//   ltorange: "#ebb844",
//   cranberry: "#762861",
//   magenta: "#c5316a",
//   mdgreen: "#80ba55",
// };

// function getColorFromElevation(elevation) {
//   if (elevation < -0.5) return colorPalette.dkpurple;
//   else if (elevation < 0) return colorPalette.mdpurple;
//   else if (elevation < 0.5) return colorPalette.ltpurple;
//   else if (elevation < 1) return colorPalette.dkorange;
//   else if (elevation < 1.5) return colorPalette.mdorange;
//   else if (elevation < 2) return colorPalette.ltorange;
//   else if (elevation < 2.5) return colorPalette.cranberry;
//   else return colorPalette.magenta;
// }

// function parseItemList(itemList) {
//   if (!itemList) return [];
//   return Array.isArray(itemList) ? itemList.flat(Infinity) : [];
// }

// function arrangeItems(uniqueItems, parsedData, size = 50) {
//   const nodes = uniqueItems.map((item) => ({ id: item }));
//   const links = parsedData.flatMap((rule) =>
//     rule.antecedents.map((antecedent) => ({
//       source: antecedent,
//       target: rule.consequents[0], // Assuming one consequent for simplicity
//       value: rule.support,
//     }))
//   );

//   const simulation = d3.forceSimulation(nodes)
//     .force("link", d3.forceLink(links).id((d) => d.id).strength((d) => d.value))
//     .force("charge", d3.forceManyBody().strength(-50))
//     .force("center", d3.forceCenter(0, 0))
//     .force("collision", d3.forceCollide(10));

//   for (let i = 0; i < 300; i++) simulation.tick();

//   return nodes.map((node) => ({
//     item: node.id,
//     position: [node.x * size, node.y * size],
//   }));
// }

// function distributeMetrics(parsedData, itemMetrics) {
//   parsedData.forEach((rule) => {
//     const totalItems = rule.antecedents.length + rule.consequents.length;
//     const weight = 1 / totalItems;

//     rule.antecedents.forEach((item) => {
//       itemMetrics[item].support += rule.support * weight;
//       itemMetrics[item].lift += rule.lift * weight;
//       itemMetrics[item].conviction += rule.conviction * weight;
//       itemMetrics[item].zhangs_metric += rule.zhangs_metric * weight;
//       itemMetrics[item].count += weight;
//     });

//     rule.consequents.forEach((item) => {
//       itemMetrics[item].support += rule.support * weight;
//       itemMetrics[item].lift += rule.lift * weight;
//       itemMetrics[item].conviction += rule.conviction * weight;
//       itemMetrics[item].zhangs_metric += rule.zhangs_metric * weight;
//       itemMetrics[item].count += weight;
//     });
//   });
// }

// function generateHeightmapWithRidges(width, depth, itemPositions, parsedData, scale = 15) {
//   console.log("Generating heightmap with width:", width, "depth:", depth);

//   const simplex = createNoise3D();
//   const heightmap = new Float32Array(width * depth);

//   for (let i = 0; i < width * depth; i++) {
//     const x = (i % width) / width;
//     const z = Math.floor(i / width) / depth;
//     heightmap[i] = simplex(x * scale, z * scale, 0) * 2;
//   }

//   const sigma = 0.1; // Adjusted sigma for broader ridges
//   parsedData.forEach((rule) => {
//     const antecedentPositions = rule.antecedents.map((item) => {
//       const pos = itemPositions.find((p) => p.item === item);
//       return pos ? pos.position : null;
//     }).filter(Boolean);

//     const consequentPosition = itemPositions.find((p) => p.item === rule.consequents[0])?.position;
//     if (!consequentPosition) return;

//     antecedentPositions.forEach(([ax, az]) => {
//       const [cx, cz] = consequentPosition;

//       for (let i = 0; i < width * depth; i++) {
//         const x = (i % width) / width;
//         const z = Math.floor(i / width) / depth;

//         const dxA = x - (ax + 25) / 50;
//         const dzA = z - (az + 25) / 50;
//         const dxC = x - (cx + 25) / 50;
//         const dzC = z - (cz + 25) / 50;

//         const distSqA = dxA * dxA + dzA * dzA;
//         const distSqC = dxC * dxC + dzC * dzC;

//         const ridgeInfluence = Math.exp(-distSqA / (2 * sigma * sigma)) + Math.exp(-distSqC / (2 * sigma * sigma));
//         heightmap[i] += ridgeInfluence * rule.support;
//       }
//     });
//   });

//   console.log("Heightmap with ridges generated.", heightmap);
//   return heightmap;
// }

// const FPGraphPage = () => {
//   const { data } = useDataset("fpgrowth");

//   const fpgrowthData = useMemo(() => {
//     if (!data?.FPGrowth) return null;
//     const cleanedString = data.FPGrowth.replace(/Infinity/g, "2");
//     try {
//       return JSON.parse(cleanedString);
//     } catch {
//       console.error("Error parsing FPGrowth data.", cleanedString);
//       return null;
//     }
//   }, [data]);

//   const parsedData = useMemo(() => {
//     if (!fpgrowthData || !fpgrowthData.data) return [];
//     const { columns, data: rows } = fpgrowthData;
//     return rows.map((row) => {
//       const rowData = {};
//       columns.forEach((col, index) => {
//         rowData[col] = row[index];
//       });
//       const antecedents = parseItemList(rowData["antecedents"]);
//       const consequents = parseItemList(rowData["consequents"]);
//       return {
//         antecedents,
//         consequents,
//         antecedent_support: rowData["antecedent support"] || 0,
//         consequent_support: rowData["consequent support"] || 0,
//         support: rowData["support"] || 0,
//         confidence: rowData["confidence"] || 0,
//         lift: rowData["lift"] || 0,
//         conviction: rowData["conviction"] || 0,
//         zhangs_metric: rowData["zhangs_metric"] || 0,
//       };
//     });
//   }, [fpgrowthData]);

//   const uniqueItems = useMemo(() => {
//     const items = new Set();
//     parsedData.forEach((rule) => {
//       rule.antecedents.forEach((item) => items.add(item));
//       rule.consequents.forEach((item) => items.add(item));
//     });
//     console.log("Unique items parsed:", Array.from(items));
//     return Array.from(items);
//   }, [parsedData]);

//   const itemMetrics = useMemo(() => {
//     const metrics = {};
//     uniqueItems.forEach((item) => {
//       metrics[item] = {
//         support: 0,
//         conviction: 0,
//         zhangs_metric: 0,
//         lift: 0,
//         count: 0,
//       };
//     });
//     distributeMetrics(parsedData, metrics);
//     console.log("Item metrics calculated:", metrics);
//     return metrics;
//   }, [parsedData, uniqueItems]);

//   const itemPositions = useMemo(() => {
//     return arrangeItems(uniqueItems, parsedData, 50).map(({ item, position }) => ({
//       item,
//       position,
//       metrics: itemMetrics[item] || { support: 0, lift: 0 },
//     }));
//   }, [uniqueItems, itemMetrics, parsedData]);

//   return (
//     <div style={{ height: "100vh", width: "100vw" }}>
//       <Canvas camera={{ position: [0, 50, 50], fov: 50 }}>
//         <OrbitControls enableZoom={true} />
//         <ambientLight intensity={0.5} />
//         <directionalLight position={[10, 30, 10]} intensity={1} />
//         {parsedData && <Terrain itemPositions={itemPositions} parsedData={parsedData} />}
//       </Canvas>
//     </div>
//   );
// };

// function Terrain({ itemPositions, parsedData }) {
//   const meshRef = useRef();
//   const size = 50;
//   const divisions = 50;

//   const [geometry, colorAttribute] = useMemo(() => {
//     const geometry = new THREE.PlaneGeometry(size, size, divisions, divisions);
//     geometry.rotateX(-Math.PI / 2);
//     const width = divisions + 1;
//     const depth = divisions + 1;

//     const heightmap = generateHeightmapWithRidges(width, depth, itemPositions, parsedData);

//     const positionAttribute = geometry.attributes.position;
//     const colorAttribute = new THREE.BufferAttribute(
//       new Float32Array(positionAttribute.count * 3),
//       3
//     );
//     const color = new THREE.Color();

//     for (let i = 0; i < positionAttribute.count; i++) {
//       const vertex = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
//       const elevation = heightmap[i];
//       vertex.y = elevation;
//       positionAttribute.setXYZ(i, vertex.x, vertex.y, vertex.z);

//       // Apply gradient color for ridges
//       const elevationNormalized = (elevation + 1) / 3; // Normalize elevation to [0, 1]
//       const ridgeColor = new THREE.Color().setHSL(0.6 - elevationNormalized * 0.6, 1, 0.5);
//       colorAttribute.setXYZ(i, ridgeColor.r, ridgeColor.g, ridgeColor.b);
//     }

//     geometry.setAttribute("color", colorAttribute);
//     geometry.computeVertexNormals();
//     console.log("Geometry and colors set up successfully.");

//     return [geometry, colorAttribute];
//   }, [itemPositions, parsedData]);

//   return (
//     <>
//       <mesh ref={meshRef} geometry={geometry}>
//         <meshStandardMaterial vertexColors={true} side={THREE.DoubleSide} flatShading={false} />
//       </mesh>
//       {itemPositions.map(({ item, position, metrics }) => (
//         <Html
//           key={item}
//           position={[position[0], metrics.lift * 2, position[1]]} // Adjusted height scaling for label positioning
//           style={{ pointerEvents: "none" }}
//         >
//           <div
//             style={{
//               color: "black",
//               fontSize: "8px",
//               textAlign: "center",
//               background: "rgba(255,255,255,0.7)",
//               padding: "2px",
//               borderRadius: "2px",
//             }}
//           >
//             <strong>{item}</strong><br />
//             <strong>Support:</strong> {metrics.support.toFixed(2)}<br />
//             <strong>Lift:</strong> {metrics.lift.toFixed(2)}
//           </div>
//         </Html>
//       ))}
//     </>
//   );
// }

// export default FPGraphPage;





// // import React, { useMemo, useRef } from "react";
// // import { Canvas } from "@react-three/fiber";
// // import { OrbitControls, Html } from "@react-three/drei";
// // import * as THREE from "three";
// // import { createNoise3D } from 'simplex-noise';
// // import { useDataset } from "../components/DataFetcher";
// // import * as d3 from "d3-force";

// // const colorPalette = {
// //   dkpurple: "#2c294b",
// //   mdpurple: "#3b3484",
// //   ltpurple: "#7174b0",
// //   dkorange: "#de6736",
// //   mdorange: "#e08a3c",
// //   ltorange: "#ebb844",
// //   cranberry: "#762861",
// //   magenta: "#c5316a",
// //   mdgreen: "#80ba55",
// // };

// // function getColorFromElevation(elevation) {
// //   if (elevation < -0.5) return colorPalette.dkpurple;
// //   else if (elevation < 0) return colorPalette.mdpurple;
// //   else if (elevation < 0.5) return colorPalette.ltpurple;
// //   else if (elevation < 1) return colorPalette.dkorange;
// //   else if (elevation < 1.5) return colorPalette.mdorange;
// //   else if (elevation < 2) return colorPalette.ltorange;
// //   else if (elevation < 2.5) return colorPalette.cranberry;
// //   else return colorPalette.magenta;
// // }

// // function parseItemList(itemList) {
// //   if (!itemList) return [];
// //   return Array.isArray(itemList) ? itemList.flat(Infinity) : [];
// // }

// // function arrangeItems(uniqueItems, parsedData, size = 50) {
// //   const nodes = uniqueItems.map((item) => ({ id: item }));
// //   const links = parsedData.flatMap((rule) =>
// //     rule.antecedents.map((antecedent) => ({
// //       source: antecedent,
// //       target: rule.consequents[0], // Assuming one consequent for simplicity
// //       value: rule.support,
// //     }))
// //   );

// //   const simulation = d3.forceSimulation(nodes)
// //     .force("link", d3.forceLink(links).id((d) => d.id).strength((d) => d.value))
// //     .force("charge", d3.forceManyBody().strength(-50))
// //     .force("center", d3.forceCenter(0, 0))
// //     .force("collision", d3.forceCollide(10));

// //   for (let i = 0; i < 300; i++) simulation.tick();

// //   return nodes.map((node) => ({
// //     item: node.id,
// //     position: [node.x * size, node.y * size],
// //   }));
// // }

// // function distributeMetrics(parsedData, itemMetrics) {
// //   parsedData.forEach((rule) => {
// //     const totalItems = rule.antecedents.length + rule.consequents.length;
// //     const weight = 1 / totalItems;

// //     rule.antecedents.forEach((item) => {
// //       itemMetrics[item].support += rule.support * weight;
// //       itemMetrics[item].lift += rule.lift * weight;
// //       itemMetrics[item].conviction += rule.conviction * weight;
// //       itemMetrics[item].zhangs_metric += rule.zhangs_metric * weight;
// //       itemMetrics[item].count += weight;
// //     });

// //     rule.consequents.forEach((item) => {
// //       itemMetrics[item].support += rule.support * weight;
// //       itemMetrics[item].lift += rule.lift * weight;
// //       itemMetrics[item].conviction += rule.conviction * weight;
// //       itemMetrics[item].zhangs_metric += rule.zhangs_metric * weight;
// //       itemMetrics[item].count += weight;
// //     });
// //   });
// // }

// // function generateHeightmapWithRidges(width, depth, itemPositions, parsedData, scale = 15) {
// //   console.log("Generating heightmap with width:", width, "depth:", depth);

// //   const simplex = createNoise3D();
// //   const heightmap = new Float32Array(width * depth);

// //   for (let i = 0; i < width * depth; i++) {
// //     const x = (i % width) / width;
// //     const z = Math.floor(i / width) / depth;
// //     heightmap[i] = simplex(x * scale, z * scale, 0) * 2;
// //   }

// //   const sigma = 0.05;
// //   parsedData.forEach((rule) => {
// //     const antecedentPositions = rule.antecedents.map((item) => {
// //       const pos = itemPositions.find((p) => p.item === item);
// //       return pos ? pos.position : null;
// //     }).filter(Boolean);

// //     const consequentPosition = itemPositions.find((p) => p.item === rule.consequents[0])?.position;
// //     if (!consequentPosition) return;

// //     antecedentPositions.forEach(([ax, az]) => {
// //       const [cx, cz] = consequentPosition;

// //       for (let i = 0; i < width * depth; i++) {
// //         const x = (i % width) / width;
// //         const z = Math.floor(i / width) / depth;

// //         const dxA = x - (ax + 25) / 50;
// //         const dzA = z - (az + 25) / 50;
// //         const dxC = x - (cx + 25) / 50;
// //         const dzC = z - (cz + 25) / 50;

// //         const distSqA = dxA * dxA + dzA * dzA;
// //         const distSqC = dxC * dxC + dzC * dzC;

// //         const ridgeInfluence = Math.exp(-distSqA / (2 * sigma * sigma)) + Math.exp(-distSqC / (2 * sigma * sigma));
// //         heightmap[i] += ridgeInfluence * rule.support;
// //       }
// //     });
// //   });

// //   console.log("Heightmap with ridges generated.", heightmap);
// //   return heightmap;
// // }

// // const FPGraphPage = () => {
// //   const { data } = useDataset("fpgrowth");

// //   const fpgrowthData = useMemo(() => {
// //     if (!data?.FPGrowth) return null;
// //     const cleanedString = data.FPGrowth.replace(/Infinity/g, "2");
// //     try {
// //       return JSON.parse(cleanedString);
// //     } catch {
// //       console.error("Error parsing FPGrowth data.", cleanedString);
// //       return null;
// //     }
// //   }, [data]);

// //   const parsedData = useMemo(() => {
// //     if (!fpgrowthData || !fpgrowthData.data) return [];
// //     const { columns, data: rows } = fpgrowthData;
// //     return rows.map((row) => {
// //       const rowData = {};
// //       columns.forEach((col, index) => {
// //         rowData[col] = row[index];
// //       });
// //       const antecedents = parseItemList(rowData["antecedents"]);
// //       const consequents = parseItemList(rowData["consequents"]);
// //       return {
// //         antecedents,
// //         consequents,
// //         antecedent_support: rowData["antecedent support"] || 0,
// //         consequent_support: rowData["consequent support"] || 0,
// //         support: rowData["support"] || 0,
// //         confidence: rowData["confidence"] || 0,
// //         lift: rowData["lift"] || 0,
// //         conviction: rowData["conviction"] || 0,
// //         zhangs_metric: rowData["zhangs_metric"] || 0,
// //       };
// //     });
// //   }, [fpgrowthData]);

// //   const uniqueItems = useMemo(() => {
// //     const items = new Set();
// //     parsedData.forEach((rule) => {
// //       rule.antecedents.forEach((item) => items.add(item));
// //       rule.consequents.forEach((item) => items.add(item));
// //     });
// //     console.log("Unique items parsed:", Array.from(items));
// //     return Array.from(items);
// //   }, [parsedData]);

// //   const itemMetrics = useMemo(() => {
// //     const metrics = {};
// //     uniqueItems.forEach((item) => {
// //       metrics[item] = {
// //         support: 0,
// //         conviction: 0,
// //         zhangs_metric: 0,
// //         lift: 0,
// //         count: 0,
// //       };
// //     });
// //     distributeMetrics(parsedData, metrics);
// //     console.log("Item metrics calculated:", metrics);
// //     return metrics;
// //   }, [parsedData, uniqueItems]);

// //   const itemPositions = useMemo(() => {
// //     return arrangeItems(uniqueItems, parsedData, 50).map(({ item, position }) => ({
// //       item,
// //       position,
// //       metrics: itemMetrics[item] || { support: 0, lift: 0 },
// //     }));
// //   }, [uniqueItems, itemMetrics, parsedData]);

// //   return (
// //     <div style={{ height: "100vh", width: "100vw" }}>
// //       <Canvas camera={{ position: [0, 50, 50], fov: 50 }}>
// //         <OrbitControls enableZoom={true} />
// //         <ambientLight intensity={0.5} />
// //         <directionalLight position={[10, 30, 10]} intensity={1} />
// //         {parsedData && <Terrain itemPositions={itemPositions} parsedData={parsedData} />}
// //       </Canvas>
// //     </div>
// //   );
// // };

// // function Terrain({ itemPositions, parsedData }) {
// //   const meshRef = useRef();
// //   const size = 50;
// //   const divisions = 50;

// //   const [geometry, colorAttribute] = useMemo(() => {
// //     const geometry = new THREE.PlaneGeometry(size, size, divisions, divisions);
// //     geometry.rotateX(-Math.PI / 2);
// //     const width = divisions + 1;
// //     const depth = divisions + 1;

// //     const heightmap = generateHeightmapWithRidges(width, depth, itemPositions, parsedData);

// //     const positionAttribute = geometry.attributes.position;
// //     const colorAttribute = new THREE.BufferAttribute(
// //       new Float32Array(positionAttribute.count * 3),
// //       3
// //     );
// //     const color = new THREE.Color();

// //     for (let i = 0; i < positionAttribute.count; i++) {
// //       const vertex = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
// //       const elevation = heightmap[i];
// //       vertex.y = elevation;
// //       positionAttribute.setXYZ(i, vertex.x, vertex.y, vertex.z);

// //       // Apply gradient color for ridges
// //       const elevationNormalized = (elevation + 1) / 3; // Normalize elevation to [0, 1]
// //       const ridgeColor = new THREE.Color().setHSL(0.6 - elevationNormalized * 0.6, 1, 0.5);
// //       colorAttribute.setXYZ(i, ridgeColor.r, ridgeColor.g, ridgeColor.b);
// //     }

// //     geometry.setAttribute("color", colorAttribute);
// //     geometry.computeVertexNormals();
// //     console.log("Geometry and colors set up successfully.");

// //     return [geometry, colorAttribute];
// //   }, [itemPositions, parsedData]);

// //   return (
// //     <>
// //       <mesh ref={meshRef} geometry={geometry}>
// //         <meshStandardMaterial vertexColors={true} side={THREE.DoubleSide} flatShading={false} />
// //       </mesh>
// //       {itemPositions.map(({ item, position, metrics }) => (
// //         <Html
// //           key={item}
// //           position={[position[0], metrics.lift * 2, position[1]]} // Adjusted height scaling for label positioning
// //           style={{ pointerEvents: "none" }}
// //         >
// //           <div
// //             style={{
// //               color: "black",
// //               fontSize: "8px",
// //               textAlign: "center",
// //               background: "rgba(255,255,255,0.7)",
// //               padding: "2px",
// //               borderRadius: "2px",
// //             }}
// //           >
// //             <strong>{item}</strong><br />
// //             <strong>Support:</strong> {metrics.support.toFixed(2)}<br />
// //             <strong>Lift:</strong> {metrics.lift.toFixed(2)}
// //           </div>
// //         </Html>
// //       ))}
// //     </>
// //   );
// // }

// // export default FPGraphPage;




// // // import React, { useMemo, useRef } from "react";
// // // import { Canvas } from "@react-three/fiber";
// // // import { OrbitControls, Html } from "@react-three/drei";
// // // import * as THREE from "three";
// // // import { createNoise3D } from 'simplex-noise';
// // // import { useDataset } from "../components/DataFetcher";

// // // const colorPalette = {
// // //   dkpurple: "#2c294b",
// // //   mdpurple: "#3b3484",
// // //   ltpurple: "#7174b0",
// // //   dkorange: "#de6736",
// // //   mdorange: "#e08a3c",
// // //   ltorange: "#ebb844",
// // //   cranberry: "#762861",
// // //   magenta: "#c5316a",
// // //   mdgreen: "#80ba55",
// // // };

// // // function getColorFromElevation(elevation) {
// // //   if (elevation < -0.5) return colorPalette.dkpurple;
// // //   else if (elevation < 0) return colorPalette.mdpurple;
// // //   else if (elevation < 0.5) return colorPalette.ltpurple;
// // //   else if (elevation < 1) return colorPalette.dkorange;
// // //   else if (elevation < 1.5) return colorPalette.mdorange;
// // //   else if (elevation < 2) return colorPalette.ltorange;
// // //   else if (elevation < 2.5) return colorPalette.cranberry;
// // //   else return colorPalette.magenta;
// // // }

// // // function parseItemList(itemList) {
// // //   if (!itemList) return [];
// // //   return Array.isArray(itemList) ? itemList.flat(Infinity) : [];
// // // }

// // // function arrangeItems(uniqueItems, size = 50) {
// // //   const n = uniqueItems.length;
// // //   const gridSize = Math.ceil(Math.sqrt(n));
// // //   const spacing = size / gridSize;
// // //   const half = size / 2;

// // //   return uniqueItems.map((item, index) => {
// // //     const xIndex = index % gridSize;
// // //     const zIndex = Math.floor(index / gridSize);

// // //     const x = xIndex * spacing - half + spacing / 2;
// // //     const z = zIndex * spacing - half + spacing / 2;

// // //     return { item, position: [x, z] };
// // //   });
// // // }

// // // function generateHeightmapWithPeaks(width, depth, itemPositions, itemMetrics, scale = 15) {
// // //   console.log("Generating heightmap with width:", width, "depth:", depth);

// // //   const simplex = createNoise3D();
// // //   const heightmap = new Float32Array(width * depth);

// // //   for (let i = 0; i < width * depth; i++) {
// // //     const x = (i % width) / width;
// // //     const z = Math.floor(i / width) / depth;
// // //     let value = simplex(x * scale, z * scale, 0) * 2;
// // //     heightmap[i] = value;
// // //   }

// // //   const sigma = 0.05;
// // //   itemPositions.forEach(({ position: [ix, iz], metrics }) => {
// // //     // const peakHeight = ((metrics.lift - 1) * 1.5 + metrics.support * 2);
// // //     const peakHeight = (((metrics.lift - 1) * 1.5 + metrics.support * 2) / 50);

// // //     console.log(`Adding peak for item at (${ix}, ${iz}) with height ${peakHeight}`);

// // //     for (let i = 0; i < width * depth; i++) {
// // //       const x = (i % width) / width;
// // //       const z = Math.floor(i / width) / depth;
// // //       const dx = x - (ix + 25) / 50;
// // //       const dz = z - (iz + 25) / 50;
// // //       const distSq = dx * dx + dz * dz;
// // //       const influence = Math.exp(-distSq / (2 * sigma * sigma)) * peakHeight;
// // //       heightmap[i] += influence;
// // //     }
// // //   });

// // //   console.log("Heightmap generated.", heightmap);
// // //   return heightmap;
// // // }

// // // const FPGraphPage = () => {
// // //   const { data } = useDataset("fpgrowth");

// // //   const fpgrowthData = useMemo(() => {
// // //     if (!data?.FPGrowth) return null;
// // //     const cleanedString = data.FPGrowth.replace(/Infinity/g, "2");
// // //     try {
// // //       return JSON.parse(cleanedString);
// // //     } catch {
// // //       console.error("Error parsing FPGrowth data.", cleanedString);
// // //       return null;
// // //     }
// // //   }, [data]);

// // //   const parsedData = useMemo(() => {
// // //     if (!fpgrowthData || !fpgrowthData.data) return [];
// // //     const { columns, data: rows } = fpgrowthData;
// // //     return rows.map((row) => {
// // //       const rowData = {};
// // //       columns.forEach((col, index) => {
// // //         rowData[col] = row[index];
// // //       });
// // //       const antecedents = parseItemList(rowData["antecedents"]);
// // //       const consequents = parseItemList(rowData["consequents"]);
// // //       return {
// // //         antecedents,
// // //         consequents,
// // //         antecedent_support: rowData["antecedent support"] || 0,
// // //         consequent_support: rowData["consequent support"] || 0,
// // //         support: rowData["support"] || 0,
// // //         confidence: rowData["confidence"] || 0,
// // //         lift: rowData["lift"] || 0,
// // //         conviction: rowData["conviction"] || 0,
// // //         zhangs_metric: rowData["zhangs_metric"] || 0,
// // //       };
// // //     });
// // //   }, [fpgrowthData]);

// // //   const uniqueItems = useMemo(() => {
// // //     const items = new Set();
// // //     parsedData.forEach((rule) => {
// // //       rule.antecedents.forEach((item) => items.add(item));
// // //       rule.consequents.forEach((item) => items.add(item));
// // //     });
// // //     console.log("Unique items parsed:", Array.from(items));
// // //     return Array.from(items);
// // //   }, [parsedData]);

// // //   const itemMetrics = useMemo(() => {
// // //     const metrics = {};
// // //     uniqueItems.forEach((item) => {
// // //       metrics[item] = {
// // //         support: 0,
// // //         conviction: 0,
// // //         zhangs_metric: 0,
// // //         lift: 0,
// // //         count: 0,
// // //       };
// // //     });
// // //     parsedData.forEach((rule) => {
// // //       const itemsInRule = [...rule.antecedents, ...rule.consequents];
// // //       itemsInRule.forEach((item) => {
// // //         metrics[item].support += rule.support;
// // //         metrics[item].conviction += rule.conviction;
// // //         metrics[item].zhangs_metric += rule.zhangs_metric;
// // //         metrics[item].lift += rule.lift;
// // //         metrics[item].count += 1;
// // //       });
// // //     });
// // //     console.log("Item metrics calculated:", metrics);
// // //     return metrics;
// // //   }, [parsedData, uniqueItems]);

// // //   const itemPositions = useMemo(() => {
// // //     const basePositions = arrangeItems(uniqueItems, 50);
// // //     return basePositions.map(({ item, position }) => ({
// // //       item,
// // //       position,
// // //       metrics: itemMetrics[item] || { support: 0, lift: 0 },
// // //     }));
// // //   }, [uniqueItems, itemMetrics]);

// // //   return (
// // //     <div style={{ height: "100vh", width: "100vw" }}>
// // //       <Canvas camera={{ position: [0, 50, 50], fov: 50 }}>
// // //         <OrbitControls enableZoom={true} />
// // //         <ambientLight intensity={0.5} />
// // //         <directionalLight position={[10, 30, 10]} intensity={1} />
// // //         {parsedData && <Terrain itemPositions={itemPositions} />}
// // //       </Canvas>
// // //     </div>
// // //   );
// // // };

// // // function Terrain({ itemPositions }) {
// // //   const meshRef = useRef();
// // //   const size = 50;
// // //   const divisions = 50;

// // //   const [geometry, colorAttribute] = useMemo(() => {
// // //     const geometry = new THREE.PlaneGeometry(size, size, divisions, divisions);
// // //     geometry.rotateX(-Math.PI / 2);
// // //     const width = divisions + 1;
// // //     const depth = divisions + 1;

// // //     const heightmap = generateHeightmapWithPeaks(width, depth, itemPositions, itemPositions.reduce((acc, ip) => {
// // //       acc[ip.item] = ip.metrics;
// // //       return acc;
// // //     }, {}));

// // //     const positionAttribute = geometry.attributes.position;
// // //     const colorAttribute = new THREE.BufferAttribute(
// // //       new Float32Array(positionAttribute.count * 3),
// // //       3
// // //     );
// // //     const color = new THREE.Color();

// // //     for (let i = 0; i < positionAttribute.count; i++) {
// // //       const vertex = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
// // //       const elevation = heightmap[i];
// // //       vertex.y = elevation;
// // //       positionAttribute.setXYZ(i, vertex.x, vertex.y, vertex.z);

// // //       color.set(getColorFromElevation(elevation));
// // //       colorAttribute.setXYZ(i, color.r, color.g, color.b);
// // //     }

// // //     geometry.setAttribute("color", colorAttribute);
// // //     geometry.computeVertexNormals();
// // //     console.log("Geometry and colors set up successfully.");

// // //     return [geometry, colorAttribute];
// // //   }, [itemPositions]);

// // //   return (
// // //     <>
// // //       <mesh ref={meshRef} geometry={geometry}>
// // //         <meshStandardMaterial vertexColors={true} side={THREE.DoubleSide} flatShading={false} />
// // //       </mesh>
// // //       {itemPositions.map(({ item, position, metrics }) => (
// // //         <Html
// // //           key={item}
// // //           position={[position[0], metrics.lift * 2, position[1]]} // Adjusted height scaling for label positioning
// // //           style={{ pointerEvents: "none" }}
// // //         >
// // //           <div
// // //             style={{
// // //               color: "black",
// // //               fontSize: "8px",
// // //               textAlign: "center",
// // //               background: "rgba(255,255,255,0.7)",
// // //               padding: "2px",
// // //               borderRadius: "2px",
// // //             }}
// // //           >
// // //             <strong>{item}</strong><br />
// // //             <strong>Support:</strong> {metrics.support.toFixed(2)}<br />
// // //             <strong>Lift:</strong> {metrics.lift.toFixed(2)}
// // //           </div>
// // //         </Html>
// // //       ))}
// // //     </>
// // //   );
// // // }

// // // export default FPGraphPage;



// // // // import React, { useMemo } from "react";
// // // // import { Canvas } from "@react-three/fiber";
// // // // import { OrbitControls, Html } from "@react-three/drei";
// // // // import { useDataset } from "../components/DataFetcher";
// // // // import * as THREE from "three";

// // // // // Color palette
// // // // const colorPalette = {
// // // //   dkpurple: "#2c294b",
// // // //   mdpurple: "#3b3484",
// // // //   ltpurple: "#7174b0",
// // // //   dkorange: "#de6736",
// // // //   mdorange: "#e08a3c",
// // // //   ltorange: "#ebb844",
// // // //   cranberry: "#762861",
// // // //   magenta: "#c5316a",
// // // //   ltgray: "#ebe5df",
// // // //   mdgray: "#958e86",
// // // //   brown: "#5d3c23",
// // // //   mdgreen: "#80ba55",
// // // // };

// // // // function getColorFromZhangsMetric(zhangsMetric) {
// // // //   if (zhangsMetric < 0.5) return colorPalette.dkpurple;
// // // //   else if (zhangsMetric < 0.6) return colorPalette.mdpurple;
// // // //   else if (zhangsMetric < 0.7) return colorPalette.ltpurple;
// // // //   else if (zhangsMetric < 0.8) return colorPalette.mdorange;
// // // //   else return colorPalette.mdgreen;
// // // // }

// // // // function parseItemList(itemList) {
// // // //   if (!itemList) return [];
// // // //   return Array.isArray(itemList) ? itemList.flat(Infinity) : [];
// // // // }

// // // // function computeForceLayout(items, rules) {
// // // //   const graph = { nodes: [], links: [] };
// // // //   const itemIndex = new Map();

// // // //   // Initialize positions with a much wider spread and zero initial velocity
// // // //   items.forEach((it, i) => {
// // // //     itemIndex.set(it, i);
// // // //     graph.nodes.push({
// // // //       id: it,
// // // //       x: Math.random() * 300 - 150, // Spread nodes over a larger grid
// // // //       z: Math.random() * 300 - 150,
// // // //       vx: 0,
// // // //       vz: 0,
// // // //     });
// // // //   });

// // // //   // Generate links based on rules
// // // //   rules.forEach((rule) => {
// // // //     const allItems = [...rule.antecedents, ...rule.consequents];
// // // //     for (let i = 0; i < allItems.length; i++) {
// // // //       for (let j = i + 1; j < allItems.length; j++) {
// // // //         graph.links.push({
// // // //           source: itemIndex.get(allItems[i]),
// // // //           target: itemIndex.get(allItems[j]),
// // // //         });
// // // //       }
// // // //     }
// // // //   });

// // // //   const alphaDecay = 0.95; // Gradual decay for better convergence
// // // //   let alpha = 0.8;
// // // //   const repulsionStrength = 80000; // Strong repulsion for wider spacing
// // // //   const linkDistance = 50; // Reasonable distance between linked nodes
// // // //   const linkStrength = 0.02; // Ensure some clustering, but not too tight

// // // //   for (let iter = 0; iter < 600; iter++) {
// // // //     // Apply repulsion forces
// // // //     for (let i = 0; i < graph.nodes.length; i++) {
// // // //       const n1 = graph.nodes[i];
// // // //       let fx = 0;
// // // //       let fz = 0;
// // // //       for (let j = 0; j < graph.nodes.length; j++) {
// // // //         if (i === j) continue;
// // // //         const n2 = graph.nodes[j];
// // // //         const dx = n1.x - n2.x;
// // // //         const dz = n1.z - n2.z;
// // // //         const distSq = dx * dx + dz * dz + 1; // Avoid divide by zero
// // // //         const force = repulsionStrength / distSq;
// // // //         fx += (dx / Math.sqrt(distSq)) * force;
// // // //         fz += (dz / Math.sqrt(distSq)) * force;
// // // //       }
// // // //       n1.vx += fx * alpha;
// // // //       n1.vz += fz * alpha;
// // // //     }

// // // //     // Apply link forces
// // // //     for (let l = 0; l < graph.links.length; l++) {
// // // //       const link = graph.links[l];
// // // //       const s = graph.nodes[link.source];
// // // //       const t = graph.nodes[link.target];
// // // //       const dx = t.x - s.x;
// // // //       const dz = t.z - s.z;
// // // //       const dist = Math.sqrt(dx * dx + dz * dz) || 1;
// // // //       const diff = dist - linkDistance;
// // // //       const force = diff * linkStrength;
// // // //       const fx = (dx / dist) * force;
// // // //       const fz = (dz / dist) * force;
// // // //       s.vx += fx;
// // // //       s.vz += fz;
// // // //       t.vx -= fx;
// // // //       t.vz -= fz;
// // // //     }

// // // //     // Update positions and reduce velocity
// // // //     for (let i = 0; i < graph.nodes.length; i++) {
// // // //       const n = graph.nodes[i];
// // // //       n.x += n.vx * 0.05; // Small step size to stabilize
// // // //       n.z += n.vz * 0.05;
// // // //       n.vx *= 0.6; // Stronger velocity damping
// // // //       n.vz *= 0.6;
// // // //     }

// // // //     alpha *= alphaDecay;
// // // //   }

// // // //   console.log("Computed Node Positions:", graph.nodes);

// // // //   return graph.nodes.map((n) => ({ item: n.id, position: [n.x, 0, n.z] }));
// // // // }



// // // // const FPGraphPage = () => {
// // // //   const { data } = useDataset("fpgrowth");

// // // //   const fpgrowthData = useMemo(() => {
// // // //     if (!data?.FPGrowth) return null;
// // // //     const cleanedString = data.FPGrowth.replace(/Infinity/g, "2");
// // // //     try {
// // // //       return JSON.parse(cleanedString);
// // // //     } catch (error) {
// // // //       return null;
// // // //     }
// // // //   }, [data]);

// // // //   const parsedData = useMemo(() => {
// // // //     if (!fpgrowthData || !fpgrowthData.data) return [];
// // // //     const { columns, data: rows } = fpgrowthData;

// // // //     return rows.map((row) => {
// // // //       const rowData = {};
// // // //       columns.forEach((col, index) => {
// // // //         rowData[col] = row[index];
// // // //       });

// // // //       const antecedents = parseItemList(rowData["antecedents"]);
// // // //       const consequents = parseItemList(rowData["consequents"]);

// // // //       return {
// // // //         antecedents,
// // // //         consequents,
// // // //         antecedent_support: rowData["antecedent support"] || 0,
// // // //         consequent_support: rowData["consequent support"] || 0,
// // // //         support: rowData["support"] || 0,
// // // //         confidence: rowData["confidence"] || 0,
// // // //         lift: rowData["lift"] || 0,
// // // //         representativity: rowData["representativity"] || 0,
// // // //         leverage: rowData["leverage"] || 0,
// // // //         conviction: rowData["conviction"] || 0,
// // // //         zhangs_metric: rowData["zhangs_metric"] || 0,
// // // //         jaccard: rowData["jaccard"] || 0,
// // // //         certainty: rowData["certainty"] || 0,
// // // //         kulczynski: rowData["kulczynski"] || 0,
// // // //       };
// // // //     });
// // // //   }, [fpgrowthData]);

// // // //   const uniqueItems = useMemo(() => {
// // // //     const items = new Set();
// // // //     parsedData.forEach((rule) => {
// // // //       rule.antecedents.forEach((item) => items.add(item));
// // // //       rule.consequents.forEach((item) => items.add(item));
// // // //     });
// // // //     return Array.from(items);
// // // //   }, [parsedData]);

// // // //   const itemPositions = useMemo(() => {
// // // //     return computeForceLayout(uniqueItems, parsedData);
// // // //   }, [uniqueItems, parsedData]);

// // // //   const itemMetrics = useMemo(() => {
// // // //     const metrics = {};
// // // //     uniqueItems.forEach((item) => {
// // // //       metrics[item] = {
// // // //         support: 0,
// // // //         conviction: 0,
// // // //         zhangs_metric: 0,
// // // //         lift: 0,
// // // //         count: 0,
// // // //       };
// // // //     });

// // // //     parsedData.forEach((rule) => {
// // // //       const itemsInRule = [...rule.antecedents, ...rule.consequents];
// // // //       itemsInRule.forEach((item) => {
// // // //         metrics[item].support += rule.support;
// // // //         metrics[item].conviction += rule.conviction;
// // // //         metrics[item].zhangs_metric += rule.zhangs_metric;
// // // //         metrics[item].lift += rule.lift;
// // // //         metrics[item].count += 1;
// // // //       });
// // // //     });

// // // //     return metrics;
// // // //   }, [parsedData, uniqueItems]);

// // // //   return (
// // // //     <div style={{ height: "100vh", width: "100vw" }}>
// // // //       <Canvas camera={{ position: [0, 50, 50], fov: 50 }}>
// // // //         <OrbitControls enableZoom={true} />
// // // //         <ambientLight intensity={0.5} />
// // // //         <directionalLight position={[10, 30, 10]} intensity={1} />
// // // //         {parsedData && (
// // // //           <Terrain
// // // //             itemPositions={itemPositions}
// // // //             itemMetrics={itemMetrics}
// // // //             uniqueItems={uniqueItems}
// // // //           />
// // // //         )}
// // // //       </Canvas>
// // // //     </div>
// // // //   );
// // // // };

// // // // function Terrain({ itemPositions, itemMetrics, uniqueItems }) {
// // // //   const meshRef = React.useRef();

// // // //   const [geometry, colorAttribute] = React.useMemo(() => {
// // // //     const size = 50;
// // // //     const divisions = 50;
// // // //     const geometry = new THREE.PlaneGeometry(size, size, divisions, divisions);
// // // //     geometry.rotateX(-Math.PI / 2);

// // // //     const positionAttribute = geometry.attributes.position;
// // // //     const colorAttribute = new THREE.BufferAttribute(
// // // //       new Float32Array(positionAttribute.count * 3),
// // // //       3
// // // //     );
// // // //     const color = new THREE.Color();

// // // //     // Reduced influence radius for sharper peaks
// // // //     const influenceRadius = 8;
// // // //     const N = 3; 

// // // //     for (let i = 0; i < positionAttribute.count; i++) {
// // // //       const vertex = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
// // // //       const contributions = [];
// // // //       itemPositions.forEach(({ item, position }) => {
// // // //         const dx = vertex.x - position[0];
// // // //         const dz = vertex.z - position[2];
// // // //         const dist = Math.sqrt(dx * dx + dz * dz);
// // // //         if (dist < influenceRadius) {
// // // //           contributions.push({ item, dist });
// // // //         }
// // // //       });
    
// // // //       // Log contributions for debugging
// // // //       console.log(`Vertex ${i} at (${vertex.x.toFixed(2)}, ${vertex.z.toFixed(2)}) has contributions:`, contributions);
    
// // // //       contributions.sort((a, b) => a.dist - b.dist);
// // // //       const nearestItems = contributions.slice(0, N);
    
// // // //       let weightedHeight = 0;
// // // //       let totalWeight = 0;
    
// // // //       nearestItems.forEach(({ item, dist }) => {
// // // //         const m = itemMetrics[item];
// // // //         if (!m) {
// // // //           console.warn(`Missing metrics for item: ${item}`);
// // // //           return;
// // // //         }
// // // //         const weight = 1 / (dist + 0.5);
// // // //         const composite = (m.lift - 1) * 1.5 + m.support * 2; // Reduce peak contribution
// // // //         weightedHeight += composite * weight;
// // // //         totalWeight += weight;
    
// // // //         // Log contributions to weighted height
// // // //         console.log(
// // // //           `Item: ${item}, Distance: ${dist.toFixed(2)}, Weight: ${weight.toFixed(3)}, Composite: ${composite.toFixed(3)}`
// // // //         );
// // // //       });
    
// // // //       const height = totalWeight > 0 ? weightedHeight / totalWeight : 0;
// // // //       const smoothHeight = height * 0.8 + vertex.y * 0.2; // Blend with existing height
    
// // // //       // Log final height calculation
// // // //       console.log(
// // // //         `Vertex ${i}: Weighted Height = ${weightedHeight.toFixed(2)}, Total Weight = ${totalWeight.toFixed(2)}, Final Height = ${smoothHeight.toFixed(2)}`
// // // //       );
    
// // // //       vertex.y = smoothHeight;
    
// // // //       color.set(getColorFromZhangsMetric(height));
// // // //       positionAttribute.setXYZ(i, vertex.x, vertex.y, vertex.z);
// // // //       colorAttribute.setXYZ(i, color.r, color.g, color.b);
// // // //     }
       

// // // //     geometry.setAttribute("color", colorAttribute);
// // // //     geometry.computeVertexNormals();
// // // //     return [geometry, colorAttribute];
// // // //   }, [itemPositions, itemMetrics]);

// // // //   return (
// // // //     <>
// // // //       <mesh ref={meshRef} geometry={geometry}>
// // // //         <meshStandardMaterial vertexColors={true} side={THREE.DoubleSide} flatShading={false} />
// // // //       </mesh>
// // // //       {itemPositions.map(({ item, position }) => {
// // // //         const metrics = itemMetrics[item];
// // // //         const height = ((metrics.lift - 1) * 100 + (metrics.support * 100)) / 10;
// // // //         return (
// // // //           <Html key={item} position={[position[0], height + 2, position[2]]}>
// // // //             <div
// // // //               style={{
// // // //                 color: "black",
// // // //                 fontSize: "8px",
// // // //                 textAlign: "center",
// // // //                 background: "rgba(255,255,255,0.7)",
// // // //                 padding: "2px",
// // // //                 borderRadius: "2px",
// // // //               }}
// // // //             >
// // // //               <strong>{item}</strong> <br />
// // // //               <strong>Support:</strong> {metrics.support.toFixed(2)} <br />
// // // //               <strong>Conviction:</strong> {metrics.conviction.toFixed(2)} <br />
// // // //               <strong>Lift:</strong> {((metrics.lift / metrics.count) || 0).toFixed(2)} <br />
// // // //               <strong>Zhang's Metric:</strong> {metrics.zhangs_metric.toFixed(2)}
// // // //             </div>
// // // //           </Html>
// // // //         );
// // // //       })}
// // // //     </>
// // // //   );
// // // // }

// // // // export default FPGraphPage;







// // // // // import React, { useMemo } from "react";
// // // // // import { Canvas } from "@react-three/fiber";
// // // // // import { OrbitControls, Html } from "@react-three/drei";
// // // // // import { useDataset } from "../components/DataFetcher";
// // // // // import * as THREE from "three";

// // // // // // Color palette
// // // // // const colorPalette = {
// // // // //   dkpurple: "#2c294b",
// // // // //   mdpurple: "#3b3484",
// // // // //   ltpurple: "#7174b0",
// // // // //   dkorange: "#de6736",
// // // // //   mdorange: "#e08a3c",
// // // // //   ltorange: "#ebb844",
// // // // //   cranberry: "#762861",
// // // // //   magenta: "#c5316a",
// // // // //   ltgray: "#ebe5df",
// // // // //   mdgray: "#958e86",
// // // // //   brown: "#5d3c23",
// // // // //   mdgreen: "#80ba55",
// // // // // };

// // // // // // Function to map Zhang's Metric to color
// // // // // function getColorFromZhangsMetric(zhangsMetric) {
// // // // //   if (zhangsMetric < 0.5) return colorPalette.dkpurple;
// // // // //   else if (zhangsMetric < 0.6) return colorPalette.mdpurple;
// // // // //   else if (zhangsMetric < 0.7) return colorPalette.ltpurple;
// // // // //   else if (zhangsMetric < 0.8) return colorPalette.mdorange;
// // // // //   else return colorPalette.mdgreen;
// // // // // }

// // // // // // Helper function to parse items
// // // // // function parseItemList(itemList) {
// // // // //   if (!itemList) return [];
// // // // //   // Flatten the array completely
// // // // //   return Array.isArray(itemList) ? itemList.flat(Infinity) : [];
// // // // // }

// // // // // // Main Component
// // // // // const FPGraphPage = () => {
// // // // //   const { data } = useDataset("fpgrowth");
// // // // //   console.log("Fetched data:", data); // Log full data

// // // // //   const fpgrowthData = useMemo(() => {
// // // // //     if (!data?.FPGrowth) return null;

// // // // //     const cleanedString = data.FPGrowth.replace(/Infinity/g, "9"); // or "null"
// // // // //     const fpgrowthData = JSON.parse(cleanedString);
// // // // //     try {
// // // // //       return fpgrowthData;
// // // // //       // return JSON.parse(data.FPGrowth); // Parse the FPGrowth data
// // // // //     } catch (error) {
// // // // //       console.error("Error parsing FPGrowth data:", error);
// // // // //       return null;
// // // // //     }
// // // // //   }, [data]);

// // // // //   const parsedData = useMemo(() => {
// // // // //     if (!fpgrowthData || !fpgrowthData.data) return [];

// // // // //     const { columns, data: rows } = fpgrowthData;

// // // // //     return rows.map((row) => {
// // // // //       const rowData = {};
// // // // //       columns.forEach((col, index) => {
// // // // //         rowData[col] = row[index];
// // // // //       });

// // // // //       // Parse antecedents and consequents properly
// // // // //       const antecedents = parseItemList(rowData["antecedents"]);
// // // // //       const consequents = parseItemList(rowData["consequents"]);

// // // // //       return {
// // // // //         antecedents,
// // // // //         consequents,
// // // // //         antecedent_support: rowData["antecedent support"] || 0,
// // // // //         consequent_support: rowData["consequent support"] || 0,
// // // // //         support: rowData["support"] || 0,
// // // // //         confidence: rowData["confidence"] || 0,
// // // // //         lift: rowData["lift"] || 0,
// // // // //         representativity: rowData["representativity"] || 0,
// // // // //         leverage: rowData["leverage"] || 0,
// // // // //         conviction: rowData["conviction"] || 0,
// // // // //         zhangs_metric: rowData["zhangs_metric"] || 0,
// // // // //         jaccard: rowData["jaccard"] || 0,
// // // // //         certainty: rowData["certainty"] || 0,
// // // // //         kulczynski: rowData["kulczynski"] || 0,
// // // // //       };
// // // // //     });
// // // // //   }, [fpgrowthData]);

// // // // //   // Extract unique items
// // // // //   const uniqueItems = useMemo(() => {
// // // // //     const items = new Set();
// // // // //     parsedData.forEach((rule) => {
// // // // //       rule.antecedents.forEach((item) => items.add(item));
// // // // //       rule.consequents.forEach((item) => items.add(item));
// // // // //     });
// // // // //     return Array.from(items);
// // // // //   }, [parsedData]);

// // // // //   // Insert this function at the top-level (outside the component) to replace your existing item positioning logic
// // // // // function computeForceLayout(items, rules) {
// // // // //   const graph = { nodes: [], links: [] };
// // // // //   const itemIndex = new Map();

// // // // //   items.forEach((it, i) => {
// // // // //     itemIndex.set(it, i);
// // // // //     graph.nodes.push({ id: it, x: Math.random() * 50 - 25, z: Math.random() * 50 - 25 });
// // // // //   });

// // // // //   rules.forEach(rule => {
// // // // //     const allItems = [...rule.antecedents, ...rule.consequents];
// // // // //     for (let i = 0; i < allItems.length; i++) {
// // // // //       for (let j = i + 1; j < allItems.length; j++) {
// // // // //         graph.links.push({
// // // // //           source: itemIndex.get(allItems[i]),
// // // // //           target: itemIndex.get(allItems[j])
// // // // //         });
// // // // //       }
// // // // //     }
// // // // //   });

// // // // //   const alphaDecay = 0.98;
// // // // //   let alpha = 0.5;
// // // // //   const repulsionStrength = 1000; 
// // // // //   const linkDistance = 5;
// // // // //   const linkStrength = 0.1;

// // // // //   for (let iter = 0; iter < 300; iter++) {
// // // // //     for (let i = 0; i < graph.nodes.length; i++) {
// // // // //       const n1 = graph.nodes[i];
// // // // //       let fx = 0;
// // // // //       let fz = 0;
// // // // //       for (let j = 0; j < graph.nodes.length; j++) {
// // // // //         if (i === j) continue;
// // // // //         const n2 = graph.nodes[j];
// // // // //         const dx = n1.x - n2.x;
// // // // //         const dz = n1.z - n2.z;
// // // // //         const distSq = dx*dx + dz*dz + 0.1;
// // // // //         const force = repulsionStrength / distSq;
// // // // //         fx += (dx / Math.sqrt(distSq)) * force;
// // // // //         fz += (dz / Math.sqrt(distSq)) * force;
// // // // //       }
// // // // //       n1.vx = (n1.vx || 0) + fx * alpha;
// // // // //       n1.vz = (n1.vz || 0) + fz * alpha;
// // // // //     }

// // // // //     for (let l = 0; l < graph.links.length; l++) {
// // // // //       const link = graph.links[l];
// // // // //       const s = graph.nodes[link.source];
// // // // //       const t = graph.nodes[link.target];
// // // // //       const dx = t.x - s.x;
// // // // //       const dz = t.z - s.z;
// // // // //       const dist = Math.sqrt(dx*dx + dz*dz) || 0.1;
// // // // //       const diff = dist - linkDistance;
// // // // //       const force = diff * linkStrength;
// // // // //       const fx = (dx / dist) * force;
// // // // //       const fz = (dz / dist) * force;
// // // // //       s.vx = (s.vx || 0) + fx;
// // // // //       s.vz = (s.vz || 0) + fz;
// // // // //       t.vx = (t.vx || 0) - fx;
// // // // //       t.vz = (t.vz || 0) - fz;
// // // // //     }

// // // // //     for (let i = 0; i < graph.nodes.length; i++) {
// // // // //       const n = graph.nodes[i];
// // // // //       n.x += (n.vx || 0) * 0.01;
// // // // //       n.z += (n.vz || 0) * 0.01;
// // // // //       n.vx *= 0.9;
// // // // //       n.vz *= 0.9;
// // // // //     }

// // // // //     alpha *= alphaDecay;
// // // // //   }

// // // // //   return graph.nodes.map(n => ({ item: n.id, position: [n.x, 0, n.z] }));
// // // // // }

// // // // // // Inside FPGraphPage, replace the existing itemPositions useMemo with this:
// // // // // const itemPositions = useMemo(() => {
// // // // //   console.log("itemPositions uniqueItems:", uniqueItems); 
// // // // //   console.log("itemPositions parsedData:", parsedData); 
// // // // //   // console.log("Item Position Spread:", itemPositions.map(ip => ({ item: ip.item, pos: ip.position })));
// // // // //   return computeForceLayout(uniqueItems, parsedData);
// // // // // }, [uniqueItems, parsedData]);


// // // // //   // Aggregate metrics for each item
// // // // //   const itemMetrics = useMemo(() => {
// // // // //     const metrics = {};
// // // // //     uniqueItems.forEach((item) => {
// // // // //       metrics[item] = {
// // // // //         support: 0,
// // // // //         conviction: 0,
// // // // //         zhangs_metric: 0,
// // // // //         lift: 0,
// // // // //         count: 0,
// // // // //       };
// // // // //     });
  
// // // // //     parsedData.forEach((rule) => {
// // // // //       const itemsInRule = [...rule.antecedents, ...rule.consequents];
// // // // //       itemsInRule.forEach((item) => {
// // // // //         metrics[item].support += rule.support;
// // // // //         metrics[item].conviction += rule.conviction;
// // // // //         metrics[item].zhangs_metric += rule.zhangs_metric;
// // // // //         metrics[item].lift += rule.lift; // accumulate lift as well
// // // // //         metrics[item].count += 1;
// // // // //       });
// // // // //     });

    
// // // // //     // Sum totals for each item
// // // // //     uniqueItems.forEach((item) => {
// // // // //       // metrics[item].support /= metrics[item].count;
// // // // //       // metrics[item].conviction /= metrics[item].count;
// // // // //       // metrics[item].zhangs_metric /= metrics[item].count;
// // // // //       console.log("uniqueItems metrics:", metrics); 

// // // // //     });
// // // // //     return metrics;
// // // // //   }, [parsedData, uniqueItems]);

// // // // //   return (
// // // // //     <div style={{ height: "100vh", width: "100vw" }}>
// // // // //       <Canvas camera={{ position: [0, 50, 50], fov: 50 }}>
// // // // //         {/* Controls for rotation and zoom */}
// // // // //         <OrbitControls enableZoom={true} />

// // // // //         {/* Lighting */}
// // // // //         <ambientLight intensity={0.5} />
// // // // //         <directionalLight position={[10, 30, 10]} intensity={1} />

// // // // //         {/* Terrain */}
// // // // //         {parsedData && (
// // // // //           <Terrain
// // // // //             itemPositions={itemPositions}
// // // // //             itemMetrics={itemMetrics}
// // // // //             uniqueItems={uniqueItems}
// // // // //           />
// // // // //         )}
// // // // //       </Canvas>
// // // // //     </div>
// // // // //   );
// // // // // };

// // // // // // Replace the entire Terrain function with this:
// // // // // // function Terrain({ itemPositions, itemMetrics, uniqueItems }) {
// // // // // //   const meshRef = React.useRef();
// // // // // //   console.log("Terrain Unique items:", uniqueItems);
// // // // // //   console.log("Terrain Item positions:", itemPositions);
// // // // // //   console.log("Terrain Item metrics:", itemMetrics);

// // // // // //   const [geometry, colorAttribute] = React.useMemo(() => {
// // // // // //     console.log("Starting terrain geometry computation...");
// // // // // //     const size = 50;
// // // // // //     const divisions = 50;
// // // // // //     const geometry = new THREE.PlaneGeometry(size, size, divisions, divisions);
// // // // // //     geometry.rotateX(-Math.PI / 2);

// // // // // //     const positionAttribute = geometry.attributes.position;
// // // // // //     const colorAttribute = new THREE.BufferAttribute(
// // // // // //       new Float32Array(positionAttribute.count * 3),
// // // // // //       3
// // // // // //     );

// // // // // //     const color = new THREE.Color();
// // // // // //     console.log("Terrain vertices:", positionAttribute.count);
// // // // // //     console.log("Item positions (re-check):", itemPositions);
// // // // // //     console.log("Item metrics (re-check):", itemMetrics);

// // // // // //     for (let i = 0; i < positionAttribute.count; i++) {
// // // // // //       const vertex = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);

// // // // // //       const contributions = [];
// // // // // //       // const influenceRadius = 12;
// // // // // //       const influenceRadius = 20;

// // // // // //       itemPositions.forEach(({ item, position }) => {
// // // // // //         const dx = vertex.x - position[0];
// // // // // //         const dz = vertex.z - position[2];
// // // // // //         const dist = Math.sqrt(dx * dx + dz * dz);
// // // // // //         if (dist < influenceRadius) {
// // // // // //         // if (dist < 12) {
// // // // // //           // if (dist < 5) {
// // // // // //             contributions.push({ item, dist });
// // // // // //         }
// // // // // //       });

// // // // // //       if (i % 500 === 0) {
// // // // // //         console.log(`Vertex ${i}: position=(${vertex.x.toFixed(2)}, ${vertex.z.toFixed(2)}) found ${contributions.length} contributions.`);
// // // // // //       }

// // // // // //       contributions.sort((a, b) => a.dist - b.dist);

// // // // // //       const N = 3;
// // // // // //       let sumHeight = 0;
// // // // // //       let sumZhang = 0;
// // // // // //       let totalWeight = 0;

// // // // // //       const nearestItems = contributions.slice(0, N);
// // // // // //     nearestItems.forEach(({ item, dist }) => {
// // // // // //       const m = itemMetrics[item];
// // // // // //       if (!m) {
// // // // // //         console.log(`No metrics for item: ${item}`);
// // // // // //         return;
// // // // // //       }

// // // // // //       // Log each item's metrics if needed
// // // // // //       if (i < 20) {
// // // // // //         console.log(`Vertex ${i}, item ${item}: dist=${dist.toFixed(2)}, metrics=`, m);
// // // // // //       }

// // // // // //       contributions.slice(0, N).forEach(({ item, dist }) => {
// // // // // //         const m = itemMetrics[item];
// // // // // //         const weight = 1 / (dist + 0.1);
// // // // // //         const composite = ((m.lift - 1) * 50) + (m.support * 50);
// // // // // //         // const composite = ((m.lift - 1) * 1000) + (m.support * 1000);
// // // // // //         // const composite = (m.lift - 1) * 300 + (m.support * 500) - 200; 
// // // // // //         // const composite = (m.lift - 1) * 300 + (m.support * 500);
// // // // // //         // const composite = (m.lift - 1) * 30 + (m.support * 50);
// // // // // //         sumHeight += composite * weight;
// // // // // //         // sumHeight += (m.lift - 1) * 50 * weight;
// // // // // //         // sumHeight += (m.conviction - 1) * 10 * weight;
// // // // // //         sumZhang += m.zhangs_metric * weight;
// // // // // //         totalWeight += weight;
// // // // // //       })

// // // // // //       const height = totalWeight > 0 ? sumHeight / totalWeight : 0;
// // // // // //       const noise = 0;
// // // // // //       // const noise = (Math.random() - 0.5) * 0.8;
// // // // // // ///put back noise     
// // // // // //       vertex.y = height + noise;

// // // // // //       const avgZhang = totalWeight > 0 ? sumZhang / totalWeight : 0;
// // // // // //       color.set(getColorFromZhangsMetric(avgZhang));
// // // // // //       positionAttribute.setXYZ(i, vertex.x, vertex.y, vertex.z);
// // // // // //       colorAttribute.setXYZ(i, color.r, color.g, color.b);
// // // // // //     }

// // // // // //     geometry.setAttribute("color", colorAttribute);
// // // // // //     geometry.computeVertexNormals();
// // // // // //     console.log("Terrain colorAttribute:", colorAttribute);
// // // // // //     console.log("Completed terrain geometry computation.");
// // // // // //     return [geometry, colorAttribute];
// // // // // //   }, [itemPositions, itemMetrics]);

// // // // // function Terrain({ itemPositions, itemMetrics, uniqueItems }) {
// // // // //   const meshRef = React.useRef();

// // // // //   console.log("Terrain Unique items:", uniqueItems);
// // // // //   console.log("Terrain Item positions:", itemPositions);
// // // // //   console.log("Item Position Spread:", itemPositions.map(ip => ({ item: ip.item, pos: ip.position })));
// // // // //   console.log("Terrain Item metrics:", itemMetrics);

// // // // //   const [geometry, colorAttribute] = React.useMemo(() => {
// // // // //     console.log("Starting terrain geometry computation...");
// // // // //     const size = 50;
// // // // //     const divisions = 50;
// // // // //     const geometry = new THREE.PlaneGeometry(size, size, divisions, divisions);
// // // // //     geometry.rotateX(-Math.PI / 2);

// // // // //     const positionAttribute = geometry.attributes.position;
// // // // //     const colorAttribute = new THREE.BufferAttribute(
// // // // //       new Float32Array(positionAttribute.count * 3),
// // // // //       3
// // // // //     );
// // // // //     const color = new THREE.Color();

// // // // //     console.log("Terrain vertices:", positionAttribute.count);
// // // // //     console.log("Re-check Item positions:", itemPositions);
// // // // //     console.log("Re-check Item metrics:", itemMetrics);

// // // // //     const influenceRadius = 20;
// // // // //     const N = 3; // Number of nearest items to consider

// // // // //     for (let i = 0; i < positionAttribute.count; i++) {
// // // // //       const vertex = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);

// // // // //       // Find items within the influence radius
// // // // //       const contributions = [];
// // // // //       itemPositions.forEach(({ item, position }) => {
// // // // //         const dx = vertex.x - position[0];
// // // // //         const dz = vertex.z - position[2];
// // // // //         const dist = Math.sqrt(dx * dx + dz * dz);
// // // // //         if (dist < influenceRadius) {
// // // // //           contributions.push({ item, dist });
// // // // //         }
// // // // //       });

// // // // //       if (i % 500 === 0) {
// // // // //         console.log(
// // // // //           `Vertex ${i}: pos=(${vertex.x.toFixed(2)}, ${vertex.z.toFixed(2)}) ` +
// // // // //           `found ${contributions.length} contributions.`
// // // // //         );
// // // // //       }

// // // // //       contributions.sort((a, b) => a.dist - b.dist);
// // // // //       const nearestItems = contributions.slice(0, N);

// // // // //       let sumHeight = 0;
// // // // //       let sumZhang = 0;
// // // // //       let totalWeight = 0;

// // // // //       nearestItems.forEach(({ item, dist }) => {
// // // // //         const m = itemMetrics[item];
// // // // //         if (!m) {
// // // // //           console.warn(`No metrics for item: ${item}`);
// // // // //           return;
// // // // //         }

// // // // //         if (i < 20) {
// // // // //           console.log(`Vertex ${i}, item ${item}: dist=${dist.toFixed(2)}, metrics=`, m);
// // // // //         }

// // // // //         const weight = 1 / (dist + 0.1);
// // // // //         const composite = ((m.lift - 1) * 50) + (m.support * 50);
// // // // //         sumHeight += composite * weight;
// // // // //         sumZhang += m.zhangs_metric * weight;
// // // // //         totalWeight += weight;
// // // // //       });

// // // // //       const height = totalWeight > 0 ? sumHeight / totalWeight : 0;
// // // // //       const avgZhang = totalWeight > 0 ? sumZhang / totalWeight : 0;

// // // // //       vertex.y = height; // If desired, add noise here: + (Math.random()-0.5)*0.8
// // // // //       color.set(getColorFromZhangsMetric(avgZhang));

// // // // //       positionAttribute.setXYZ(i, vertex.x, vertex.y, vertex.z);
// // // // //       colorAttribute.setXYZ(i, color.r, color.g, color.b);
// // // // //     }

// // // // //     geometry.setAttribute("color", colorAttribute);
// // // // //     geometry.computeVertexNormals();
// // // // //     console.log("Completed terrain geometry computation.");

// // // // //     return [geometry, colorAttribute];
// // // // //   }, [itemPositions, itemMetrics]);
  
// // // // //   return (
// // // // //     <>
// // // // //       <mesh ref={meshRef} geometry={geometry}>
// // // // //         <meshStandardMaterial vertexColors={true} side={THREE.DoubleSide} flatShading={false} />
// // // // //       </mesh>
// // // // //       {itemPositions.map(({ item, position }) => {
// // // // //         const metrics = itemMetrics[item];
// // // // //         // const height = (metrics.conviction - 1) * 10;
// // // // //         const height = ((metrics.lift - 1) * 1000 + (metrics.support * 1000)) / 10; 
// // // // //         return (
// // // // //           <Html key={item} position={[position[0], height + 2, position[2]]}>
// // // // //             <div
// // // // //               style={{
// // // // //                 color: "black",
// // // // //                 fontSize: "8px",
// // // // //                 textAlign: "center",
// // // // //                 background: "rgba(255,255,255,0.7)",
// // // // //                 padding: "2px",
// // // // //                 borderRadius: "2px",
// // // // //               }}
// // // // //             >
// // // // //               <strong>{item}</strong> <br />
// // // // //               <strong>Support:</strong> {metrics.support.toFixed(2)} <br />
// // // // //               <strong>Conviction:</strong> {metrics.conviction.toFixed(2)} <br />
// // // // //               <strong>Lift:</strong> {((metrics.lift / metrics.count) || 0).toFixed(2)} <br />
// // // // //               <strong>Zhang's Metric:</strong> {metrics.zhangs_metric.toFixed(2)}
// // // // //             </div>
// // // // //           </Html>
// // // // //         );
// // // // //       })}
// // // // //     </>
// // // // //   );
// // // // // }

// // // // // export default FPGraphPage;