// JourneyFlow.jsx
import React, { useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MultiSelect } from '@mantine/core';
import { useDataset } from '../components/DataFetcher';
import DraggableItemList from '../components/DraggableItemList';
import FlowVisualization from '../components/FlowVisualization';
// import UndoRedoControls from '../components/UndoRedoControls';
// import FlowDataDisplay from '../components/FlowDataDisplay';
import LoadingSpinner from '../components/LoadingSpinner';
import { DimensionContext } from '../components/ResponsiveWrapper';
import { addStep, removeStep, updateStep, selectSteps, selectCurrentSteps } from '../redux/slices/userJourneySlice';
// import userJourneyReducer from '../redux/reducers/userJourneyReducer';
// import InfoBox from '../components/InfoBox';
// import SEO from '../components/SEO';




    const processData = (data) => {
        if (!data) {
            return { urlList: [], eventDescList: [] };
          }
        const urlList = data?.UniqueURL ? Object.keys(data.UniqueURL).map(key => ({ value: key, label: key })) : [];

        const eventDescList = data?.UniqueEventDescList ? Object.keys(data.UniqueEventDescList).map(key => ({ value: key, label: key })) : [];

        // Concatenate urlList and eventDescList
        const combinedList = [...urlList, ...eventDescList];

        // Other processing logic...
        return combinedList;
      };

      console.log("FlowVisualization:", FlowVisualization);
        // console.log("UndoRedoControls:", UndoRedoControls);
        // console.log("FlowDataDisplay:", FlowDataDisplay);
        console.log("DraggableItemList:", DraggableItemList);

    const UserJourneyBuilder = () => {
        // Redux dispatch function

        // State for stepsData and flowElements
        // const [stepsData, setStepsData] = useState(() => processData(data).urlList);
        const [stepsData, setStepsData] = useState([]);
        console.log("UserJourneyBuilder: stepsData", stepsData);

        // the steps should be urlList + eventDescList
        const [flowElements, setFlowElements] = useState([])

        const dispatch = useDispatch(); // this is 'dispatch' is declared but its value is never read.ts(6133)
        const dimensions = useContext(DimensionContext);
        const { width, height } = useContext(DimensionContext);

        const { data, status, error } = useDataset();
    
        console.log("Loading UserJourneyBuilder:", dimensions);

       
        // Effect to update stepsData and flowElements based on fetched data
        useEffect(() => {
            if (data && status === 'success') {
                // Transform or process your data here
                const combinedList = processData(data);
                setStepsData(combinedList);
                console.log("What happens here?", combinedList);
            }
        }, [data, status]);
    
        const [visKey, setVisKey] = useState(0);

        useEffect(() => {
            console.log("elements updated", flowElements);
            setVisKey(prevKey => prevKey + 1); // Increment key to force re-render
        }, [flowElements]);
        


        useEffect(() => {
            console.log("flowElements updated", flowElements);
        }, [flowElements]);

        // Define actions like UNDO_ACTION and REDO_ACTION in your Redux setup.
        // Implement logic in your reducers to handle these actions. This might involve moving back and forth in a stored history of states.

        // const steps = useSelector(selectSteps || []);
        // const steps = useSelector(state => state.userJourney?.steps || []);
        const currentSteps = useSelector(selectCurrentSteps || []);
        
        const [renderKey, setRenderKey] = useState(0);

        const handleDragEnd = (newOrder) => {
            setFlowElements(newOrder);
            setRenderKey(prevKey => prevKey + 1); // Increment to force re-render
        };
        
        // Handle loading and error states
        if (status === 'loading') {
            return <LoadingSpinner />;
        }
        if (status === 'error') {
            console.error('Error fetching data:', error);
            return <div>Error: {error.message}</div>;
        }
    
        
        // Handle add, remove, and update logic
        const handleAddStep = (newStep) => {
            dispatch(addStep(newStep));
        };

        const handleRemoveStep = (stepId) => {
            dispatch(removeStep(stepId));
        };

        const handleUpdateStep = (stepId, newStep) => {
            dispatch(updateStep(stepId, newStep));
        };

        const handleSelectChange = (selectedValues) => {
            let currentSteps = selectedValues
            if (currentSteps) {
                const newSteps = selectedValues.filter(value => !currentSteps.includes(value));
                const removedSteps = currentSteps.filter(step => !selectedValues.includes(step));
            
                setFlowElements(selectedValues);
                newSteps.forEach(step => dispatch(addStep(step)));
                removedSteps.forEach(stepId => dispatch(removeStep(stepId)));
                console.log("handleSelectChange:", selectedValues);
                console.log("handleSelectChange flowElements:", flowElements);
              } else {
                console.error("currentSteps is undefined.");
              }
        };

        console.log("handleSelectChange flowElements", flowElements);

        
    return (
        <div className='UserJourneyBuilder'>
            <h1 className="h1 banner">Journey Flow</h1>
            {/* Uncomment components one by one to identify the issue */}
            <div className='UserJourney-Container'>

            {flowElements ? (<></>) : (<><p>Select your pages and events to analyze</p></>)}
           
            {stepsData.length > 0 && 
            <MultiSelect
                data={stepsData} 
                onChange={handleSelectChange}
                placeholder="Select steps"
                searchable
                nothingFound="No options"
                className='ml-6'
            />}  
            {/* DraggableItemList Component */}

            {flowElements.length > 0 && 
            <DraggableItemList
                 key={renderKey} // Key to force re-render
                 items={flowElements}
                 onDragEnd={handleDragEnd} // Passing the handler to DraggableItemList
             />}

            {/* FlowVisualization Component */}
            {flowElements.length > 0 && 
            // <FlowVisualization elements={flowElements} />}
            <FlowVisualization key={visKey} nodeNames={flowElements} />}

              {/* FlowDataDisplay Component */}
             {/* <FlowDataDisplay /> */}

{/* <UndoRedoControls /> */}
            </div>
            
        </div>
    );
};

export default UserJourneyBuilder;