//ScreenResized.jsx
import { useState, useEffect } from 'react';

function useDebouncedResize(delay) {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    let timeoutId = null;

    const updateWindowSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        updateWindowSize();
      }, delay);
    };

    window.addEventListener('resize', handleResize);

    updateWindowSize();
    console.log("ScreenResized.jsx windowSize:", windowSize);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', handleResize);
    };
  }, [delay]);

  return windowSize;
}

export default useDebouncedResize;
