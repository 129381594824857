import React, { useState, useRef, useEffect } from 'react';
import { useChat } from './ChatContext';
import SanitizedInput from './SanitizedInput';
import PropTypes from 'prop-types';
import parse from 'html-react-parser'; 
import ChatStart from './ChatStart';
import ChatPrompts from './ChatPrompts'; // Adjust path as necessary

const Chatbot = ({ dimensions }) => {
    const { messages, sendMessage } = useChat();
    const [tempInput, setTempInput] = useState('');
    const [expandedMessages, setExpandedMessages] = useState({}); // Track expanded messages
    const [initialMessagesSent, setInitialMessagesSent] = useState(false);

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    const parseInput = (input) => {
        const regex = /(?:Page (.*?))?(?:List (.*?))?(?:Event (.*?))?(?:Outcome (.*?))?(?:PageFrom (.*?))?(?:PageTo (.*?))?$/;
        const match = input.match(regex);
        return match ? { page: match[1], list: match[2], event: match[3], outcome: match[4], PageFrom: match[5], outPageTocome: match[6] } : null;
    };
    
    const handleInputChange = (event) => {
        console.log('Input Event:', event); // For debugging
        setTempInput(event.target.value);
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Message submitted:", tempInput);
    
        const parsedInput = parseInput(tempInput);
        if (parsedInput) {
            // Valid input: Add 'sender' property to the message object and send it
            const messageToSend = {
                ...parsedInput,
                sender: 'user'
            };
            sendMessage(messageToSend);
        } else {
            // Invalid or empty input: Send a specific error message
            sendMessage({ 
                text: "Please enter a valid query.", 
                sender: 'bot', 
                type: 'error' 
            });
        }
    
        setTempInput(''); // Clear the input field
    };
    
    
    const handleShowMore = (index) => {
        setExpandedMessages(prev => ({ ...prev, [index]: !prev[index] }));
    };

    const chatWindowStyle = {
        width: '100%',
        height: '100%',
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]); // 'messages' should be the state or prop that holds your chat messages
    
    console.log('Chatbot floating messages:', messages); // For debugging

const renderMessageText = (content) => {
    if (typeof content === 'string') {
        return parse(content); // Parse HTML string
    } else if (Array.isArray(content)) {
        return content.map((item, index) => {
            if (typeof item === 'object' && item !== null) {
                return <div key={index}>{parse(Object.entries(item).map(([key, value]) => `${key} ${value}`).join('<br/>'))}</div>;
            } else if (typeof item === 'string') {
                return <div key={index}>{parse(item)}</div>;
            }
            return null;
        });
    } else {
        // Return content as is if it's not an array
        return parse(content);
    }
};

const renderContent = (message) => {
    // Handling start messages and simple string messages
    if (message.start === 'true' || typeof message.text === 'string') {
        return <div>{parse(message.text)}</div>;
    }

    if (message.type === 'error') {
        // Ensure message.text is a string before parsing
        if (typeof message.text === 'string') {
            return <div>{parse(message.text)}</div>;
        } else {
            console.error('Error message text is not a string:', message);
            return <div>Please enter a valid query.</div>;
        }
    }

    // Handling messages with data object
    if (message.text && message.text.data) {
        return <div>{parse(message.text.data)}</div>;
    }

    // Handling regular bot messages with content and tooltip
    if (!message.text || !message.text.content || !message.text.tooltip) {
        console.error('Message content or tooltip is undefined:', message);
        return <div>Unsupported message format</div>;
    }

    const { initialContent, remainingContent, hasMore } = message.text.content;
    const { headline, explanation, modelTooltip } = message.text.tooltip;

    return (
        <div>
            <div className="conversation-tooltip">
                <h3 className="ChatWithModel">{headline}</h3>
                <p className="model-explanation">{explanation}</p>
                <div className="conversation-tooltip-container">
                    <img src="/assets/icons/icon-info.svg" className='iconInfo-sm' alt='More Information' />
                    <span className="conversation-tooltip-text">{modelTooltip}</span>
                </div>
            </div>
            <div>
            {initialContent && renderMessageText(initialContent)}
                {/* {initialContent && initialContent.map(item => renderMessageText(item))} */}
                {/* {initialContent && parse(initialContent.join(''))} */}
                {/* {expandedMessages[message.type] && remainingContent && parse(remainingContent.join(''))} */}
            {expandedMessages[message.type] && remainingContent && renderMessageText(remainingContent)}
                {/* {expandedMessages[message.type] && remainingContent && remainingContent.map(item => renderMessageText(item))} */}
                {hasMore && (
                    <button className="load-more" onClick={() => handleShowMore(message.type)}>
                        {expandedMessages[message.type] ? '- Less' : '+ More'}
                    </button>
                )}
            </div>
        </div>
    );
};

        return (
        <>
            <ChatStart initialMessagesSent={initialMessagesSent} setInitialMessagesSent={setInitialMessagesSent} />
        <div id="chat-window" className="chat-window" style={chatWindowStyle}>
                {messages.map((message, index) => (
                    <div key={index} className={`message ${message.sender}`}>
                        {renderContent(message)}
                        {message.sender === 'bot' && message.start !== 'true' && message.remainingData && (
                            <button className="load-more" onClick={() => handleShowMore(message.type)}>
                                {expandedMessages[message.type] ? '- Less' : '+ More'}
                            </button>
                        )}
                    </div>
                ))}
            <ChatPrompts />
                <div ref={messagesEndRef} />
            </div>
          <div className="input-wrapper">
          <form onSubmit={handleSubmit} className="chat-form">
            <SanitizedInput
                type="text"
                className="chat-input"
                value={tempInput}
                onChange={handleInputChange}
                placeholder="Page X, Event Y, Outcome Z, PageTo A, PageFrom B, List Events, Add Spark"
            />
            <button type="submit" className="send-button">
                Send
            </button>
            </form>
        </div>  
        </>
      );
};

Chatbot.propTypes = {
    dimensions: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }).isRequired,
};

export default Chatbot;


// import React, { useState, useRef, useEffect } from 'react';
// import { useChat } from './ChatContext';
// import SanitizedInput from './SanitizedInput';
// import PropTypes from 'prop-types';
// import parse from 'html-react-parser'; 
// import ChatStart from './ChatStart';
// import ChatPrompts from './ChatPrompts'; // Adjust path as necessary
// import { useDataset } from './DataFetcher';

// const Chatbot = ({ dimensions }) => {
//     const { messages, sendMessage } = useChat();
//     const [tempInput, setTempInput] = useState('');
//     const [expandedMessages, setExpandedMessages] = useState({}); // Track expanded messages
//     const [initialMessagesSent, setInitialMessagesSent] = useState(false);

//     const messagesEndRef = useRef(null);

//     const scrollToBottom = () => {
//         messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
//     };
//     const parseInput = (input) => {
//         const regex = /(?:Page (.*?))?(?:List (.*?))?(?:Event (.*?))?(?:Outcome (.*?))?(?:PageFrom (.*?))?(?:PageTo (.*?))?$/;
//         const match = input.match(regex);
//         return match ? { page: match[1], list: match[2], event: match[3], outcome: match[4], PageFrom: match[5], outPageTocome: match[6] } : null;
//     };
    
//     const handleInputChange = (event) => {
//         setTempInput(event.target.value);
//         console.log('Chatbot: Input Change', event.target.value);
//     };
    
//     const handleSubmit = (e) => {
//         e.preventDefault();
//         console.log("Message submitted:", tempInput);
    
//         const parsedInput = parseInput(tempInput);
//         console.log("Chatbot: Submitting message", parsedInput);
//         if (parsedInput) {
//             // Valid input: Add 'sender' property to the message object and send it
//             const messageToSend = {
//                 ...parsedInput,
//                 sender: 'user'
//             };
//             sendMessage(messageToSend);
//         } else {
//             // Invalid or empty input: Send a specific error message
//             sendMessage({ 
//                 text: "Please enter a valid query.", 
//                 sender: 'bot', 
//                 type: 'error' 
//             });
//         }
    
//         setTempInput(''); // Clear the input field
//     };
    
    
//     const handleShowMore = (messageId) => {
//         setExpandedMessages(prev => {
//             const newExpandedState = { ...prev, [messageId]: !prev[messageId] };
//             return newExpandedState;
//         });
//     };

//     const chatWindowStyle = {
//         width: dimensions.width,
//         height: dimensions.height,
//     };

//     useEffect(() => {
//         scrollToBottom();
//         console.log('Chatbot scrolltoBottom: useEffect - Messages updated', messages);
//     }, [messages]); // 'messages' should be the state or prop that holds your chat messages
    
//     console.log('Chatbot floating messages:', messages); // For debugging

// const renderMessageText = (content) => {
//     if (typeof content === 'string') {
//         return parse(content); // Parse HTML string
//     } else if (Array.isArray(content)) {
//         return content.map((item, index) => {
//             if (typeof item === 'object' && item !== null) {
//                 return <div key={index}>{parse(Object.entries(item).map(([key, value]) => `${key} ${value}`).join('<br/>'))}</div>;
//             } else if (typeof item === 'string') {
//                 return <div key={index}>{parse(item)}</div>;
//             }
//             return null;
//         });
//     } else {
//         // Return content as is if it's not an array
//         return parse(content);
//     }
// };

// const renderContent = (message) => {
//     // console.log("Chatbot: Rendering content for message", message);

//     // Handling start messages and simple string messages
//     if (message.start === 'true' || typeof message.text === 'string') {
//         return <div>{parse(message.text)}</div>;
//     }

//     if (message.type === 'error') {
//         // Ensure message.text is a string before parsing
//         if (typeof message.text === 'string') {
//             return <div>{parse(message.text)}</div>;
//         } else {
//             console.error('Error message text is not a string:', message);
//             return <div>Please enter a valid query.</div>;
//         }
//     }

//     // Handling messages with data object
//     if (message.text && message.text.data) {
//         return <div>{parse(message.text.data)}</div>;
//     }

//     // Handling regular bot messages with content and tooltip
//     if (!message.text || !message.text.content || !message.text.tooltip) {
//         console.error('Message content or tooltip is undefined:', message);
//         return <div>Unsupported message format</div>;
//     }

//     const { initialContent, remainingContent, hasMore } = message.text.content;
//     const { headline, explanation, modelTooltip } = message.text.tooltip;

//     return (
//         <div>
//             {/* ... */}
//             {initialContent && renderMessageText(initialContent)}
//             {expandedMessages[message.id] && remainingContent && renderMessageText(remainingContent)}
//             {hasMore && (
//                 <button className="load-more" onClick={() => handleShowMore(message.id)}>
//                     {expandedMessages[message.id] ? '- Less' : '+ More'}
//                 </button>
//             )}
//         </div>
//     );
// };

// //             <div>
// //             {initialContent && renderMessageText(initialContent)}
// //                 {/* {initialContent && initialContent.map(item => renderMessageText(item))} */}
// //                 {/* {initialContent && parse(initialContent.join(''))} */}
// //                 {/* {expandedMessages[message.type] && remainingContent && parse(remainingContent.join(''))} */}
// //             {expandedMessages[message.type] && remainingContent && renderMessageText(remainingContent)}
// //                 {/* {expandedMessages[message.type] && remainingContent && remainingContent.map(item => renderMessageText(item))} */}
// //                 {hasMore && (
// //                     <button className="load-more" onClick={() => handleShowMore(message.type)}>
// //                         {expandedMessages[message.type] ? '- Less' : '+ More'}
// //                     </button>
// //                 )}
// //             </div>
// //         </div>
// //     );
// // };

//         return (
//         <>
//             <ChatStart initialMessagesSent={initialMessagesSent} setInitialMessagesSent={setInitialMessagesSent} />
//         <div id="chat-window" className="chat-window" style={chatWindowStyle}>
//                 {messages.map((message) => (
//                     <div key={message.id} className={`message ${message.sender}`}>
//                     {renderContent(message)}
//                         {message.sender === 'bot' && message.start !== 'true' && message.remainingData && (
//                             <button className="load-more" onClick={() => handleShowMore(message.type)}>
//                                 {expandedMessages[message.type] ? '- Less' : '+ More'}
//                             </button>
//                         )}
//                     </div>
//                 ))}
//             <ChatPrompts />
//                 <div ref={messagesEndRef} />
//             </div>
//           <div className="input-wrapper">
//           <form onSubmit={handleSubmit} className="chat-form">
//             <SanitizedInput
//                 type="text"
//                 className="chat-input"
//                 value={tempInput}
//                 onChange={handleInputChange}
//                 placeholder="Page X, Event Y, Outcome Z, PageTo A, PageFrom B, List Events, Add Spark"
//             />
//             <button type="submit" className="send-button">
//                 Send
//             </button>
//             </form>
//         </div>  
//         </>
//       );
// };

// Chatbot.propTypes = {
//     dimensions: PropTypes.shape({
//       width: PropTypes.number.isRequired,
//       height: PropTypes.number.isRequired,
//     }).isRequired,
// };

// export default Chatbot;