// /redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import undoable from 'redux-undo';
import userJourneyReducer from './slices/userJourneySlice';
import treeExplorerReducer from './slices/treeExplorerSlice';


// Wrap your reducer with undoable
const undoableJourneyReducer = undoable(userJourneyReducer);
const undoableTreeReducer = undoable(treeExplorerReducer);

const store = configureStore({
  reducer: {
    userJourney: undoableJourneyReducer,
    treeExplorer: undoableTreeReducer,
    // ... other reducers as needed
  },
  // middleware and devTools are automatically set up
});

export default store;
