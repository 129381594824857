class PriorityQueue {
  constructor() {
      this.values = [];
  }

  enqueue(val, priority) {
      this.values.push({ val, priority });
      this.sort();
  }

  dequeue() {
      return this.values.shift();
  }

  sort() {
      this.values.sort((a, b) => a.priority - b.priority);
  }

  isEmpty() {
      return this.values.length === 0;
  }
}

function createAdjacencyList(links) {
  const adjacencyList = {};
  links.forEach(link => {
      let sourceId = link.source.id;
      let targetId = link.target.id;

      if (!adjacencyList[sourceId]) {
          adjacencyList[sourceId] = [];
      }
      if (!adjacencyList[targetId]) {
          adjacencyList[targetId] = [];
      }

      // Calculate the Euclidean distance between source and target as the weight
      let weight = Math.hypot(
          link.target.x - link.source.x,
          link.target.y - link.source.y
      );

      adjacencyList[sourceId].push({ node: targetId, weight: weight, position: { x: link.target.x, y: link.target.y } });
      adjacencyList[targetId].push({ node: sourceId, weight: weight, position: { x: link.source.x, y: link.source.y } }); // Assuming undirected graph
  });

  return adjacencyList;
}

function heuristic(a, b) {
  return Math.hypot(b.x - a.x, b.y - a.y);
}

function AStarPath(links, startNode, endNode) {
  const adjacencyList = createAdjacencyList(links.current);
  const openSet = new PriorityQueue();
  const closedSet = new Set();
  const gScores = {};
  const fScores = {};
  const cameFrom = {};

  // Initialize scores
  for (let node in adjacencyList) {
      gScores[node] = Infinity;
      fScores[node] = Infinity;
  }

  // Assuming you have access to node positions
  const nodePositions = {};
  links.current.forEach(link => {
      nodePositions[link.source.id] = { x: link.source.x, y: link.source.y };
      nodePositions[link.target.id] = { x: link.target.x, y: link.target.y };
  });

  gScores[startNode] = 0;
  fScores[startNode] = heuristic(nodePositions[startNode], nodePositions[endNode]);

  openSet.enqueue(startNode, fScores[startNode]);

  while (!openSet.isEmpty()) {
      let current = openSet.dequeue().val;

      if (current === endNode) {
          // Reconstruct path
          let path = [];
          while (current) {
              path.unshift(current);
              current = cameFrom[current];
          }
          return path;
      }

      closedSet.add(current);

      for (let neighbor of adjacencyList[current]) {
          if (closedSet.has(neighbor.node)) {
              continue;
          }

          let tentative_gScore = gScores[current] + neighbor.weight;

          if (tentative_gScore < gScores[neighbor.node]) {
              cameFrom[neighbor.node] = current;
              gScores[neighbor.node] = tentative_gScore;
              fScores[neighbor.node] = tentative_gScore + heuristic(neighbor.position, nodePositions[endNode]);

              if (!openSet.values.find(element => element.val === neighbor.node)) {
                  openSet.enqueue(neighbor.node, fScores[neighbor.node]);
              }
          }
      }
  }

  // If no path is found
  return [];
}

export default AStarPath;



// import React, { useState } from 'react';

// console.log("DijkstraPath load");

// class PriorityQueue {
//     constructor() {
//         this.values = [];
//     }

//     enqueue(val, priority) {
//         this.values.push({ val, priority });
//         this.bubbleUp();
//     }

//     bubbleUp() {
//         let idx = this.values.length - 1;
//         const element = this.values[idx];

//         while (idx > 0) {
//             let parentIdx = Math.floor((idx - 1) / 2);
//             let parent = this.values[parentIdx];

//             if (element.priority >= parent.priority) break;
//             this.values[parentIdx] = element;
//             this.values[idx] = parent;
//             idx = parentIdx;
//         }
//     }

//     dequeue() {
//         const min = this.values[0];
//         const endNode = this.values.pop();
//         if (this.values.length > 0) {
//             this.values[0] = endNode;
//             this.sinkDown();
//         }
//         return min;
//     }

//     sinkDown() {
//         let idx = 0;
//         const length = this.values.length;
//         const element = this.values[0];

//         while (true) {
//             let leftChildIdx = 2 * idx + 1;
//             let rightChildIdx = 2 * idx + 2;
//             let leftChild, rightChild;
//             let swap = null;

//             if (leftChildIdx < length) {
//                 leftChild = this.values[leftChildIdx];
//                 if (leftChild.priority < element.priority) {
//                     swap = leftChildIdx;
//                 }
//             }

//             if (rightChildIdx < length) {
//                 rightChild = this.values[rightChildIdx];
//                 if (
//                     (swap === null && rightChild.priority < element.priority) ||
//                     (swap !== null && rightChild.priority < leftChild.priority)
//                 ) {
//                     swap = rightChildIdx;
//                 }
//             }

//             if (swap === null) break;
//             this.values[idx] = this.values[swap];
//             this.values[swap] = element;
//             idx = swap;
//         }
//     }

//     isEmpty() {
//         return this.values.length === 0;
//     }
// }

// function createAdjacencyList(links) {
//     console.log('Received links:', links);
//     console.log('Type of links:', typeof links);
//     console.log('Type of links:', Array.isArray(links) ? 'array' : 'not an array'); // This should log 'array'

//     if (!Array.isArray(links)) {
//         console.error('Expected an array but received:', links);
//       }

//     const adjacencyList = {};
//     links.forEach(link => {
//       let sourceId = link.source.id;
//       let targetId = link.target.id;
  
//       if (!adjacencyList[sourceId]) {
//         adjacencyList[sourceId] = [];
//       }
//       if (!adjacencyList[targetId]) {
//         adjacencyList[targetId] = [];
//       }
  
//       // Calculate the Euclidean distance between source and target as the weight
//       let weight = Math.sqrt(
//         Math.pow(link.target.x - link.source.x, 2) +
//         Math.pow(link.target.y - link.source.y, 2)
//       );
  
//       adjacencyList[sourceId].push({ node: targetId, weight: weight });
//       // If it's an undirected graph, you might also want to add the reverse link
//       // adjacencyList[targetId].push({ node: sourceId, weight: weight });
//     });
  
//     return adjacencyList;
//   }
  

// function DijkstraPath(links, startNode, endNode) {
//   const queue = new PriorityQueue();
//   queue.enqueue(startNode, 0);
//   const distances = {};
//   const previous = {};
//   const visited = new Set(); // Track visited nodes
//   let path = []; // To store the final path

//   console.log('Dijkstra -- startNode:', startNode);
//   console.log('Dijkstra -- endNode:', endNode);

//   const adjacencyList = createAdjacencyList(links.current);
//   console.log('Dijkstra -- adjacencyList:', adjacencyList);

//   // Initialize distances and previous
//   for (let vertex in adjacencyList) {
//       distances[vertex] = Infinity;
//       previous[vertex] = null;
//   }
//   distances[startNode] = 0;

//   while (!queue.isEmpty()) {
//       let smallest = queue.dequeue().val;

//       if (visited.has(smallest)) {
//           continue; // Skip if already processed
//       }

//       visited.add(smallest); // Mark node as visited
//       console.log(`Dequeued '${smallest}' with distance ${distances[smallest]}`);

//       if (smallest === endNode) {
//           console.log(`Reached endNode: '${endNode}'.`);
//           // Build the path by walking backward from endNode
//           let currentNode = endNode;
//           while (currentNode) {
//               path.unshift(currentNode);
//               currentNode = previous[currentNode];
//           }
//           console.log(`Final path: ${path}`);
//           return path; // Return the full path including start and end nodes
//       }

//       for (let neighbor of adjacencyList[smallest]) {
//           let alt = distances[smallest] + neighbor.weight;
//           if (alt < distances[neighbor.node]) {
//               distances[neighbor.node] = alt;
//               previous[neighbor.node] = smallest;
//               queue.enqueue(neighbor.node, alt);
//               console.log(`Updated distance for '${neighbor.node}' to ${alt} and enqueued`);
//           }
//       }
//   }

//   // If no path was found, return an empty array or handle as desired
//   console.log(`No path found from '${startNode}' to '${endNode}', searching alternative routes.`);

//   // Attempt to return an alternative path
//   let closestPath = [];
//   let currentNode = null;
//   let closestDistance = Infinity;

//   // Find the node with the smallest non-infinity distance
//   for (let node in distances) {
//       if (distances[node] < closestDistance && node !== startNode) {
//           closestDistance = distances[node];
//           currentNode = node;
//       }
//   }

//   if (currentNode) {
//       // Rebuild the path to the closest node
//       while (currentNode) {
//           closestPath.unshift(currentNode);
//           currentNode = previous[currentNode];
//       }
//   }

//   console.log(`Closest path found: ${closestPath}`);
//   return closestPath.length > 1 ? closestPath : []; // Return closest valid path, or empty array if not found
// }

//   export default DijkstraPath;