import React, { useState, useEffect } from 'react';
import { Modal, Button } from '@mantine/core';
import Select from 'react-select';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDataset } from '../components/DataFetcher'; // Adjust the path as necessary
import { tellOracle } from './AskOracle';

const FunnelConfig = ({ isOpen, onClose, onSave, initialStages }) => {
  const [selectedStages, setSelectedStages] = useState(initialStages);
  const [options, setOptions] = useState([]);

  const { data: dataset, isLoading: isLoadingDataset } = useDataset();

  // Sync selectedStages with initialStages when initialStages changes
  useEffect(() => {
    if (initialStages) {
      console.log('Updating selectedStages with initialStages:', initialStages);
      setSelectedStages(initialStages);
    }
  }, [initialStages]);

  useEffect(() => {
    if (!isLoadingDataset && dataset && dataset.UniqueURL) {
      const urlOptions = Object.keys(dataset.UniqueURL).map((key) => ({
        value: key,
        label: key,
      }));
      setOptions(urlOptions);
    }
  }, [isLoadingDataset, dataset]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(selectedStages);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSelectedStages(items);
  };

  const handleStageSelectionChange = (selectedOption) => {
    console.log('Selected stages changed:', selectedOption);
    setSelectedStages(selectedOption);
  };

  const handleSaveConfiguration = () => {
    if (JSON.stringify(selectedStages) !== JSON.stringify(initialStages)) {
      const stagesToUpdate = selectedStages.map(stage => ({ key: stage.value, label: stage.label }));
      tellOracle('oracle/tellFunnelConfig', { funnelConfig: stagesToUpdate })
        .then(response => {
          console.log('Configuration updated successfully', response);
        })
        .catch(error => {
          console.error('Failed to update configuration, but applying changes locally', error);
        })
        .finally(() => {
          onSave(selectedStages); // Always update parent component with new stages
        });
    }
  };

  return (
    <Modal className="config-modal" opened={isOpen} onClose={onClose} title="Configure Funnel Stages">
      <Select
        isMulti
        onChange={handleStageSelectionChange}
        options={options}
        value={selectedStages}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
      />
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="stages">
          {(provided) => (
            <div className="draggable-container" {...provided.droppableProps} ref={provided.innerRef}>
              {selectedStages.map((stage, index) => (
                <Draggable key={stage.value} draggableId={stage.value} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {stage.label}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button className="config-button" onClick={handleSaveConfiguration}>Save Configuration</Button>
    </Modal>
  );
};

export default FunnelConfig;


// // FunnelConfig.jsx
// import React, { useState, useEffect } from 'react';
// import { Modal, Button } from '@mantine/core';
// import Select from 'react-select';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import { useDataset } from '../components/DataFetcher'; // Adjust the path as necessary
// import { tellOracle } from './AskOracle';

// const FunnelConfig = ({ isOpen, onClose, onSave, initialStages }) => {
//   const [selectedStages, setSelectedStages] = useState(initialStages);

//   const [options, setOptions] = useState([]);

// const { data: dataset, isLoading: isLoadingDataset } = useDataset();

// useEffect(() => {
//   if (!isLoadingDataset && dataset && dataset.UniqueURL) {
//     const urlOptions = Object.keys(dataset.UniqueURL).map((key) => ({
//       value: key,
//       label: key,
//     }));
//     setOptions(urlOptions);
//   }
// }, [isLoadingDataset, dataset]);

//   const handleDragEnd = (result) => {
//     if (!result.destination) return;
//     const items = Array.from(selectedStages);
//     const [reorderedItem] = items.splice(result.source.index, 1);
//     items.splice(result.destination.index, 0, reorderedItem);
//     setSelectedStages(items);
//   };

//   const handleStageSelectionChange = (selectedOption) => {
//     setSelectedStages(selectedOption);
//   };

//   const handleSaveConfiguration = () => {
//     if (JSON.stringify(selectedStages) !== JSON.stringify(initialStages)) {
//       const stagesToUpdate = selectedStages.map(stage => ({ key: stage.value, label: stage.label }));
//       tellOracle('oracle/tellFunnelConfig', { funnelConfig: stagesToUpdate })
//         .then(response => {
//           console.log('Configuration updated successfully', response);
//         })
//         .catch(error => {
//           console.error('Failed to update configuration, but applying changes locally', error);
//         })
//         .finally(() => {
//           onSave(selectedStages); // Always update parent component with new stages
//         });
//     }
//   };
  
  

//   return (
//     <Modal className="config-modal" opened={isOpen} onClose={onClose} title="Configure Funnel Stages">
//       <Select
//         isMulti
//         onChange={handleStageSelectionChange}
//         options={options}
//         value={selectedStages}
//         // Additional props for react-select
//       />
//       <DragDropContext onDragEnd={handleDragEnd}>
//         <Droppable droppableId="stages">
//           {(provided) => (
//             <div className="draggable-container" {...provided.droppableProps} ref={provided.innerRef}>
//               {selectedStages.map((stage, index) => (
//                 <Draggable key={stage.value} draggableId={stage.value} index={index}>
//                   {(provided) => (
//                     <div
//                       ref={provided.innerRef}
//                       {...provided.draggableProps}
//                       {...provided.dragHandleProps}
//                     >
//                       {stage.label}
//                     </div>
//                   )}
//                 </Draggable>
//               ))}
//               {provided.placeholder}
//             </div>
//           )}
//         </Droppable>
//       </DragDropContext>
//       <Button className="config-button" onClick={handleSaveConfiguration}>Save Configuration</Button>

//     </Modal>
//   );
// };

// export default FunnelConfig;
