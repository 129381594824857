import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
// import { CookieJar } from 'tough-cookie';
// import { useAuth } from './AuthContext'; 

import { motion } from 'framer-motion';
import LoadingSpinner from './LoadingSpinner';
import SanitizedInput from './SanitizedInput';
import { askOracle } from './AskOracle';
import { Navigate, useNavigate } from 'react-router-dom';
// import LocalData from './LocalData';

console.log('Initializing Auth.jsx');

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';

const GO_BASE_URL = process.env.REACT_APP_GO_BASE;
console.log(process.env.REACT_APP_GO_BASE);

const Auth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
// const navigation = navigation();
  console.log('Initializing Auth function in jsx');

  const successLoginFlow = async (result = null) => {
    // If result is provided, use it. Else, get token from localStorage
    const token = result ? result.data.token : localStorage.getItem('authToken');

    // Store the UserID before clearing the localStorage
    const storedUserID = localStorage.getItem('UserID');

    // 1. Empty localStorage
    localStorage.clear();
    console.log('Cleared Local Storage. Initializing successLoginFlow');

    // const xsrfToken = result.data.xsrfToken;
    // localStorage.setItem("xsrfToken", xsrfToken);

    // 2. Set the authToken and UserID back into localStorage if they exist
    if (token) {
        localStorage.setItem('authToken', token);
        if (storedUserID) {
            localStorage.setItem('UserID', storedUserID); // Use the storedUserID instead of token.UserID
        }
    } else {
        console.error("No token found");
        return;
    }

    // 3. Ask the Oracle for the required data
    const userID = storedUserID; // Use the storedUserID directly
    if (!userID) {
        console.error("No User ID found");
        return;
    }
  
    const orgsData = await askOracle(`oracle/askLogin?ID=${userID}`);
    if (!orgsData || Object.keys(orgsData).length === 0) {
      console.error("No organization data received from Oracle");
      return;
    }

    console.log('successfulLoginFlow orgsData', orgsData);

    // Set the universal user role
    if (orgsData.user.Role) {
      localStorage.setItem('UserRole', orgsData.user.Role);
    } else {
      console.error("No user role found in response");
    }

    // Update local storage with organizations and dispatch an event
    localStorage.setItem('Organizations', JSON.stringify(orgsData.orgs));
    const activeOrg = orgsData.org[0];
    if (activeOrg) {
      localStorage.setItem('ActiveOrgID', activeOrg.OrgID); // Assuming the correct property name is OrgID
    }
    
    window.dispatchEvent(new Event('localStorageUpdate'));

    console.log('Navigate Redirect');
    navigate('/happypath');    
};

// const handleGoogleSignInResponse = async (response) => {
//     // Ensure response object exists and has the expected structure
//     console.log('Google Sign-In Response:', response);

//     if (!response || !response.credential) {
//         console.error('Invalid Google sign-in response');
//         return;
//     }

//     try {
//         const result = await axios.post(`${GO_BASE_URL}/google-login`, {
//             idToken: response.credential,
//         }, { withCredentials: true });
//         console.log('handleGoogleSignInResponse', response);
//         localStorage.setItem('authToken', result.data.token);
//         setIsLoggedIn(true);
//     } catch (error) {
//         console.error('Google Authentication failed', error);
//     }
// };
      
  useEffect(() => {
    if (isLoggedIn) {
        console.log('isLoggedIn', isLoggedIn);
        successLoginFlow();
    }
}, [isLoggedIn]);

// useEffect(() => {
//   console.log('code from 7:16');
//   // Make an API call to backend to get the CSRF token from the response header
//   axios.get(`${GO_BASE_URL}/init`, { withCredentials: true })
//     .then(response => {
//       console.log('Axios response:', response);
//     })
//     .catch(error => {
//       console.error("Error initializing application:", error);
//     });
// }, []);

const verifyAuthToken = useCallback(async () => {
  const authToken = localStorage.getItem('authToken');

  if (authToken) {
    try {
      const response = await axios.post(`${GO_BASE_URL}/verify-token`, {}, {
        headers: {
          'Authorization': `Bearer ${authToken}`
        },    
        withCredentials: true
      });
      setIsLoggedIn(response.data.valid);
    } catch (error) {
      setIsLoggedIn(false);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('authToken');  // Delete the token
        console.log('User is not logged in');
        navigate("/signup");
      }
    }
  } else {
    setIsLoggedIn(false);
  }
}, [navigate]);


  // const { xsrfToken } = useAuth();  // <-- Use the context here

  // const formData = new FormData();
  // formData.append('email', email);
  // formData.append('password', password);
  // formData.append('xsrfToken', xsrfToken);

  async function handleEmailPasswordAuth(isSignup) {
    setIsLoading(true);
    const endpoint = '/signup';
    try {
      const response = await axios.post(
        `${GO_BASE_URL}${endpoint}`,
        { email, password },
        //   formData,
        { withCredentials: true }
      );
      console.log('API Response:', response.data);
      await localStorage.setItem('authToken', response.data.token);
      console.log('handleEmailPasswordAuth');
      console.log('Token directly after setting:', localStorage.getItem('authToken'));
      // Check if the response contains the UserID and store it in localStorage
      if (response.data.UserID) {
        await localStorage.setItem('UserID', response.data.UserID);
        console.log('UserID directly after setting:', localStorage.getItem('UserID'));
        setIsLoggedIn(true); // Set isLoggedIn to true
      } else {
        console.error("No UserID found in the response");
      }
      // await verifyAuthToken(); // Verify the token immediately after setting it.
      // console.log('verifiedAuthToken');
    } catch (error) {
      console.error('Authentication Error:', error);
      setErrorMessage('Authentication failed');
    }
    setIsLoading(false);
  }

//   useEffect(() => {
//     verifyAuthToken();
//     console.log('verifyAuthToken')

//     const loadGoogleSignInLibrary = (nonce) => {
//       return new Promise((resolve) => {
//         const script = document.createElement('script');
//         script.src = 'https://accounts.google.com/gsi/client';
//         script.async = true;
//         script.defer = true;
//         script.onload = resolve;
//         if (window.nonce) {
//           script.setAttribute('nonce', window.nonce);
//         }
//         document.body.appendChild(script);
//         console.log('loadGoogleSignInLibrary')
//       });
//     };

//     const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
//     loadGoogleSignInLibrary().then(() => {
//       window.google.accounts.id.initialize({
//         client_id: GOOGLE_CLIENT_ID,
//         callback: handleGoogleSignInResponse,
//       });
//       console.log('loadGoogleSignInLibrary')
//     });
//   }, [verifyAuthToken]);

  
  return (
    <div className="auth-container">
  {console.log('Rendering signup-container')}
      {isLoading && <LoadingSpinner />}
      {errorMessage && <div className="auth-error">{errorMessage}</div>}
      {isLoggedIn ? (
<div className="h3 text-dkpurple">User is Logged In
{navigate('/happypath')}    
  </div>
                ) : (
                <>
                <div className={`auth-form-container ${isLoading ? 'hidden' : ''}`}>
                <h1>New User Sign Up</h1>
                {/* <div className="auth-form-container"> */}
                {console.log('Rendering signup-form-container')}
                <motion.img 
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}  // Adjust the delay as needed
                    src="/logo-Teraace-250.png" 
                    alt="logo" 
                    className="auth-logo" 
                />
            
                  <form className="auth-form" onSubmit={(e) => e.preventDefault()}>
                    <SanitizedInput
                    // <motion.input
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.3 }}
                      className="auth-input-email email"
                      type="email"
                      id="email"
                      name="email"
                      autoComplete="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <SanitizedInput
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.4 }}
                      className="auth-input-password password"
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {/* <input
                      className="hidden-input"
                      type="hidden"
                      name="xsrfToken"
                      value={xsrfToken}
                    /> */}
                    {/* <motion.button 
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.5 }}
                      className="auth-btn-signup"
                      onClick={() => handleEmailPasswordAuth(true)}>
                      Sign Up
                    </motion.button> */}
                    <motion.button 
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.4 }}
                      className="auth-btn-signin"
                      onClick={() => handleEmailPasswordAuth(false)}>
                      Sign In
                    </motion.button>
                  </form>
                  {/* <motion.button 
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.8 }}
                    className="auth-btn-google"
                    onClick={handleGoogleSignInResponse}>
                    Sign In with Google
                  </motion.button> */}
                  </div>
                </>
              )}
            </div>
  );
}

export default Auth;
