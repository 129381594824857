// /redux/slices/userJourneySlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = { steps: [] };

const userJourneySlice = createSlice({
  name: 'userJourney',
  initialState,
  reducers: {
    addStep: (state, action) => {
      state.steps.push(action.payload);
    },
    removeStep: (state, action) => {
      state.steps = state.steps.filter(step => step.id !== action.payload);
    },
    updateStep: (state, action) => {
      const index = state.steps.findIndex(step => step.id === action.payload.stepId);
      if (index !== -1) state.steps[index] = action.payload.newStep;
    },
  },
});

export const selectSteps = state => state.userJourney.steps;
export const selectCurrentSteps = state => state.userJourney.steps;
export const { addStep, removeStep, updateStep } = userJourneySlice.actions;
export default userJourneySlice.reducer;
