import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useChat } from './ChatContext';

const ChatPrompts = () => {
    const [topUrls, setTopUrls] = useState([]);
    const [selectedUrls, setSelectedUrls] = useState([]); // State to track all selected URLs
    const { sendMessage } = useChat();
    const [isAnimating, setIsAnimating] = useState(true); // Start with animating state

    const processUniqueURLData = (uniqueURLData) => {
        if (!uniqueURLData) {
            console.error("ChatPrompts: UniqueURL data is unavailable");
            return [];
        }

        let urlArray;
        if (Array.isArray(uniqueURLData)) {
            urlArray = uniqueURLData;
        } else if (typeof uniqueURLData === 'object') {
            urlArray = Object.entries(uniqueURLData).map(([url, data]) => ({
                url,
                frequency: data.frequency || 0
            }));
        } else {
            console.error("ChatPrompts: Invalid format for UniqueURL data");
            return [];
        }

        // Filter and sort by frequency
        return urlArray
        // .filter(item => !selectedUrls.includes(item.url))
        .map(item => ({ ...item, 
            id: `${item.id}`,
            isExpanded: false, // Initial state for expansion
            url: `${item.url}`,
        }))
        // .filter(item => !topUrls.some(topUrl => topUrl.id === item.id))
        .filter(item => !selectedUrls.includes(item.url))
        // .filter(item => !selectedUrls.includes({...item.url, id: `${item.name}-${item.url}` }))
        .sort((a, b) => b.frequency - a.frequency)
        .slice(0, 3)

            // .map(item => item.url);
    };

    useEffect(() => {
        console.log("ChatPrompts: Retrieving dataset from localStorage");
        const storedData = localStorage.getItem('dataset');

        if (!storedData) {
            console.error("ChatPrompts: No dataset found in localStorage");
            return;
        }

        let parsedDataset;
        try {
            parsedDataset = JSON.parse(storedData);
            const processedUrls = processUniqueURLData(parsedDataset.UniqueURL);
            const updatedUrls = processedUrls.map(url => ({ ...url, isExpanded: false }));
            setTopUrls(updatedUrls);
        } catch (error) {
            console.error("ChatPrompts: Error parsing dataset from localStorage", error);
            return;
        }
        
        // const topURLs = processUniqueURLData(parsedDataset.UniqueURL, selectedUrls);
        // setTopUrls(topURLs);
        
        setTimeout(() => setIsAnimating(false), 2000); // Adjust time as needed

    }, [selectedUrls]); // Depend on selectedUrl to update topUrls

    const handleButtonClick = (urlData) => {
        console.log("ChatPrompts: Button clicked for URL", urlData.url);
        setIsAnimating(true); // Start animation

        setTopUrls(currentUrls =>
            currentUrls.map(item =>
                item.id === urlData.id ? { ...item, isExpanded: !item.isExpanded } : item
            )
        );
        
        setSelectedUrls(prevUrls => {
            const newUrls = prevUrls.includes(urlData.url) 
                ? prevUrls.filter(existingUrl => existingUrl !== urlData.url) 
                : [...prevUrls, urlData.url];
            console.log("ChatPrompts: New selectedUrls", newUrls);
            return newUrls;
        });


        const parsedInput = { page: urlData.url.trim() };
        sendMessage({ ...parsedInput, sender: 'user' });
        console.log("ChatPrompts: Sending structured message", parsedInput);

        // After a delay, send the message and reset animation state
        setTimeout(() => {
            setIsAnimating(false); // End animation
            console.log("ChatPrompts: setIsAnimating to false");
        }, 2000);
    };

    const buttonVariants = {
        initial: { x: 100, opacity: 0 }, // Animate in from the right
        animate: (index) => ({
            x: 0,
            opacity: 1,
            transition: {
                delay: index * 0.2
            }
        }),
        exit: { x: -100, opacity: 0 } // Animate out towards the left
    };

    const headerVariants = {
        initial: { x: 100, opacity: 0 }, // Or any specific animation you want for the header
        animate: {
            x: 0,
            opacity: 1,
            transition: { delay: 0.2 } // Adjust the delay as needed
        },
        exit: { x: -100, opacity: 0 }
    };

    return (
        <div className="chat-prompts">
            <AnimatePresence>
                <motion.h3
                    className="chat-prompts-h3"
                    variants={headerVariants}
                    initial="initial"
                    animate={isAnimating ? "exit" : "animate"}
                    exit="exit"
                >
                    Quick Links
                </motion.h3>
                {topUrls.map((urlData, index) => (
                // <motion.div key={urlData.id} className="chat-prompts-div">
                    <motion.button
                    className="chat-prompts-button"
                    custom={topUrls.length - index - 1}
                    variants={buttonVariants}
                    initial="initial"
                    animate={isAnimating ? "exit" : "animate"}
                    exit="exit"
                    onClick={() => handleButtonClick(urlData)}
                    >
                    {urlData.url || "No URL"}
                    </motion.button>
                // </motion.div>
                ))}
    </AnimatePresence>
        </div>
    );
};

export default ChatPrompts;