//useD3Graph.jsx
import { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';

console.log("useD3Graph load");


const useD3Graph = ({
  svgRef,
  dimensions,
  status,
  graphNodes,
  graphEdges,
  sources,
  targets,
  selectedNodeIds,
  handleNodeClick,
  UniqueURL,
  UniqueEventDescList,
  nodesRef, // Pass refs if needed
  linksRef,
  simulationRef,
  shortestPath,
  iconVisibleNodeIds,
  onIconClick,
}) => {
  const rScaleRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [zoomTransform, setZoomTransform] = useState(d3.zoomIdentity);
  const [nodesState, setNodesState] = useState([]);
  const [pathLinksSet, setPathLinksSet] = useState(new Set());

  // Step 1: Build pathLinksSet from shortestPath
  useEffect(() => {
    if (shortestPath.length < 2) {
      // If shortestPath is empty or has only one node, clear the Set
      setPathLinksSet(new Set());
      console.log('Shortest path is too short. Clearing pathLinksSet.');
      return;
    }
    console.log('shortestPath:', shortestPath);

    const newPathLinksSet = new Set();
    for (let i = 0; i < shortestPath.length - 1; i++) {
      const source = shortestPath[i];
      const target = shortestPath[i + 1];
      newPathLinksSet.add(`${source}->${target}`);
      newPathLinksSet.add(`${target}->${source}`); // Include reverse if undirected
    }
    setPathLinksSet(newPathLinksSet);

    // Debugging: Verify the Set
    console.log('pathLinksSet:', newPathLinksSet);
    console.log('Is pathLinksSet a Set?', newPathLinksSet instanceof Set);
  }, [shortestPath]);

  // Step 2: Helper function using the Set
  function isLinkInShortestPath(link, pathLinksSet) {
    if (!(pathLinksSet instanceof Set)) {
      console.warn('pathLinksSet is not a Set:', pathLinksSet);
      return false;
    }
    
    // Debugging: Verify the Set
    // console.log('pathLinksSet:', pathLinksSet);
    // console.log('Is pathLinksSet a Set?', pathLinksSet instanceof Set); // Should log: true

    const sourceId = link.source.id || link.source; // Adjust based on link structure
    const targetId = link.target.id || link.target;
    const linkId = `${sourceId}->${targetId}`;
    
    const isInPath = pathLinksSet.has(linkId);
    console.log(`pathLinksSet: Link ${linkId} is in shortest path: ${isInPath}`);
    
    return isInPath;
  }

  useEffect(() => {
    const svg = d3.select(svgRef.current);
  
    // Select all link elements
    const linkSelection = svg.selectAll('.link');
  
    // Update link styles based on pathLinksSet
    linkSelection
      .transition() // Optional: Adds a smooth transition
      .duration(500)
      .attr('stroke', d => {
        if (pathLinksSet.size === 0) {
          return '#2c294b'; // Default black color
        }
        return isLinkInShortestPath(d, pathLinksSet) ? '#c5316a' : '#dbdbde'; // Pink or gray
      })
      .attr('stroke-width', d => {
        if (pathLinksSet.size === 0) {
          return 1; // Default stroke width
        }
        return isLinkInShortestPath(d, pathLinksSet) ? 8 : 1; // Thicker stroke for path links
      });
  }, [pathLinksSet]); // Dependency array ensures this runs when pathLinksSet changes
  
  // useEffect(() => {
  //   if (shortestPath.length > 1) {
  //     // Create or update node labels for the shortest path
  //     const nodeLabels = zoomedContainer.selectAll('.node-label')
  //       .data(
  //         nodesRef.current.filter(d => shortestPath.includes(d.id)),
  //         d => d.id
  //       )
  //       .join(
  //         enter => enter.append('text')
  //           .attr('class', 'node-label')
  //           .text(d => d.id)
  //           .attr('font-size', '12px')
  //           .attr('fill', 'black'),
  //         update => update,
  //         exit => exit.remove()
  //       );
  
  //     // Ensure labels are brought to the front
  //     nodeLabels.each(function () {
  //       this.parentNode.appendChild(this);
  //     });
  
  //     // Update label positions in the tick function
  //     simulationRef.current.on('tick', () => {
  //       nodeLabels
  //         .attr('x', d => d.x + 10)
  //         .attr('y', d => d.y + 5);
  //     });
  //   }
  // }, [shortestPath]);
  
  useEffect(() => {

    if (status !== 'success' || !sources ) {
      console.log("Data not ready yet.");
      return;
    }

  if (graphNodes.length === 0 || graphEdges.length === 0) {
    console.error('Edges and Nodes are not properly defined in graphData.');
    return;
  }

  if (!svgRef.current) {
    console.log("SVG element not yet available.");
    return;
  }

  // console.log("useD3Graph Nodes:", graphNodes);
  // console.log("useD3Graph Edges:", graphEdges);
  // console.log("useD3Graph Sources:", sources);
  // console.log("useD3Graph Targets:", targets);
  // console.log("useD3Graph status:", status);
  // console.log("useD3Graph UniqueURL:", UniqueURL);
  // console.log("useD3Graph UniqueEventDescList:", UniqueEventDescList);

  // Deep copy of links and nodes
  const nodes = graphNodes.map(d => ({ ...d }));
  const links = graphEdges.map(d => ({
    source: nodes.find(node => node.id === d.source),
    target: nodes.find(node => node.id === d.target),
  }));
  
  // Create SVG container
  const svg = d3.select(svgRef.current)
    .attr('width', dimensions.width)
    .attr('height', dimensions.height)
    .attr('viewBox', [0, 0, dimensions.width, dimensions.height])
    .attr('style', 'max-width: 100%; height: auto;');

  // Select or append a 'g' element to the SVG only once
  const zoomedContainer = svg.select('g').empty() ? svg.append('g') : svg.select('g');

  const link = zoomedContainer.selectAll('.link')
    .data(links, d => `${d.source.id}-${d.target.id}`) // Use a unique key
    .join(
      enter => enter.append('line').attr('class', 'link'),
      update => update,
      exit => exit.remove()
    )
    .attr('stroke', d => (isLinkInShortestPath(d, pathLinksSet) ? '#c5316a' : '#ebe5df'))
    .attr('stroke-opacity', 0.6)
    .attr('stroke-width', d => (isLinkInShortestPath(d, pathLinksSet) ? 8 : 1));

  // Move highlighted links to the top
  link.filter(d => isLinkInShortestPath(d, pathLinksSet)).each(function () {
    this.parentNode.appendChild(this);
  });

    const labelDiv = d3.select('#label');

    linksRef.current = links;

    console.log("UniqueURL:", UniqueURL);
    console.log("UniqueEventDescList:", UniqueEventDescList);

const counts = graphNodes
    .filter((node) => node.id !== "begin" && node.id !== "end" && node.id !== "Empty" && node.id !== undefined)
    .map((node) => {
    return UniqueURL[node.id] || UniqueEventDescList[node.id] || 0;
    });

const minCount = Math.min(...counts);
const maxCount = Math.max(...counts);
const domain = minCount === maxCount ? [0, maxCount] : [minCount, maxCount];

console.log('MinCount:', minCount);
console.log('maxCount:', maxCount);

rScaleRef.current = d3.scaleLinear()
    .domain(domain)
    .range([5, 70]);  // Range of circle radius, adjust as needed

console.log('Counts:', counts);

// Update nodesRef with the new nodes data
nodesRef.current = [...nodes];
// Create the D3 selection\


const validNodes = nodesRef.current.filter(d => d && typeof d.id === 'string');

console.log('before node rend validNodes:', validNodes);

console.log('before node rend nodesRef.current:', nodesRef.current);

const node = zoomedContainer.selectAll('.node')
  .data(validNodes, d => (d ? d.id : undefined)) // Defensive accessor
  .join(
    enter => enter.append('circle').attr('class', 'node'),
    update => update,
    exit => exit.remove()
  )
  .attr('class', d => `node ${selectedNodeIds.includes(d.id) ? 'selected-node' : ''}`)
  .attr('stroke', '#7174b0')
  .attr('stroke-width', 1.5)
  .attr('r', d => {
    const count = UniqueURL[d.id] || UniqueEventDescList[d.id] || 0;
    return rScaleRef.current(count);
  })
  .attr('stroke', d => (shortestPath.includes(d.id) ? '#c5316a' : 'none'))
  .attr('stroke-width', d => (shortestPath.includes(d.id) ? 1.5 : 0))
  .attr('fill', d => (selectedNodeIds.includes(d.id) ? '#c5316a' : '#3b3484'))
  .on('click', (event, d) => {
    if (linksRef.current && linksRef.current.length > 0) {
      handleNodeClick(event, d, linksRef.current);
    } else {
      console.error('Links data is not available or not populated yet');
    }
  })
  .call(d3.drag()
    .on('start', dragstarted)
    .on('drag', dragged)
    .on('end', dragended));

  node.filter(d => shortestPath.includes(d.id)).each(function () {
    this.parentNode.appendChild(this);
  });

const sanitizeId = id => id.replace(/[^a-zA-Z0-9-_]/g, '_');
const nodeLabels = zoomedContainer.selectAll('.hover-node-label')
  .data(nodesRef.current, d => d.id)
  .join(
    enter => enter.append('text')
      .attr('class', 'hover-node-label') // Unique class for hover labels
      .attr('id', d => `hover-label-${sanitizeId(d.id)}`) // Sanitize ID
      .text(d => d.id)
      .attr('font-size', '10px')
      .attr('fill', 'black') // Different color to distinguish
      .style('visibility', 'hidden'), // Initially hidden
    update => update,
    exit => exit.remove()
  );


// Exit old nodes
node.exit().remove();

console.log("Updated nodesRef.current: ", nodesRef.current);

const nodeIds = new Set(nodesRef.current.map(d => d.id));

console.log("nodeIds:", nodeIds);
console.log("Nodes:", nodesRef.current.map(d => d.id));
console.log('Links:', links);
console.log('LinksRef:', linksRef);

// Check if each source and target exists in nodes
links.forEach((link, index) => {
const sourceExists = nodesRef.current.some(node => node.id === link.source.id);
const targetExists = nodesRef.current.some(node => node.id === link.target.id);

if (!sourceExists || !targetExists) {
console.log(`Invalid link at index ${index}: `, link);
}
});

console.log("Using nodesRef.current in simulation 5: ", nodesRef.current);


// plan b
if (simulationRef.current) {
console.log('Is simulation an instance of d3.forceSimulation: ', simulationRef.current instanceof d3.forceSimulation);

simulationRef.current.nodes(nodesRef.current);

simulationRef.current.force('link').links(links);
simulationRef.current.alpha(1).restart();  // Reheat and restart the simulation
console.log("Is simulation an instance of d3.forceSimulation: ", simulationRef.current instanceof d3.forceSimulation);

if (!simulationRef.current.force('charge')) {
  console.error("Charge force is not defined");
}

console.log("Forces before simulation start:", {
  link: simulationRef.current.force('link'),
  charge: simulationRef.current.force('charge'),
  center: simulationRef.current.force('center')
});

} else {
console.log("at Simulation : links", links)
console.log("at Simulation : nodesRef.current", nodesRef.current)  // Shouldn't be empty now
simulationRef.current = d3.forceSimulation(nodesRef.current)  // Initialize with nodesRef.current
.force('link', d3.forceLink(links).id(d => d.id).distance(75))
.force('charge', d3.forceManyBody().strength(-30))
.force("center", d3.forceCenter(dimensions.width / 2, (dimensions.height / 2 - 20)))
.force('collide', d3.forceCollide().radius(d => d.radius + 5).strength(1)) // Prevent overlap
.on('tick', ticked);
}

// console.log("Type of charge force", typeof simulationRef.current.force('charge'));
// console.log("Value of charge force", simulationRef.current.force('charge'));

// console.log("NodesRef after simulation 6:", nodesRef.current);

// console.log("log all methods force('charge'",Object.keys(simulationRef.current.force('charge')));


// console.log("log all methods force('charge'",Object.keys(simulationRef.current.force('charge')));

// console.log("Is charge an instance of d3.forceManyBody: ", simulationRef.current.force('charge') instanceof d3.forceManyBody);

// console.log("log all methods force('charge'):", Object.keys(charge));
// console.log("Is charge an instance of d3.forceManyBody: ", charge instanceof d3.forceManyBody);

// console.log('charge prototype:', Object.getPrototypeOf(charge));
// console.log('d3.forceManyBody prototype:', Object.getPrototypeOf(d3.forceManyBody()));
// console.log('Are they equal:', Object.getPrototypeOf(charge) === Object.getPrototypeOf(d3.forceManyBody()));

const charge = simulationRef.current.force('charge');


if (!simulationRef.current) {
  console.warn('Simulation is not initialized yet.');
  return;
}
const simulationNodes = simulationRef.current.nodes();


node.each(function (d) {
  // Remove any existing icons to prevent duplicates
  d3.select(this).select('.node-icon').remove();

  // Ensure iconVisibleNodeIds is a Set before proceeding
  if (iconVisibleNodeIds instanceof Set && iconVisibleNodeIds.has(d.id)) {
    d3.select(this)
      .append('text') // Or 'image' if you prefer an image icon
      .attr('class', 'node-icon')
      .attr('x', 10) // Adjust position as needed
      .attr('y', -10)
      .attr('text-anchor', 'start')
      .style('font-size', '16px')
      .style('cursor', 'pointer')
      .text('🛈') // Unicode character for an info icon
      .on('click', (event) => {
        event.stopPropagation(); // Prevent the click from propagating to the node
        onIconClick(d.id);
      });
  }
});

function ticked() {
  link
    .attr('x1', d => d.source.x)
    .attr('y1', d => d.source.y)
    .attr('x2', d => d.target.x)
    .attr('y2', d => d.target.y);

  node
    .attr('cx', d => d.x)
    .attr('cy', d => d.y);

  // Update shortest path label positions
  shortestNodeLabels.each(function (d) {
    const group = d3.select(this);
    const radius = rScaleRef.current(d.id) || 20; // Default radius if undefined
    const offset = 5;

    const text = group.select('text');
    const textWidth = text.node().getComputedTextLength();
    const textHeight = 12; // Assume 12px font size

    // Anchor text
    text.attr('x', d.x + radius + offset)
        .attr('y', d.y + textHeight / 2);

    // Anchor background rect
    group.select('rect')
      .attr('x', d.x + radius + offset - 2) // Add padding
      .attr('y', d.y - textHeight / 2) // Center vertically
      .attr('width', textWidth + 4) // Add padding
      .attr('height', textHeight + 4); // Add padding
  });
}


simulationRef.current.on('tick', () => {
  shortestNodeLabels.each(function (d) {
    const group = d3.select(this);
    const radius = rScaleRef.current(d.id) || 20; // Fallback to default radius
    const offset = 5;

    const text = group.select('text');
    const textWidth = text.node().getComputedTextLength();
    const textHeight = 12; // Assume 12px font size

    console.log('Node Positions:', nodesRef.current.map(n => ({ id: n.id, x: n.x, y: n.y })));

    text.attr('x', d.x + radius + offset)
        .attr('y', d.y + textHeight / 2);

    group.select('rect')
      .attr('x', d.x + radius + offset - 2)
      .attr('y', d.y - textHeight / 2)
      .attr('width', textWidth + 4)
      .attr('height', textHeight + 4);
  });
});

// const shortestNodeLabels = zoomedContainer.selectAll('.shortest-node-label')
//   .data(
//     shortestPath.length > 1
//       ? nodesRef.current.filter(d => shortestPath.includes(d.id))
//       : [], // No labels if no shortestPath
//     d => d.id
//   )
//   .join(
//     enter => enter.append('text')
//       .attr('class', 'shortest-node-label') // Unique class for shortest path labels
//       .attr('id', d => `shortest-label-${d.id}`) // Unique ID for each shortest path label
//       .text(d => d.id)
//       .attr('font-size', '12px')
//       .attr('background-fill', 'white')
//       .attr('fill', 'black'),
//     update => update,
//     exit => exit.remove()
//   );

const shortestNodeLabels = zoomedContainer.selectAll('.shortest-node-label-group')
  .data(
    shortestPath.length > 1
      ? nodesRef.current.filter(d => shortestPath.includes(d.id))
      : [], // No labels if no shortestPath
    d => d.id
  )
  .join(
    enter => {
      const group = enter.append('g')
        .attr('class', 'shortest-node-label-group')
        .attr('id', d => `shortest-label-group-${d.id}`);

      group.append('rect')
        .attr('class', 'shortest-label-bg')
        .attr('fill', 'white')
        .attr('rx', 3)
        .attr('ry', 3);

      group.append('text')
        .attr('class', 'shortest-node-label')
        .text(d => d.id)
        .attr('font-size', '12px')
        .attr('fill', 'black');

      return group;
    },
    update => update,
    exit => exit.remove()
  );

// const shortestNodeLabels = zoomedContainer.selectAll('.shortest-node-label-group')
//   .data(
//     shortestPath.length > 1
//       ? nodesRef.current.filter(d => shortestPath.includes(d.id))
//       : [], // No labels if no shortestPath
//     d => d.id
//   )
//   .join(
//     enter => {
//       const group = enter.append('g') // Create a group for each label
//         .attr('class', 'shortest-node-label-group')
//         .attr('id', d => `shortest-label-group-${d.id}`); // Unique ID for each group

//       // Add background rectangle
//       group.append('rect')
//         .attr('class', 'shortest-label-bg') // Background class
//         .attr('fill', 'white')
//         .attr('rx', 3) // Rounded corners (optional)
//         .attr('ry', 3); // Rounded corners (optional)

//       // Add text
//       group.append('text')
//         .attr('class', 'shortest-node-label')
//         .text(d => d.id)
//         .attr('font-size', '12px')
//         .attr('fill', 'black');

//       return group;
//     },
//     update => update,
//     exit => exit.remove()
//   );

// Position the label group
shortestNodeLabels.each(function (d) {
  const group = d3.select(this);
  const radius = rScaleRef.current(d.id) || 0; // Get radius from scale
  const offset = 5; // Desired offset from the node

  // Get text element dimensions
  const text = group.select('text');
  const textWidth = text.node().getComputedTextLength().width;
  const textHeight = text.node().getComputedTextLength().height;

  // Position text relative to node
  text.attr('x', d.x + radius + offset)
      .attr('y', d.y + textHeight / 2); // Center vertically

  // Position background rect relative to text
  group.select('rect')
    .attr('x', d.x + radius + offset - 2) // Add padding
    .attr('y', d.y - textHeight / 2) // Center vertically
    .attr('width', textWidth + 4) // Add padding
    .attr('height', textHeight + 4); // Add padding
});

// After creating the 'link' selection
link.filter(d => isLinkInShortestPath(d, pathLinksSet)).each(function () {
  this.parentNode.appendChild(this);
});

// After creating the 'node' selection
node.filter(d => shortestPath.includes(d.id)).each(function () {
  this.parentNode.appendChild(this);
});

// After creating 'nodeLabels' selection
shortestNodeLabels.each(function () {
  this.parentNode.appendChild(this);
});

// Ensure ticked function is called during simulation
simulationRef.current.on('tick', ticked);
console.log("Simulation running:", simulationRef.current.alpha() > 0);


  // Create text elements for labels on edges
  const linkLabels = zoomedContainer.selectAll('.link-label')
    .data(links)
    .enter()
    .append('text')
    .text(d => `from ${d.source.id} to ${d.target.id}`)
    .attr('class', 'link-label')
    .style('visibility', 'hidden');

  function dragstarted(event) {
    if (!event.active) simulationRef.current.alphaTarget(0.3).restart();
    event.subject.fx = event.subject.x;
    event.subject.fy = event.subject.y;
  }

  function dragged(event) {
    event.subject.fx = event.x;
    event.subject.fy = event.y;
  }

  function dragended(event, d) {
    if (!event.active) simulationRef.current.alphaTarget(0);
    d.fx = null;
    d.fy = null;
  
    // Only stopPropagation if the node was actually dragged
    if (event.sourceEvent.type === 'drag') {
      event.sourceEvent.stopPropagation();
    }
  }
  
  // node.on('mouseenter', function (event, d) {
  //   d3.select(this).attr('r', rScaleRef.current(UniqueURL[d.id] || UniqueEventDescList[d.id] || 0) + 2);
  //   nodeLabels.filter(labelData => labelData.id === d.id)
  //     .style('visibility', 'visible');
  //   labelDiv.text(`Node: ${d.id}`);

  // })
  // .on('mouseleave', function (event, d) {
  //   d3.select(this).attr('r', rScaleRef.current(UniqueURL[d.id] || UniqueEventDescList[d.id] || 0));
  //   nodeLabels.filter(labelData => labelData.id === d.id)
  //     .style('visibility', 'hidden');
  //   labelDiv.text('');
  // })

  node.on('mouseenter', function (event, d) {
    // Highlight the hovered node
    d3.select(this).attr('r', rScaleRef.current(UniqueURL[d.id] || UniqueEventDescList[d.id] || 0) + 2);
  
    // Use sanitized ID for hover label
    const hoverLabel = d3.select(`#hover-label-${sanitizeId(d.id)}`);
    if (!hoverLabel.empty()) {
      hoverLabel.style('visibility', 'visible');
    }
  
    // Update the labelDiv for detailed info (optional)
    labelDiv.text(`Node: ${d.id}`);
  })
  .on('mouseleave', function (event, d) {
    // Reset the node size
    d3.select(this).attr('r', rScaleRef.current(UniqueURL[d.id] || UniqueEventDescList[d.id] || 0));
  
    // Use sanitized ID for hover label
    const hoverLabel = d3.select(`#hover-label-${sanitizeId(d.id)}`);
    if (!hoverLabel.empty()) {
      hoverLabel.style('visibility', 'hidden');
    }
  
    // Clear the labelDiv
    labelDiv.text('');
  })
  
  .on('click', (event, d) => {
    if(linksRef.current && linksRef.current.length > 0) {
      console.log('LinksRef at the time of click:', linksRef.current);
      handleNodeClick(event, d, linksRef.current);
    } else {
      console.error('Links data is not available or not populated yet');
    }
  });
  
  link.on('mouseenter', function (event, d) {
    labelDiv.text(`Link from ${d.source.id} to ${d.target.id}`);
  })
    .on('mouseleave', function () {
      labelDiv.text('');
    });

    setIsLoading(false);

    // Clean-up function
  return () => simulationRef.current.stop();
}, [status, graphNodes, graphEdges, dimensions, UniqueEventDescList, UniqueURL, zoomTransform, selectedNodeIds, sources, handleNodeClick, pathLinksSet]);


useEffect(() => {
  if (!svgRef.current) {
    console.log("SVG element not yet available for updating styles.");
    return;
  }

  const svg = d3.select(svgRef.current);
  const zoomedContainer = svg.select('g.zoomable');

  // Ensure node and link selections are available
  const node = zoomedContainer.selectAll('.node');
  const link = zoomedContainer.selectAll('.link');

  // Function to update node and link styles based on shortestPath
  function updateStyles() {
    if (!shortestPath || shortestPath.length === 0) {
      // Reset styles if there's no shortest path
      node.attr('fill', d => {
        if (selectedNodeIds.length === 2 && selectedNodeIds.includes(d.id)) {
          return '#c5316a'; // Color for selected nodes
        } else {
          return '#3b3484'; // Default node color
        }
      });

      link.attr('stroke', '#2c294b'); // Default link color
      return;
    }

    // Highlight nodes in the shortest path
    node.attr('fill', d => {
      if (shortestPath.includes(d.id)) {
        return 'red'; // Highlight color
      } else if (selectedNodeIds.length === 2 && selectedNodeIds.includes(d.id)) {
        return '#c5316a'; // Color for selected nodes
      } else {
        return '#3b3484'; // Default node color
      }
    });

    // Create a set of node pairs representing the shortest path links
    const pathLinks = new Set();
    for (let i = 0; i < shortestPath.length - 1; i++) {
      const source = shortestPath[i];
      const target = shortestPath[i + 1];
      pathLinks.add(`${source}->${target}`);
      pathLinks.add(`${target}->${source}`); // Include reverse if undirected
    }

    // Highlight links that are part of the shortest path
    link.attr('stroke', d => {
      if (
        pathLinks.has(`${d.source.id}->${d.target.id}`) ||
        pathLinks.has(`${d.target.id}->${d.source.id}`)
      ) {
        return 'red'; // Highlight color
      } else {
        return '#2c294b'; // Default link color
      }
    });
  }

  // Call the updateStyles function
  updateStyles();
}, [shortestPath]); 

  useEffect(() => {
    // Assuming svgRef is a ref to your SVG element
    if (!svgRef.current) {
      console.log("SVG element not yet available.");
      return;
    }
    console.log("SVG Element:", svgRef.current);
    const svg = d3.select(svgRef.current);
    console.log("Establishing Zoom context:");

    // Define the zoom behavior
    const zoom = d3.zoom()
      .scaleExtent([0.5, 20])
      .on('zoom', (event) => {
        // event.preventDefault(); // Prevent default zooming
        // Apply the zoom transform directly to the zoomedContainer
        const zoomedContainer = svg.select('g.zoomable');
        zoomedContainer.attr('transform', event.transform);
        // console.log("Zoom event:", event.transform);
      });
  
    // Apply the zoom behavior to the SVG element
    svg.call(zoom);
  
    // Cleanup function to remove zoom behavior when the component is unmounted
    return () => {
      svg.on('.zoom', null);
    };
  }, [status, sources, graphNodes, graphEdges]);
  

  useEffect(() => {
    // When nodesRef.current is populated, update nodesState to trigger a re-render
    if (nodesRef.current.length > 0) {
      setNodesState(nodesRef.current);
    }
  }, [nodesRef.current.length]); // Trigger only when nodesRef.current.length changes

  useEffect(() => {
    console.log('Current nodesState in LinkRef create:', nodesState);
  
    if (nodesState && nodesState.length > 0) {
      // Calculate weights and find min and max weights
      let minWeight = Infinity;
      let maxWeight = -Infinity;
  
      const linksWithWeights = graphEdges.map(d => {
        const sourceNode = nodesState.find(node => node.id === d.source);
        const targetNode = nodesState.find(node => node.id === d.target);
  
        let weight = Math.sqrt(
          Math.pow(targetNode.x - sourceNode.x, 2) +
          Math.pow(targetNode.y - sourceNode.y, 2)
        );
        // console.log('sourceNode', sourceNode);
        // console.log('targetNode', targetNode);
        // console.log('weight', weight);

        minWeight = Math.min(minWeight, weight);
        maxWeight = Math.max(maxWeight, weight);
  
        return {
          ...d,
          source: sourceNode,
          target: targetNode,
          weight: weight
        };
      });
  
      // Normalize weights to a 1-5 scale
      const normalizedLinks = linksWithWeights.map(link => {
        const distancerating = 1 + 4 * (link.weight - minWeight) / (maxWeight - minWeight);
        return {
          ...link,
          distancerating: Math.round(distancerating), // Round to the nearest integer
          weight: Math.round(link.weight) // Round to the nearest integer
        };
      });
  
      linksRef.current = normalizedLinks;
      console.log('After populating LinksRef - linksRef.current:', linksRef.current);
    }
  }, [nodesState]);
  

  // Return any refs or data you need in your component
  return {
    simulationRef,
    nodesRef,
    linksRef,
    rScaleRef,
  };
};

export default useD3Graph;
