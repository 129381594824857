//D3GraphRend.jsx
import React, { useRef, useContext } from 'react';
import * as d3 from 'd3';
import { DimensionContext } from '../ResponsiveWrapper';
import useD3Graph from './useD3Graph';

console.log("D3GraphRend load");

const D3Graph = ({
  status,
  svgRef,
  graphNodes,
  graphEdges,
  sources,
  targets,
  UniqueURL,
  UniqueEventDescList,
  handleNodeClick,
  selectedNodeIds,
  nodesRef, // Pass refs if needed
  linksRef,
  simulationRef,
  shortestPath,
}) => {
  const dimensions = useContext(DimensionContext);

  // Use the custom hook
  useD3Graph({
    svgRef,
    nodesRef, // Pass refs if needed
    linksRef,
    simulationRef,
    dimensions,
    status,
    sources,
    targets,
    graphNodes,
    graphEdges,
    selectedNodeIds,
    handleNodeClick,
    UniqueURL,
    UniqueEventDescList,
    shortestPath,
  });

return (
    <>
<svg ref={svgRef} width={dimensions.width} height={dimensions.height}>
<g className="zoomable">
  {nodesRef.current.map((node) => (
    <circle
      key={node.id}
      className={`node ${selectedNodeIds.includes(node.id) ? 'selected-node' : ''}`}
      onClick={(event) => {
        handleNodeClick(event, node, linksRef.current);
      }}
    />
  ))}
</g>
</svg>
<div    width={dimensions.width}
        id="label"
        style={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          textAlign: 'center',
          padding: '10px',
          fontSize: '14px',
        }}
      >
        {/* Label content will be updated dynamically */}
      </div>
      </>
)
};

export default D3Graph;
