import React, { useRef, useState, useContext, useCallback } from 'react';
import LoadingSpinner from '../components/LoadingSpinner'; 
import SEO from '../components/SEO';
import { DimensionContext } from '../components/ResponsiveWrapper';
import InfoBox from '../components/InfoBox';
import NodeBox from '../components/path/NodeBox';
import PathDataSets from '../components/path/useDataset';
import NodeClick  from '../components/path/NodeClick';

const PathAnalyzer = ({ data }) => {
  const dimensions = useContext(DimensionContext);
  const { width, height } = useContext(DimensionContext);
  const [isLoading, setIsLoading] = useState(true);
  const [graphData, setGraphData] = useState(null);
  const [graphNodes, setGraphNodes] = useState([]);
  const [graphEdges, setGraphEdges] = useState([]);
  const [sources, setSources] = useState([]);
  const [targets, setTargets] = useState([]);
  const [UniqueURL, setUniqueURL] = useState('');
  const [UniqueEventDescList, setUniqueEventDescList] = useState('');
  const [status, setStatus] = useState('');
  const [nodeBoxContent1, setNodeBoxContent1] = useState({ title: '', description: '' });
  const [nodeBoxContent2, setNodeBoxContent2] = useState({ title: '', description: '' });
  const [isNodeBox1Visible, setIsNodeBox1Visible] = useState(false);
  const [isNodeBox2Visible, setIsNodeBox2Visible] = useState(false);
  const simulationRef = useRef();
  const nodesRef = useRef([]);
  const linksRef = useRef([]);
  const rScaleRef = useRef(null);
  const [selectedNodeIds, setSelectedNodeIds] = useState([]);

  
  // Callback to receive the data from PathDataSets
  const handleDataLoaded = (data) => {
    setStatus(data.status);
    setGraphData(data.graphData);
    setGraphNodes(data.graphNodes);
    setGraphEdges(data.graphEdges);
    setSources(data.sources);
    setTargets(data.targets);
    setUniqueURL(data.UniqueURL);
    setUniqueEventDescList(data.UniqueEventDescList);
    setIsLoading(false); 
  };

  const handleD3GraphData = (data) => {
    setStatus(data.status);
    setGraphData(data.graphData);
    setGraphNodes(data.graphNodes);
    setGraphEdges(data.graphEdges);
    setSources(data.sources);
    setTargets(data.targets);
    setUniqueURL(data.UniqueURL);
    setUniqueEventDescList(data.UniqueEventDescList);
  };

  const handleNodeDataChange = ({
    nodeBoxContent1,
    nodeBoxContent2,
    isNodeBox1Visible,
    isNodeBox2Visible,
  }) => {
    setNodeBoxContent1(nodeBoxContent1);
    setNodeBoxContent2(nodeBoxContent2);
    setIsNodeBox1Visible(isNodeBox1Visible);
    setIsNodeBox2Visible(isNodeBox2Visible);
  };
  


  return (
    
    <>
          <PathDataSets onDataLoaded={handleDataLoaded} />

    <div className="bg-white bg-opacity-80">
            {isLoading ? <LoadingSpinner /> : null} 
        <>
          {isNodeBox1Visible && (
            <NodeBox
              isVisible={isNodeBox1Visible}
              content={{ title: nodeBoxContent1.title, description: nodeBoxContent1.description }}
              onClose={() => setIsNodeBox1Visible(false)}
              className="nodebox"
            />
          )}
          {isNodeBox2Visible && (
            <NodeBox
              isVisible={isNodeBox2Visible}
              content={{ title: nodeBoxContent2.title, description: nodeBoxContent2.description }}
              onClose={() => setIsNodeBox2Visible(false)}
              className="nodebox2"
            />
          )}
        </>
            <h1 className="h1 banner">Path Analyzer</h1>
            <NodeClick
              status={status}
              graphNodes={graphNodes}
              graphEdges={graphEdges}
              sources={sources}
              targets={targets}
              UniqueURL={UniqueURL}
              UniqueEventDescList={UniqueEventDescList}
              nodesRef={nodesRef}
              linksRef={linksRef}
              simulationRef={simulationRef}
              isNodeBox1Visible={isNodeBox1Visible}
              isNodeBox2Visible={isNodeBox2Visible}
              nodeBoxContent1={nodeBoxContent1}
              nodeBoxContent2={nodeBoxContent2}
              setIsNodeBox1Visible={setIsNodeBox1Visible}
              setIsNodeBox2Visible={setIsNodeBox2Visible}
              onNodeDataChange={handleNodeDataChange}
           />
    <div>
    <SEO
      title="Path Analyzer"
      description="Click two nodes to see the shortest path between them"
      url="/pathanalyzer"
    />
    </div>
    </div>
      <InfoBox title={'Path Analyzer'} description={'Analyze how your users move through your application. Click on two nodes to see the shortest route.'} />
</>
  );
};

export default PathAnalyzer;