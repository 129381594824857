// FlowVisualization.jsx
import React, { useState, useEffect } from 'react';
import ReactFlow, { MiniMap, Background, Controls, Handle } from 'react-flow-renderer';
import { useDataset } from './DataFetcher'; 

console.log("FlowVisualization");

const FlowVisualization = ({ nodeNames }) => {

    console.log("FlowVisualization nodeNames:", nodeNames);
    const [elements, setElements] = useState([]);
    const { data, status } = useDataset();


    useEffect(() => {
    }, [nodeNames]);
    


    useEffect(() => {
        console.log(`Effect triggered: status=${status}, nodeNames=`, nodeNames, `, data=`, data);
        if (status === 'success' && nodeNames && nodeNames?.length > 0) {
            try{
            const newElements = generateElements(nodeNames, data);
            console.log("Setting elements in state:", newElements);
            // setElements(newElements);
            if (JSON.stringify(elements) !== JSON.stringify(newElements)) {
                setElements(newElements);
            }
            console.log("setElements nodeNames", nodeNames);
        } catch (error) {
            console.error("Error generating elements:", error);
        }
        }
    }, [nodeNames, elements, data, status]);

    const generateElements = (nodeNames, data) => {
        console.log("generateElements called with nodeNames:", nodeNames);

        if (!data.MarkovChain) {
            console.log("No MarkovChain data available.");
            return [];
        }
        // Parse MarkovChain data - replace this with actual parsing logic
        const markovChainData = JSON.parse(data.MarkovChain).data || [];
        console.log("generateElements markovChainData", markovChainData);

        
        // Create nodes
        const nodes = nodeNames.map((name, index) => ({
            id: `node-${name}`,
            type: 'default', // or any other type you might have
            data: { label: name },
            position: { x: 100 * index, y: 100 } // example positioning, adjust as needed
        }));
        console.log("generateElements nodes", nodes);

        console.log("Rendering with elements:", elements);
       
        const edges = markovChainData.reduce((acc, [source, target, probability]) => {
            const sourceId = `node-${source.toString().trim()}`;
            const targetId = `node-${target.toString().trim()}`;        
            if (sourceId === targetId) {
                return acc;
            }
            if (nodeNames.includes(source) && nodeNames.includes(target)) {
                // Convert probability to percentage and format it to one decimal place
                const probabilityPercentage = (probability * 100).toFixed(1);
        
                acc.push({
                    id: `edge-${sourceId}-${targetId}`,
                    source: sourceId,
                    target: targetId,
                    label: `Probability: ${probabilityPercentage}%`
                });
            }
            return acc;
        }, []);
        
        
    
        return [...nodes, ...edges];
    };
    console.log("Final elements to render:", elements);

    console.log("Final nodes elements to render:", elements.nodes);
    console.log("Final edges elements to render:", elements.edges);

    console.log("defaultNodes in render:", ReactFlow.defaultNodes);
    console.log("defaultEdges in render:", ReactFlow.defaultEdges);

    return (
        <div className="flow-container">
<ReactFlow
            elements={elements}
            // onNodesChange={handleNodesChange}
            // onEdgesChange={handleEdgesChange}
            // onConnect={handleConnect}
                defaultNodes={elements.filter(e => e.id.startsWith('node'))}
                defaultEdges={elements.filter(e => e.id.startsWith('edge'))}
                
                className="reactflow"
                minZoom={0.5}
                maxZoom={2}
                panOnScroll={true}
                panOnDrag={true}
            >
                <Background variant="dots" gap={26} size={2} color={'#ebe5df'}/>
                <Controls />
                <MiniMap
                nodeStrokeColor={() => '#2c294b'}
                nodeColor={() => '#fff'}
                nodeBorderRadius={2}    
                />

<Handle type="target" position="left" />
<Handle type="source" position="right" />


            </ReactFlow>
        </div>
    );
    
};

export default FlowVisualization;

