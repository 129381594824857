import React, { useState, useEffect, useContext } from "react";
import { DimensionContext } from '../components/ResponsiveWrapper';
import { askOracle, tellOracle } from '../components/AskOracle';
import SanitizedInput from '../components/SanitizedInput';
// import SanitizedText from '../components/SanitizedText';
import { Select } from '@mantine/core';
import LoadingSpinner from '../components/LoadingSpinner'; // Import the Spinner component

function Onboarding() {
    const [orgName, setOrgName] = useState('');
    const [organizations, setOrganizations] = useState(null);
    const [users, setUsers] = useState(null);
    const [orgProperties, setOrgProperties] = useState('');
    const [selectedOrg, setSelectedOrg] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [domain, setDomain] = useState('');
    const [subscription, setSubscription] = useState('Hike'); 
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMesssage] = useState('');
    const { width, height } = useContext(DimensionContext);

    useEffect(() => {
        askOracle('oracle/askOrgs').then(data => {
            console.log("Onboarding Organizations fetched:", data);
            setOrganizations(data);
        });
        askOracle('oracle/askUsers').then(data => {
            console.log("Onboarding Users fetched:", data);
            setUsers(data);
        });
    }, [selectedOrg]);
    
    const handleSubmit = () => {
        const newOrgData = {
            name: orgName,
            properties: orgProperties,  // Assuming you want to send this
            domain: domain,
            subscription: subscription
        };

        tellOracle('oracle/tellOrgs', newOrgData)
            .then(response => {
                // Handle the successful response
                setSuccessMessage('Organization added successfully!');
                setErrorMesssage('');  // Clear any previous errors

                // Optionally, clear the success message after a few seconds
                setTimeout(() => {
                    setSuccessMessage('');
                }, 3000);
            })
            .catch(error => {
                // Handle the error response
                setErrorMesssage('Failed to add the organization. Please try again.');
                setSuccessMessage('');  // Clear any previous success message
            });
    };

    const handleAddUserToOrg = () => {
        console.log('Onboarding Selected Org:', selectedOrg);
        console.log('Onboarding Selected User:', selectedUser);
        const payload = {
            orgId: selectedOrg,
            userId: selectedUser
        };
        console.log('Onboarding Selected orgId:', payload.orgId);
        console.log('Onboarding Selected userId:', payload.userId);
        tellOracle('oracle/tellOrgMembers', payload).then(response => {
            // Handle the response
        });
    };

    console.log("Current orgName:", orgName);

    console.log("Organizations array:", organizations);
    console.log("Users array:", users);

    if (!organizations || !users) {
        // Optionally, render a loading indicator here
        return <LoadingSpinner />;
    }

    return (
        <div className="admin-section">

<h1 className="h1">SuperAdmin Onboarding Page</h1>

{successMessage && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">
                <strong className="font-bold">Success!</strong>
                <span className="block sm:inline"> {successMessage}</span>
            </div>}

            {errorMessage && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                <strong className="font-bold">Error!</strong>
                <span className="block sm:inline"> {errorMessage}</span>
            </div>}

            <h3 className="admin-section">Create New Orgs</h3>
            
            {/* Create New Organization */}
            <div className="admin-section">
                <label className="block text-sm mb-2" htmlFor="orgName">Organization Name
                
                <SanitizedInput 
                    type="text" 
                    // label="Organization Name"
                    placeholder="Enter Organization Name"    
                    value={orgName}
                    onChange={e => {
                        console.log("Onboarding Setting orgName:", e.target.value);
                        setOrgName(e.target.value);
                    }}
                    className="border rounded w-full py-2 px-3"
                /></label>
                {/* Add more fields for other properties */}
            </div>
            {/* Assign URL/Domain for CORS */}
            <div className="admin-section">
                <label className="block text-sm mb-2" htmlFor="domain">Domain/URL for CORS
                <SanitizedInput 
                    type="text" 
                    // label="Domain Name"
                    placeholder="Enter Domain"    
                    value={domain}
                    onChange={e => setDomain(e.target.value)}
                    className="border rounded w-full py-2 px-3"
                />
                </label>
            </div>
            {/* Choose Subscription Tier */}
            <div className="admin-section">
                <label className="block text-sm mb-2">Choose Subscription Tier
                <Select
                    value={subscription}
                    onChange={setSubscription}
                    className="border rounded w-full py-2 px-3"
                    data={[
                        { value: 'Hike', label: 'Hike' },
                        { value: 'Camp', label: 'Camp' },
                        { value: 'Backpack', label: 'Backpack' },
                    ]}
                />
                {/* <select 
                    value={subscription} 
                    // label="Set Subscription Tier"
                    onChange={e => setSubscription(e.target.value)} 
                    className="border rounded w-full py-2 px-3"
                >
                    <option value="Hike">Hike</option>
                    <option value="Camp">Camp</option>
                    <option value="Backpack">Backpack</option>
                </select> */}
                </label>
            </div>
            
            <button onClick={handleSubmit} className="admin-section button">
                Submit
            </button>
            
            <div className="admin-section">
            <h3 className="text-2xl mb-4">Add Users to Orgs</h3>

            <Select
                label="Select Organization"
                placeholder="Select an organization"
                value={selectedOrg}
                onChange={setSelectedOrg}
                data={organizations.map(org => ({ 
                    value: org.ID || '', // Ensure value is not undefined
                    label: org.Name || 'Unnamed Organization' // Provide a fallback label
                }))}
                disabled={organizations.length === 0}
                className="mb-2"
            />

            <Select
                label="Select User"
                value={selectedUser}
                onChange={setSelectedUser}
                data={users.map(user => ({ 
                    value: user.ID || '', // Ensure value is not undefined
                    label: user.Email || 'Unnamed User' // Provide a fallback label
                }))}
                placeholder="Select a user"
                disabled={users.length === 0}
            />


                <button onClick={handleAddUserToOrg} className="admin-section button">
                    Add User to Organization
                </button>
            </div>
        </div>
    );
}

export default Onboarding;
