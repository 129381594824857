// ResponsiveWrapper.jsx
import React, { useRef, useLayoutEffect, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useDebouncedResize from './ScreenResized';

export const DimensionContext = React.createContext();

export const ResponsiveWrapper = ({ children }) => {
  const mainContentRef = useRef(null);
  const [mainContentStyle, setMainContentStyle] = useState({});
  const [sidebarWidth, setSidebarWidth] = useState(0);

  // Get updated window dimensions
  const { width: viewportWidth, height: viewportHeight } = useDebouncedResize(300);

  useEffect(() => {
    const parent = mainContentRef.current?.parentNode;
    if (!parent) return;
  
    const observer = new MutationObserver(() => {
      const sidebarElement = parent.querySelector('.sidebar-container');
      if (sidebarElement) {
        const newSidebarWidth = sidebarElement.offsetWidth || viewportWidth * 0.25;
        console.log("ResponsiveWrapper.jsx newSidebarWidth:", newSidebarWidth);
        setSidebarWidth(newSidebarWidth);
      }
    });
  
    observer.observe(parent, { childList: true, subtree: true });
  
    // Initial check
    const initialSidebarElement = parent.querySelector('.sidebar-container');
    if (initialSidebarElement) {
      const newSidebarWidth = initialSidebarElement.offsetWidth || viewportWidth * 0.25;
      setSidebarWidth(newSidebarWidth);
    }
  
    return () => {
      observer.disconnect();
    };
  }, [mainContentRef, viewportWidth]);
  
  useEffect(() => {
    const delayMeasurement = () => {
      const parent = mainContentRef.current?.parentNode;
      const sidebarElement = parent?.querySelector('.sidebar-container');
  
      if (!sidebarElement) {
        console.log("Sidebar not found yet. Retrying...");
        setTimeout(delayMeasurement, 50); // Retry after 50ms
        return;
      }
  
      const newSidebarWidth = sidebarElement.offsetWidth || viewportWidth * 0.25;
      console.log("ResponsiveWrapper.jsx newSidebarWidth:", newSidebarWidth);
      setSidebarWidth(newSidebarWidth);
    };
  
    // Initial attempt to measure
    delayMeasurement();
  }, [mainContentRef, viewportWidth]);
  
  // Effect to observe the sidebar's size
  useEffect(() => {
    if (sidebarWidth <= 0) return; // Wait until sidebarWidth is known
    if (!mainContentRef.current) return;

    const parent = mainContentRef.current.parentNode;
    const sidebarElement = parent.querySelector('.sidebar-container');

    if (!sidebarElement) return; // Sidebar not yet rendered

    // Function to update sidebar width
    const updateSidebarWidth = () => {
      const newSidebarWidth = sidebarElement.offsetWidth || viewportWidth * 0.25;
      console.log("ResponsiveWrapper.jsx newSidebarWidth:", newSidebarWidth);
      setSidebarWidth(newSidebarWidth);
    };

    // Create a ResizeObserver to watch the sidebar
    const resizeObserver = new ResizeObserver(updateSidebarWidth);
    resizeObserver.observe(sidebarElement);

    // Initial width calculation
    updateSidebarWidth();

    console.log("ResponsiveWrapper.jsx sidebarWidth:", sidebarWidth);

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, [mainContentRef, viewportWidth]);

  // Calculate main content dimensions whenever dependencies change
  useLayoutEffect(() => {
    const mainContentWidth = viewportWidth - sidebarWidth;
    const mainContentHeight = viewportHeight;

    console.log("ResponsiveWrapper.jsx in useLayout sidebarWidth:", sidebarWidth);

    setMainContentStyle({
      width: mainContentWidth,
      height: mainContentHeight,
    });
  }, [viewportWidth, viewportHeight, sidebarWidth]);

  return (
    <DimensionContext.Provider value={mainContentStyle}>
      <div
        ref={mainContentRef}
        style={{
          width: `${mainContentStyle.width}px`,
          height: `${mainContentStyle.height}px`,
        }}
        className="responsivewrapper"
      >
        {children}
      </div>
    </DimensionContext.Provider>
  );
};

ResponsiveWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};


// //ResponsiveWrapper.jsx
// import React, { useRef, useLayoutEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import useDebouncedResize from './ScreenResized'; // Import your custom hook

// export const DimensionContext = React.createContext();

// export const ResponsiveWrapper = ({ children }) => {
//   const mainContentRef = useRef(null);
//   const [mainContentStyle, setMainContentStyle] = useState({});

//   // Use your custom hook to get updated window dimensions
//   const { width: viewportWidth, height: viewportHeight } = useDebouncedResize(300);
//   console.log("ResponsiveWrapper.jsx mainContentRef:", mainContentRef);

//   useLayoutEffect(() => {
//     if (mainContentRef.current && mainContentRef.current.parentNode) {
//       const parent = mainContentRef.current.parentNode;

//       const sidebarElement = parent.querySelector('.sidebar-container');
//       let sidebarWidth = sidebarElement ? sidebarElement.offsetWidth : 0;

//       // Fallback: If sidebarWidth is 0, set it to a default value if needed
//       if (sidebarWidth === 0) {
//         sidebarWidth = viewportWidth * 0.25; // Example: 25% of viewportWidth
//       }

//       const mainContentWidth = viewportWidth - sidebarWidth;
//       // const mainContentWidth = viewportWidth;
//       const mainContentHeight = viewportHeight;

//       setMainContentStyle({
//         width: mainContentWidth,
//         height: mainContentHeight,
//       });
//     }
//     console.log("ResponsiveWrapper.jsx setMainContentStyle:", setMainContentStyle);

//   }, [viewportWidth, viewportHeight, mainContentRef]);

//   return (
//     <DimensionContext.Provider value={mainContentStyle}>
//       <div
//         ref={mainContentRef}
//         style={{
//           width: `${mainContentStyle.width}px`,
//           height: `${mainContentStyle.height}px`,
//         }}
//         className="responsivewrapper"
//       >
//         {children}
//       </div>
//     </DimensionContext.Provider>
//   );
// };

// ResponsiveWrapper.propTypes = {
//   children: PropTypes.node.isRequired,
// };
