import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const InfoBox = ({ title, description }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 5000); // 8 seconds delay
    
        return () => clearTimeout(timer); // Cleanup timer on component unmount
    }, []);


    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 40000); 
    
        return () => clearTimeout(timer); // Cleanup timer on component unmount
    }, []);

    return (
        <AnimatePresence>
            {isVisible && (
                <motion.div 
                    className="info-box"
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <button onClick={() => setIsVisible(false)} className="info-box-close">X</button>
                    <motion.h4
                        className="info-box-title"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.3 }}
                    >
                        {title}
                    </motion.h4>
                    <motion.p
                        className="info-box-desc"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.6 }}
                    >
                        {description}
                    </motion.p>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default InfoBox;
