// User Inspector Page Requirements
import React, { useState, useEffect } from 'react';
import { DatePickerInput } from '@mantine/dates';
import {
  Modal,
  Select,
  MultiSelect,
//   Checkbox,
  SegmentedControl,
  Pagination,
  TextInput,
  NumberInput,
  Button,
} from '@mantine/core';
import { motion } from 'framer-motion';
import { parseISO, isValid, isWithinInterval } from 'date-fns';
import LoadingSpinner from '../components/LoadingSpinner';
import { askOracle } from '../components/AskOracle';
import dayjs from 'dayjs';

export default function UserInspector({ orgID }) {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filterSets, setFilterSets] = useState([]);
  const [currentFilterSet, setCurrentFilterSet] = useState({ filters: [] });
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [currentFilter, setCurrentFilter] = useState({
    column: '',
    value: [null, null], 
    minValue: undefined,
    maxValue: undefined,
    not: false,
  });
    const [currentLogicalOperator, setCurrentLogicalOperator] = useState('AND');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 50;
  const [loading, setLoading] = useState(false);
  const [uniqueValuesByColumn, setUniqueValuesByColumn] = useState({
    EventDescList: [],
    PageDuration: [],
    QueryString: [],
    SessionStatus: [],
    URL: [],
    Timestamp: [],
  });

  const columns = [
    { value: 'EventDescList', label: 'Event Description List' },
    { value: 'PageDuration', label: 'Page Duration' },
    { value: 'QueryString', label: 'Query String' },
    { value: 'SessionStatus', label: 'Session Status' },
    { value: 'Timestamp', label: 'Timestamp' },
    { value: 'URL', label: 'URL' },
  ];

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      console.log('Sent by-page to askOracle with params:', { orgID });

      askOracle('by-page', {
        params: { orgID },
      })
        .then((response) => {
          console.log('Received response from askOracle:', response);

          const validData = response.filter(
            (entry) =>
              entry.Timestamp &&
              isValid(parseISO(entry.Timestamp)) &&
              entry.URL &&
              entry.URL.trim() !== '' &&
              entry.HappyID &&
              entry.HappyID.trim() !== '' &&
              entry.SessionId &&
              entry.SessionId.trim() !== '' &&
              Array.isArray(entry.EventDescList) &&
              entry.EventDescList.length > 0 &&
              entry.EventDescList.every(
                (event) => typeof event === 'string' && event.trim() !== ''
              )
          );

          // Group data by HappyID
          const groupedData = {};

          validData.forEach((entry) => {
            const happyID = entry.HappyID;
            if (!groupedData[happyID]) {
              groupedData[happyID] = [];
            }
            groupedData[happyID].push(entry);
          });

          // For each HappyID, sort their entries by Timestamp
          const usersArray = Object.keys(groupedData).map((happyID) => {
            const entries = groupedData[happyID];
            entries.sort((a, b) => new Date(b.Timestamp) - new Date(a.Timestamp));
            return {
              happyID,
              entries,
              latestTimestamp: entries[0].Timestamp,
            };
          });

          console.log('Grouped Data:', groupedData);
          console.log('Users Array:', usersArray);

          // Extract unique values for filters
          const uniqueValues = {
            EventDescList: new Set(),
            PageDuration: new Set(),
            QueryString: new Set(),
            SessionStatus: new Set(),
            URL: new Set(),
            Timestamp: new Set(),
          };

          usersArray.forEach((user) => {
            user.entries.forEach((entry) => {
              // EventDescList is an array
              if (Array.isArray(entry.EventDescList)) {
                entry.EventDescList.forEach((event) => {
                  uniqueValues.EventDescList.add(event);
                });
              }
              if (entry.PageDuration !== undefined && entry.PageDuration !== null) {
                uniqueValues.PageDuration.add(entry.PageDuration);
              }
              if (entry.QueryString) {
                uniqueValues.QueryString.add(entry.QueryString);
              }
              if (entry.SessionStatus) {
                uniqueValues.SessionStatus.add(entry.SessionStatus);
              }
              if (entry.URL) {
                uniqueValues.URL.add(entry.URL);
              }
              if (entry.Timestamp) {
                const date = parseISO(entry.Timestamp);
                if (isValid(date)) {
                  uniqueValues.Timestamp.add(date.toISOString().split('T')[0]);
                }
              }
            });
          });

          const uniqueValuesByColumn = {
            EventDescList: Array.from(uniqueValues.EventDescList),
            PageDuration: Array.from(uniqueValues.PageDuration),
            QueryString: Array.from(uniqueValues.QueryString),
            SessionStatus: Array.from(uniqueValues.SessionStatus),
            URL: Array.from(uniqueValues.URL),
            Timestamp: Array.from(uniqueValues.Timestamp),
          };

          setUniqueValuesByColumn(uniqueValuesByColumn);

          // Sort usersArray by latestTimestamp
          usersArray.sort((a, b) => new Date(b.latestTimestamp) - new Date(a.latestTimestamp));

          setUsers(usersArray);
        })

        .catch((err) => {
          console.error('Error fetching data:', err);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    fetchData();
  }, [orgID]);

  // Filtering logic
  function evaluateFilter(entry, filter) {
    const { column, value, minValue, maxValue, not } = filter;
    let entryValue = entry[column];
  
    if (column === 'Timestamp') {
      const entryDate = parseISO(entryValue);
      if (!isValid(entryDate)) return false;
  
      if (Array.isArray(value) && value.length === 2) {
        const [startDate, endDate] = value;
        if (startDate && endDate) {
          const conditionMet = isWithinInterval(entryDate, {
            start: startDate,
            end: endDate,
          });
          return not ? !conditionMet : conditionMet;
        } else {
          return true; // No date range specified
        }
      } else {
        return true; // No valid date range specified
      }
    } else if (column === 'PageDuration') {
      const entryDuration = Number(entryValue);
      const min = minValue !== undefined ? minValue : Number.NEGATIVE_INFINITY;
      const max = maxValue !== undefined ? maxValue : Number.POSITIVE_INFINITY;
      const conditionMet = entryDuration >= min && entryDuration <= max;
      return not ? !conditionMet : conditionMet;
    } else if (column === 'EventDescList') {
      if (Array.isArray(entryValue)) {
        const containsValue = entryValue.includes(value);
        return not ? !containsValue : containsValue;
      } else {
        return false;
      }
    } else {
      const conditionMet = entryValue === value;
      return not ? !conditionMet : conditionMet;
    }
  }
  
  function formatTimestamp(timestamp) {
    return dayjs(timestamp).format('YYYY-MM-DD  HH:mm:ss.S');
  }

  function evaluateFilterSet(entry, filterSet) {
    let result = null;

    for (let i = 0; i < filterSet.filters.length; i++) {
      const { filter, logicalOperator } = filterSet.filters[i];
      const filterResult = evaluateFilter(entry, filter);

      if (i === 0) {
        result = filterResult;
      } else {
        if (logicalOperator === 'AND') {
          result = result && filterResult;
        } else if (logicalOperator === 'OR') {
          result = result || filterResult;
        }
      }
    }

    return result;
  }

  const applyFilters = (usersData) => {
    return usersData.filter((user) => {
      const entries = user.entries;

      const entryMatches = entries.some((entry) => {
        // For each filter set
        const filterSetResults = filterSets.map((filterSet) => {
          return evaluateFilterSet(entry, filterSet);
        });

        // Combine the filter set results according to the logical operators between filter sets
        let overallResult = null;

        for (let i = 0; i < filterSetResults.length; i++) {
          const filterSetResult = filterSetResults[i];
          const logicalOperator = i > 0 ? filterSets[i].logicalOperator : null;

          if (i === 0) {
            overallResult = filterSetResult;
          } else {
            if (logicalOperator === 'AND') {
              overallResult = overallResult && filterSetResult;
            } else if (logicalOperator === 'OR') {
              overallResult = overallResult || filterSetResult;
            }
          }
        }

        return overallResult === null ? true : overallResult;
    });

      // Search query filter
      const searchMatch =
        searchQuery.trim() !== ''
          ? user.happyID.toLowerCase().includes(searchQuery.toLowerCase())
          : true;

      return entryMatches && searchMatch;
    });
  };

  function renderFilterValue(filter) {
    if (filter.column === 'Timestamp' && Array.isArray(filter.value)) {
      const [startDate, endDate] = filter.value;
      const startStr = startDate ? startDate.toLocaleDateString() : '';
      const endStr = endDate ? endDate.toLocaleDateString() : '';
      return `${startStr} - ${endStr}`;
    } else if (filter.column === 'PageDuration') {
      const minStr = filter.minValue !== undefined ? filter.minValue : '';
      const maxStr = filter.maxValue !== undefined ? filter.maxValue : '';
      return `${minStr} - ${maxStr} seconds`;
    } else {
      return filter.value?.toString() || '';
    }
  }
  
  
  const filteredUsers = applyFilters(users);

  // Sorting users by the latest event timestamp
  const sortedUsers = filteredUsers.sort(
    (a, b) => new Date(b.latestTimestamp) - new Date(a.latestTimestamp)
  );

  // Pagination logic
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = sortedUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (users.length > 0 && !selectedUser) {
      setSelectedUser(users[0]);
    }
  }, [users, selectedUser]);

  useEffect(() => {
    if (filteredUsers.length > 0) {
      // If selectedUser is not in filteredUsers, update it
      if (!selectedUser || !filteredUsers.some(user => user.happyID === selectedUser.happyID)) {
        setSelectedUser(filteredUsers[0]);
      }
    } else {
      // If there are no filtered users, clear selectedUser
      setSelectedUser(null);
    }
  }, [filteredUsers, selectedUser]);

  function formatDuration(seconds) {
    const days = Math.floor(seconds / (24 * 3600));
    const hours = Math.floor((seconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = (seconds % 60).toFixed(1); // Truncate to 1 decimal
  
    let durationStr = "";
  
    if (days > 0) durationStr += `${days}d `;
    if (hours > 0) durationStr += `${hours}h `;
    if (minutes > 0) durationStr += `${minutes}m `;
    if (remainingSeconds > 0 || durationStr === "") durationStr += `${remainingSeconds}s`;
  
    return durationStr.trim();
  }

  const renderUserJourney = () => {
    if (!selectedUser || !selectedUser.entries) return null;

    const sessions = {};

    selectedUser.entries.forEach((entry) => {
      const sessionId = entry.SessionId || 'Unknown';
      if (!sessions[sessionId]) {
        sessions[sessionId] = {
          id: sessionId,
          entries: [],
        };
      }
      sessions[sessionId].entries.push(entry);
    });

    const sessionsArray = Object.values(sessions);

    sessionsArray.forEach((session) => {
      session.entries.sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));
      session.startTime = session.entries[0].Timestamp;
      session.endTime = session.entries[session.entries.length - 1].Timestamp;
      session.duration = (new Date(session.endTime) - new Date(session.startTime)) / 1000; // in seconds
    });

    sessionsArray.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
    console.log('Rendering journey for:', selectedUser.happyID);

    return (
      <motion.div
        key={selectedUser.happyID}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <h2 className="h2">User Journey for {selectedUser.happyID}</h2>
        <div className="mb-6">
          <h3 className="text-dkpurple"><strong>
            {sessionsArray.length === 1
                ? '1 Session'
                : `${sessionsArray.length} Total Sessions`}
            </strong></h3>
        </div>
        {sessionsArray.map((session, idx) => (
          <div key={idx}  className="sessionWrapper">
            <h5 className="font-semibold">Session: {session.id}</h5>
            <p>
            <strong>Session Start:</strong> {formatTimestamp(session.startTime)}{' '}
            <strong>Session End:</strong> {formatTimestamp(session.endTime)}
            </p>
            <p>
              <strong>Duration:</strong> {formatDuration(session.duration)}
            </p>
            <div className="sessionInnerWrapper">
              {session.entries.map((entry, idx) => (
                <div key={idx} className="pad-b2">
                  <p>
                    <strong>Timestamp:</strong> {entry.Timestamp}
                  </p>
                  <p>
                    <strong>Page:</strong> {entry.URL}
                  </p>
                  <p>
                    <strong>Events:</strong> {entry.EventDescList.join(', ')}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </motion.div>
    );
  };

  // Modal handlers
  const addFilterToSet = () => {
    const filterWithOperator = {
      filter: currentFilter,
      logicalOperator: currentFilterSet.filters.length > 0 ? currentLogicalOperator : null,
    };
    setCurrentFilterSet({
      ...currentFilterSet,
      filters: [...currentFilterSet.filters, filterWithOperator],
    });
    // Reset currentFilter and currentLogicalOperator
    setCurrentFilter({
      column: '',
      value: [null, null], // Reset to an array of nulls
      minValue: undefined,
      maxValue: undefined,
      not: false,
    });
    setCurrentLogicalOperator('AND');
  };
  
  

  const saveFilterSet = () => {
    const newFilterSet = {
      filters: currentFilterSet.filters,
      logicalOperator: filterSets.length > 0 ? 'AND' : null, // default to 'AND'
    };
    setFilterSets([...filterSets, newFilterSet]);
    setCurrentFilterSet({ filters: [] });
    setFilterModalOpen(false);
  };

  return (
    <div className="visualization-container">
      <h1 className="h1">User Inspector</h1>

      <aside className="inspectorFilter">

        <Button onClick={() => setFilterModalOpen(true)} className="b-dkpurple float-right">
          Add Filters
        </Button>
        <div>
          <h2>Active Filter Sets</h2>
          <ul>
            {filterSets.map((filterSet, idx) => (
              <li key={idx}>
                {idx > 0 && (
                  <Select
                    data={[
                      { value: 'AND', label: 'AND' },
                      { value: 'OR', label: 'OR' },
                    ]}
                    value={filterSet.logicalOperator}
                    className='maxw-md'
                    onChange={(value) => {
                      const newFilterSets = [...filterSets];
                      newFilterSets[idx].logicalOperator = value;
                      setFilterSets(newFilterSets);
                    }}
                  />
                )}
                <ul>
                  {filterSet.filters.map((f, idx2) => (
                    <li key={idx2}>
                      {f.logicalOperator && <span>{f.logicalOperator} </span>}
                      <span
                        className={`${f.filter.not ? 'text-red-500' : ''}`}
                      >
                    {f.filter.column} {f.filter.not ? 'NOT' : ''} {renderFilterValue(f.filter)}
                    </span>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </aside>

      {/* Main Content: List of Users and User Journey */}
      <main className="inspectorContainer height-5-6">
        {/* List of Users */}
        <section className="inspectorUsers">
          <h2>User List</h2>
          <TextInput
            placeholder="Search users"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="mb-4"
          />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
            <ul>
                {currentUsers.map((user, idx) => (
                <motion.li
                    key={user.happyID}
                    onClick={() => setSelectedUser(user)}
                    className={`inspectorUsers-li cursor-pointer ${
                    selectedUser?.happyID === user.happyID ? 'selected' : ''
                    } ${idx % 2 === 0 ? 'even' : 'odd'}`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                >
                    {user.happyID}
                </motion.li>
                ))}
            </ul>
            <Pagination
                page={currentPage}
                onChange={handlePageChange}
                total={Math.ceil(sortedUsers.length / usersPerPage)}
                className="mt-4"
            />
            </>
          )}
        </section>

        <section className="inspectorJourney">{renderUserJourney()}</section>
      </main>

      {/* Filter Modal */}
      <Modal
        opened={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        title="Create Filter Set"
      >
            <h3>Active Filter Sets</h3>
            <div className="filterSetBorder">
            {currentFilterSet.filters.length > 0 && (
                <>
            <ul>
              {filterSets.map((filterSet, idx) => (
                <li key={idx}>
                  {idx > 0 && (
                    <Select
                      data={[
                        { value: 'AND', label: 'AND' },
                        { value: 'OR', label: 'OR' },
                      ]}
                      value={filterSet.logicalOperator}
                      onChange={(value) => {
                        const newFilterSets = [...filterSets];
                        newFilterSets[idx].logicalOperator = value;
                        setFilterSets(newFilterSets);
                      }}
                    />
                  )}
                  <ul>
                    {filterSet.filters.map((f, idx2) => (
                      <li key={idx2}>
                        {f.logicalOperator && <span>{f.logicalOperator} </span>}
                        <span className={`${f.filter.not ? 'text-red-500' : ''}`}>
                        {f.filter.column} {f.filter.not ? 'NOT' : ''} {renderFilterValue(f.filter)}
                        </span>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
            </>
            )}

        <Select
          label="Choose Data Type"
          placeholder="Select Data"
          data={columns}
          value={currentFilter.column}
          onChange={(value) => {
            // Initialize currentFilter based on the selected column
            const newFilter = {
              column: value,
              not: false,
            };
          
            if (value === 'Timestamp') {
              newFilter.value = [null, null]; // Initialize date range
              delete newFilter.minValue;
              delete newFilter.maxValue;
            } else if (value === 'PageDuration') {
              newFilter.minValue = undefined;
              newFilter.maxValue = undefined;
              delete newFilter.value;
            } else {
              newFilter.value = ''; // For other columns
              delete newFilter.minValue;
              delete newFilter.maxValue;
            }
          
            setCurrentFilter(newFilter);
          }}
          
          className="mb-4"
        />

        {currentFilter.column && (
        <>
            {currentFilter.column === 'PageDuration' ? (
                <>
            <NumberInput
            label="Minimum Page Duration (seconds)"
            placeholder="Enter minimum duration"
            value={currentFilter.minValue}
            onChange={(value) =>
              setCurrentFilter({ ...currentFilter, minValue: value })
            }
          />
          <NumberInput
            label="Maximum Page Duration (seconds)"
            placeholder="Enter maximum duration"
            value={currentFilter.maxValue}
            onChange={(value) =>
              setCurrentFilter({ ...currentFilter, maxValue: value })
            }
          />
          </>
            ) : currentFilter.column === 'Timestamp' ? (
                <DatePickerInput
                type="range"
                label="Timestamp Range"
                placeholder="Select date range"
                value={currentFilter.value}
                onChange={(value) => setCurrentFilter({ ...currentFilter, value })}
                className="mb-4"
            />
                
            ) : currentFilter.column === 'QueryString' ? (
            <TextInput
                label="Query String"
                placeholder="Enter query string"
                value={currentFilter.value}
                onChange={(e) =>
                setCurrentFilter({ ...currentFilter, value: e.target.value })
                }
            />
            ) : (
            <Select
                label="Choose Values"
                placeholder="Select value"
                data={uniqueValuesByColumn[currentFilter.column].map((value) => ({
                value: value.toString(),
                label: value.toString(),
                }))}
                value={currentFilter.value}
                onChange={(value) => setCurrentFilter({ ...currentFilter, value })}
            />
            )}
        </>
        )}
        <SegmentedControl
  data={[
    { label: 'IS', value: 'false' },  // Representing "NOT" unchecked
    { label: 'NOT', value: 'true' }   // Representing "NOT" checked
  ]}
  value={currentFilter.not ? 'true' : 'false'}
  onChange={(value) => 
    setCurrentFilter({ ...currentFilter, not: value === 'true' })
  }
  className="mar-t2"
/>
</div>
        <Button onClick={addFilterToSet} className="b-dkpurple mr-2">
          Add Filter to Set
        </Button>

        {/* Display Current Filters in Set */}
        {currentFilterSet.filters.length > 0 && (
        <div className="mar-y4">
            <h3>Current Filters in Set</h3>
            <ul>
            {currentFilterSet.filters.map((f, idx) => (
                <li key={idx}>
                {f.logicalOperator && <span>{f.logicalOperator} </span>}
                <span className={`${f.filter.not ? 'text-red-500' : ''}`}>
                    {f.filter.column} {f.filter.not ? 'NOT' : ''}{' '}
                    {renderFilterValue(f.filter)}
                </span>
                </li>
            ))}
            </ul>
        </div>
        )}
        <Button onClick={saveFilterSet} variant="outline" className="b-magenta">
          Save Filter Set
        </Button>
      </Modal>
    </div>
  );
}