import React, { useState, useEffect, useContext } from 'react';
import campfire1 from '../assets/spinner/loadingspinner-campfire1.svg';
import campfire2 from '../assets/spinner/loadingspinner-campfire2.svg';
import campfire3 from '../assets/spinner/loadingspinner-campfire3.svg';
// import { DimensionContext } from '../components/ResponsiveWrapper';


const LoadingSpinner = ({ toggleLoading }) => {
  const [currentFire, setCurrentFire] = useState(1);
//   const dimensions = useContext(DimensionContext);

  
  useEffect(() => {
    // toggleLoading(true);  // Set loading to true when component mounts

    console.log("Loading spinner mounted"); // Debugging line
    const timer = setInterval(() => {
      setCurrentFire((prev) => {
        if (prev === 3) return 1;
        return prev + 1;
      });
    }, 250); // Changes every quarter-second

    return () => {
      clearInterval(timer);
      // toggleLoading(false);  // Set loading to false when component unmounts
    };
  }, [toggleLoading]);

  const getCurrentCampfire = () => {
    switch (currentFire) {
      case 1:
        return campfire1;
      case 2:
        return campfire2;
      case 3:
        return campfire3;
      default:
        return campfire1;
    }
  };

  return (
    <div className="loading-container">
    <img
      src={getCurrentCampfire()}
      alt="Loading..."
      className="loading-image"
    />
  </div>
  );
};

export default LoadingSpinner;
