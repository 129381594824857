// DataFetcher.jsx
import { useQuery } from 'react-query';
import axios from 'axios';
import LocalData from './LocalData';

axios.defaults.withCredentials = true;

const GO_BASE = process.env.REACT_APP_GO_BASE;

const fetchDataset = () => {
  console.log('Fetching dataset...');
  const defaultHeaders = {
    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
    'OrgID': `${localStorage.getItem('ActiveOrgID')}`
  };
  const headers = { ...defaultHeaders };
  console.log('headers in fetchDataset:', headers);

  return (
    axios.get(`${GO_BASE}/${'api/model-latest'}`, { headers }, { withCredentials: true })
      .then((res) => {
        console.log('Data fetched successfully:', res.data);
        
        // Parse UniqueUrls and assign datasets
        if (res.data && typeof res.data.UniqueUrls === 'string') {
          try {
            const parsedUniqueUrls = JSON.parse(res.data.UniqueUrls);
            res.data.UniqueURL = parsedUniqueUrls.URL;
            res.data.UniqueQueryString = parsedUniqueUrls.QueryString;
            res.data.UniqueReferrer = parsedUniqueUrls.Referrer;
            res.data.UniqueEventDescList = parsedUniqueUrls.EventDescList;
          } catch (error) {
            console.error('Error parsing UniqueUrls:', error);
          }
        }
        
        return res.data; // Return data with datasets assigned
      })
      .catch((error) => {
        console.log('Error fetching data:', error);
        throw error;
      })
  );
};

export const useDataset = (shouldFetch = true) => {
  const fetchFn = shouldFetch ? fetchDataset : () => {
    const localDataset = LocalData.getItem('dataset');
    if (localDataset) {
      return Promise.resolve(localDataset);
    } else {
      // If there's no data in LocalData, fetch it anyway
      return fetchDataset();
    }
  };
  const queryInfo = useQuery('dataset', fetchFn, {
    staleTime: 1000 * 60 * 60 * 12, // 12 hours
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      // Update LocalData with the fresh dataset
      LocalData.setItem('dataset', data);
    }
  });
  
  console.log('useDataset called export:');
  console.log('Query Info:', queryInfo);
  console.log('Data inside useDataset:', queryInfo.data);

  return queryInfo;
};


// import { useQuery } from 'react-query';
// import axios from 'axios';
// import LocalData from './LocalData';

// axios.defaults.withCredentials = true;

// // const csrfToken = localStorage.getItem('csrfToken');

// // axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

// const GO_BASE = process.env.REACT_APP_GO_BASE;

// const fetchDataset = () => {
//   console.log('Fetching dataset...');
//   const defaultHeaders = {
//     'Authorization': `Bearer ${localStorage.getItem('authToken')}`, // Assuming the token is stored in local storage
//     'OrgID': `${localStorage.getItem('ActiveOrgID')}` // Assuming the token is stored in local storage
//   };
//   const headers = { ...defaultHeaders };
//   console.log('headers in fetchDataset:', headers);

//   return (
//     axios.get(`${GO_BASE}/${'api/model-latest'}`, { headers }, { withCredentials: true })
//       .then((res) => {
//         console.log('Data fetched successfully:', res.data);
//         return res.data; // Return data directly without transformation
//       })
//       .catch((error) => {
//         console.log('Error fetching data:', error);
//         throw error;
//       })
//   );
// };

// export const useDataset = (shouldFetch = true) => {
//   const fetchFn = shouldFetch ? fetchDataset : () => {
//     const localDataset = LocalData.getItem('dataset');
//     if (localDataset) {
//       return Promise.resolve(localDataset);
//     } else {
//       // If there's no data in LocalData, fetch it anyway
//       return fetchDataset();
//     }
//   };
//   const queryInfo = useQuery('dataset', fetchFn, {
//     staleTime: 1000 * 60 * 60 * 12, // 12 hours
//     refetchOnWindowFocus: false,
//     onSuccess: (data) => {
//       // Update LocalData with the fresh dataset
//       LocalData.setItem('dataset', data);
//     }
//   });
  
//   console.log('useDataset called export:');
//   console.log('Query Info:', queryInfo);
//   console.log('Data inside useDataset:', queryInfo.data); // Add this line

//   return queryInfo;
// };
