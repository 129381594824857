import axios from 'axios';

axios.defaults.withCredentials = true;

// const csrfToken = localStorage.getItem('csrfToken');

// axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

const GO_BASE = process.env.REACT_APP_GO_BASE;

export const askOracle = async (endpoint, config = {}) => {
  const token = localStorage.getItem('authToken');
  const OrgID = localStorage.getItem('ActiveOrgID');
  console.log("Sent askOracle to:", endpoint);
  console.log("Sent askOracle for OrgID:", OrgID);

  const headers = {
    'Authorization': `Bearer ${token}`,
    ...config.headers,
  };

  if (OrgID) {
    headers['OrgID'] = OrgID;
  }
  
  try {
    const response = await axios.get(`${GO_BASE}/${endpoint}`, {
      ...config,
      headers,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Remove invalid token
      localStorage.removeItem('authToken');
      console.log("I had to remove the auth token");
      // Optionally redirect to login page
      window.location.href = '/auth';
    }
    throw error;
  }
};

export const tellOracle = async (endpoint, data, config = {}) => {
  const token = localStorage.getItem('authToken');
  const OrgID = localStorage.getItem('ActiveOrgID');

  const headers = {
    'Authorization': `Bearer ${token}`,
    'OrgID': `${OrgID}`,
    ...config.headers,
  };
  console.log("tellOracle headers", headers);

  try {
    const response = await axios.post(`${GO_BASE}/${endpoint}`, data, {
      ...config,
      headers,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Remove invalid token
      localStorage.removeItem('authToken');
      // Optionally redirect to login page
      window.location.href = '/auth';
    }
    throw error;
  }
};
