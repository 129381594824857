const setItem = (keyName, data) => {
  try {
    if (data) {
      localStorage.setItem(keyName, JSON.stringify(data));
      console.log(`Saved data set for ${keyName}:`, data);
    }
  } catch (error) {
    console.error(`Error saving data to local storage with key ${keyName}:`, error);
  }
};

const getItem = (keyName) => {
  try {
    const data = localStorage.getItem(keyName);
    console.log(`Retrieved data set for ${keyName}:`, data);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error(`Error retrieving data from local storage with key ${keyName}:`, error);
    return null;
  }
};

const LocalData = {
  setItem,
  getItem
};

export default LocalData;