import React, { useEffect, useRef, useMemo } from 'react';
import { useDataset } from '../DataFetcher';
import LoadingSpinner from '../LoadingSpinner';

console.log("useDataset load");

const PathDataSets = ({ onDataLoaded }) => {
    const isDataFetchedRef = useRef(false);
    const { data: dataset, status } = useDataset(!isDataFetchedRef.current);

    const isGraphDataLoaded = () => {
        return !!document.querySelector('script[data-graph-positions]');
    };

    const UniqueURL = useMemo(() => {
        if (dataset && dataset.UniqueURL) {
          return typeof dataset.UniqueURL === 'string' ? JSON.parse(dataset.UniqueURL) : dataset.UniqueURL;
        }
        return {};
      }, [dataset]);
      
    const UniqueEventDescList = useMemo(() => {
        if (dataset && dataset.UniqueEventDescList) {
          return typeof dataset.UniqueEventDescList === 'string' ? JSON.parse(dataset.UniqueEventDescList) : dataset.UniqueEventDescList;
        }
        return {};
      }, [dataset]);

      useEffect(() => {
        console.log("UniqueURL:", UniqueURL);
        console.log("UniqueEventDescList:", UniqueEventDescList);
      }, [UniqueURL, UniqueEventDescList]);

    useEffect(() => {
        console.log("useDataset status:", status);
        if (status === 'success') {
            isDataFetchedRef.current = true;
        }
    }, [status]);

    useEffect(() => {
        if (!isDataFetchedRef.current) {
            // console.log("Fetching dataset...");
            const graphLoaded = isGraphDataLoaded();
            console.log("Is base data loaded:", graphLoaded);
            isDataFetchedRef.current = true;
        }
    }, []);

    const replaceInvalidValues = (obj) => {
        // console.log("Replacing invalid values in object:", obj);
        for (const key in obj) {
            if (typeof obj[key] === 'object') {
                replaceInvalidValues(obj[key]);
            } else if (
                Number.isNaN(obj[key]) ||
                obj[key] === null ||
                obj[key] === undefined
            ) {
                // console.log(`Replacing invalid value for key: ${key}`);
                obj[key] = `node_${Math.random()}`;
            }
        }
    };

    const { graphData, graphNodes, graphEdges, sources, targets } = useMemo(() => {
        if (!dataset || status !== 'success') {
            // console.log("Dataset not ready or status not success.");
            return {
                graphData: {},
                graphNodes: [],
                graphEdges: [],
                sources: [],
                targets: []
            };
        }

        // console.log("Processing dataset.GraphPositions:", dataset.GraphPositions);
        replaceInvalidValues(dataset.GraphPositions);
    
        let parsedData;

        if (typeof dataset.GraphPositions === 'string') {
            try {
                parsedData = JSON.parse(dataset.GraphPositions);
            } catch (error) {
                console.error("Error parsing dataset.GraphPositions:", error);
                parsedData = {};
            }
        } else {
            parsedData = dataset.GraphPositions;
        }
    
        if (typeof parsedData === 'string') {
            try {
                parsedData = JSON.parse(parsedData);
            } catch (error) {
                console.error("Error parsing parsedData:", error);
                parsedData = {};
            }
        }

        // console.log("Parsed data:", parsedData);

        const nodes = (parsedData?.nodes ?? []).filter((node) => {
            const isValid = node.id !== "Empty" && node.id !== "" && node.id !== null && node.id !== undefined;
            if (!isValid) {
                console.log("Filtering out invalid node:", node);
            }
            return isValid;
        });
        const edges = (parsedData?.edges ?? []).filter((edge) => {
            const isValid = 
                edge.source !== "Empty" && edge.source !== "" && edge.source !== null && edge.source !== undefined &&
                edge.target !== "Empty" && edge.target !== "" && edge.target !== null && edge.target !== undefined &&
                edge.source !== edge.target;
            if (!isValid) {
                // console.log("Filtering out invalid edge:", edge);
            }
            return isValid;
        });

        const sources = edges.map(edge => edge.source);
        const targets = edges.map(edge => edge.target);

        console.log("useDataset Nodes:", nodes);
        console.log("useDataset Edges:", edges);
        console.log("useDataset Sources:", sources);
        console.log("useDataset Targets:", targets);
        console.log("useDataset UniqueURL:", UniqueURL);
        console.log("useDataset UniqueEventDescList:", UniqueEventDescList);

        return { 
            graphData: parsedData || {}, 
            graphNodes: nodes, 
            graphEdges: edges,
            sources,
            targets,
            UniqueURL,
            UniqueEventDescList
        };
      
    }, [dataset, status, UniqueEventDescList, UniqueURL]);

    useEffect(() => {
        if (
            status === 'success' &&
            sources.length > 0 &&
            isDataFetchedRef.current &&
            graphNodes.length > 0 &&
            graphEdges.length > 0
        ) {
            console.log("Data is ready, calling onDataLoaded...");
            onDataLoaded({
                status, // Add status to the data being sent back
                graphData,
                graphNodes,
                graphEdges,
                sources,
                targets,
                UniqueURL,
                UniqueEventDescList
            });
        } else {
            console.log("Data not ready yet or edges and nodes not properly defined.");
        }
    }, [
        status,
        graphData,
        graphNodes,
        graphEdges,
        sources,
        targets,
        UniqueURL,
        UniqueEventDescList,
        onDataLoaded
    ]);

    if (status === 'loading') {
        console.log("Loading dataset...");
        return <LoadingSpinner />;
    }

    if (status === 'error') {
        console.error("Error loading dataset.");
        return <div>Error loading data.</div>;
    }

    return null;
};

export default PathDataSets;