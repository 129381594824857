import { useEffect, useState } from 'react';
import { useChat } from './ChatContext';

const getOrganizationData = () => {
    const activeOrgID = localStorage.getItem('ActiveOrgID');
    const organizations = JSON.parse(localStorage.getItem('Organizations'));
    const activeOrganization = organizations?.find(org => org.OrgID === activeOrgID);

    return {
        modelDate: activeOrganization?.ModelDate || 'All time',
        startPage: activeOrganization?.StartPage || 'Start Page',
        endPage: activeOrganization?.EndPage || 'End Page'
    };
};

const ChatStart = ({ initialMessagesSent, setInitialMessagesSent }) => {
    const { sendMessage, isLoading } = useChat();

    useEffect(() => {
        console.log("ChatStart useEffect called. isLoading:", isLoading, "initialMessagesSent:", initialMessagesSent);
        if (!isLoading && !initialMessagesSent) {
            // ...
        }
    }, [sendMessage, isLoading, initialMessagesSent]);
    
    useEffect(() => {
        if (!isLoading && !initialMessagesSent) {
            const { modelDate, startPage, endPage } = getOrganizationData();

            const initialMessages = [
                `<h3>Getting Started</h3><div className="bot-start">Ask questions of your data. Create your queries as prompts. You can mix and match to create the query you need. <br/> Your data set begins on ${modelDate}.<br/><br/> Example: Page ${endPage} Event Click - Submit Outcome Signup</div>`,
                `<div className="bot-start">"Page ${startPage}" will return all the information about a specific URL. <br/>"PageFrom ${startPage} PageTo ${endPage}" will drill down into just data about entry and exit from specific pages.</div>`,
                `<div className="bot-start">You can also ask about Events or Outcomes. The queries are contains. <br/>"Event Button - Signup" "Outcome Activated"</div>`,
                `<div className="bot-start">To get started, you might want to get a list of all of your data values. <br/>You can ask for Pages, Events, Referrers, QueryStrings.<br/> "List Pages" "List Events"</div>`
            ];

            let index = 0;
            const sendInitialMessageWithDelay = () => {
                if (index < initialMessages.length) {
                    setInitialMessagesSent(true);
                    sendMessage({ text: initialMessages[index], start: 'true', sender: 'bot' });
                    index++;
                    setTimeout(sendInitialMessageWithDelay, 600); // Delay of 600 ms between messages
                }
                //  else {
                //     setInitialMessagesSent(true);
                // }
            };

            sendInitialMessageWithDelay();
        }
    }, [sendMessage, isLoading, initialMessagesSent]);

    return null;
};

export default ChatStart;