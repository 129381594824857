// /redux/slices/treeExplorerSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    graphElements: [],
    graph: [],
    selectedNodesByLayer: {},
    expandedNodes: new Set(), // Initialize expandedNodes
    currentLayer: 0, // Initialize currentLayer as needed
  };

const treeExplorerSlice = createSlice({
  name: 'treeExplorer',
  initialState,
  reducers: {
    setGraphElements: (state, action) => {
      state.graphElements = action.payload;
    },
    setGraph: (state, action) => {
    state.graph = action.payload;
    },
    updateGraph: (state, action) => {
    state.graph.push(action.payload);
    },
    addGraphElement: (state, action) => {
      state.graphElements.push(action.payload);
    },
    removeGraphElement: (state, action) => {
      state.graphElements = state.graphElements.filter(element => element.id !== action.payload);
    },
    updateGraphElement: (state, action) => {
      const index = state.graphElements.findIndex(element => element.id === action.payload.id);
      if (index !== -1) state.graphElements[index] = action.payload;
    },
    setSelectedNodesByLayer: (state, action) => {
      state.selectedNodesByLayer = action.payload;
    },
    addSelectedNode: (state, action) => {
      const { layer, nodeId } = action.payload;
      if (!state.selectedNodesByLayer[layer]) {
        state.selectedNodesByLayer[layer] = new Set();
      }
      state.selectedNodesByLayer[layer].add(nodeId);
    },
    removeSelectedNode: (state, action) => {
      const { layer, nodeId } = action.payload;
      state.selectedNodesByLayer[layer]?.delete(nodeId);
    },
        // Action to set expanded nodes
    setExpandedNodes: (state, action) => {
        state.expandedNodes = action.payload;
    },

    // Action to set the current layer
    setCurrentLayer: (state, action) => {
        state.currentLayer = action.payload;
    },
  },
});

export const selectGraphElements = state => state.treeExplorer.graphElements;
export const selectSelectedNodesByLayer = state => state.treeExplorer.selectedNodesByLayer;
export const selectExpandedNodes = state => state.treeExplorer.expandedNodes; // Add selector for expandedNodes
export const selectCurrentLayer = state => state.treeExplorer.currentLayer; // Add selector for currentLayer

export const { 
  setGraphElements, 
  setGraph,
  updateGraph,
  addGraphElement, 
  removeGraphElement, 
  updateGraphElement, 
  setSelectedNodesByLayer, 
  addSelectedNode, 
  removeSelectedNode,
  setExpandedNodes,
  setCurrentLayer 
} = treeExplorerSlice.actions;

export default treeExplorerSlice.reducer;

